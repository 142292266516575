export const GetHorarios = 'horarios/GET_HORARIOS';

export const modifyHorarios = 'horarios/MODIFY';

export const AddHorario = 'horarios/ADD';

export const GetHorarioById = 'horarios/GETBYID';

export const AlterHorario = 'horarios/ALTER';

export const DeleteHorario = 'horarios/DELETE';