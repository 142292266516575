import * as actionTypes from './actionTypes';

const initialState = {
    sucursales: undefined,
    nominaFechas: undefined,
    empleadosSucursal: undefined,
    generar: undefined,
    revision: undefined,
    nominaFechaAnterior: undefined,
    nominaFechaSearch: undefined,
    agregarPenalizacion: undefined,
    eliminarPenalizacion: undefined,
    penalizacionesByNomina: undefined,
    nominasValidadas: undefined,
    validarNomina: undefined,
}

export default function NominaReducer(state, action) {
    if (typeof state === 'undefined') {
        return initialState;
    }

    switch (action.type) {
        case actionTypes.GetSucursalesActivas:
            return Object.assign({}, state, {
                sucursales: action.payload
            });
        case actionTypes.GetNominaFechas:
            return Object.assign({}, state, {
                nominaFechas: action.payload
            });
        case actionTypes.GetEmpleadosPorSucursal:
            return Object.assign({}, state, {
                empleadosSucursal: action.payload
            });
        case actionTypes.Generar:
            return Object.assign({}, state, {
                generar: action.payload,
            });
        case actionTypes.NominaInRevision:
            return Object.assign({}, state, {
                revision: action.payload,
            });
        case actionTypes.NominaFechaAnterior:
            return Object.assign({}, state, {
                nominaFechaAnterior: action.payload,
            });
        case actionTypes.NominaFechaSearch: 
            return Object.assign({}, state, {
                nominaFechaSearch: action.payload,
            });

        case actionTypes.AgregarPenalizacion:
            return Object.assign({}, state, {
                agregarPenalizacion: action.payload,
            });
        case actionTypes.EliminarPenalizacion:
            return Object.assign({}, state, {
                eliminarPenalizacion: action.payload,
            });
        case actionTypes.GetPenalizacionesByNomina: 
            return Object.assign({}, state, {
                penalizacionesByNomina: action.payload,
            });
        case actionTypes.GetNominasValidadas: 
            return Object.assign({}, state, {
                nominasValidadas: action.payload,
            });
        case actionTypes.ValidarNomina: 
            return Object.assign({}, state, {
                validarNomina: action.payload,
            });
        default: return state;
    }
}