import * as actionTypes from './actionTypes';
import { connector } from '../connector/index';
import { id } from 'date-fns/locale';

export const getSucursalesActivas = () => {
    return async (dispatch) => {
        const result = await connector(`/sucursales`, 'GET', {},{}).then((response) => {
            dispatch({
                type: actionTypes.GetSucursalesActivas,
                payload: response.data,
            });
            return response;
        }).catch(err => {
            dispatch({
                type: actionTypes.GetSucursalesActivas,
                payload: err.data,
            });
            return err.response;
        });
        return result && result.response ? result.response.data : result.data;
    }
}

export const getNominaFechas = (fecha) => {
    return async (dispatch) => {
        const result = await connector(`/nomina/fechas?fecha=${fecha}`, 'GET', {},{}).then((response) => {
            dispatch({
                type: actionTypes.GetNominaFechas,
                payload: response.data,
            });
            return response;
        }).catch(err => {
            dispatch({
                type: actionTypes.GetNominaFechas,
                payload: err.data,
            });
            return err.response;
        });
        return result && result.response ? result.response.data : result.data;
    }
}

export const getEmpleadosBySucursal = (idSucursal) => {
    return async (dispatch) => {
        const result = await connector(`/nomina/empleados?idSucursal=${idSucursal}`, 'GET', {},{}).then((response) => {
            dispatch({
                type: actionTypes.GetEmpleadosPorSucursal,
                payload: response.data,
            });
            return response;
        }).catch(err => {
            dispatch({
                type: actionTypes.GetEmpleadosPorSucursal,
                payload: err.data,
            });
            return err.response;
        });
        return result && result.response ? result.response.data : result.data;
    }
}

export const generateNomina = (data) => {
    return async (dispatch) => {
        const result = await connector(`/nomina/generar`, 'POST', data,{}).then((response) => {
            dispatch({
                type: actionTypes.Generar,
                payload: response.data,
            });
            return response;
        }).catch(err => {
            dispatch({
                type: actionTypes.Generar,
                payload: err.data,
            });
            return err.response;
        });
        return result && result.response ? result.response.data : result.data;
    }
}

export const getAllNominasInRevision = (idSucursal, fechaInicio, fechaFin) => {
    return async (dispatch) => {
        const result = await connector(`/nomina/revision?idSucursal=${idSucursal}&fechaInicio=${fechaInicio}&fechaFin=${fechaFin}`, 'GET', {},{}).then((response) => {
            dispatch({
                type: actionTypes.NominaInRevision,
                payload: response.data,
            });
            return response;
        }).catch(err => {
            dispatch({
                type: actionTypes.NominaInRevision,
                payload: err.data,
            });
            return err.response;
        });
        return result && result.response ? result.response.data : result.data;
    }
}

export const getNominaFechaAnterior = (fecha) => {
    return async (dispatch) => {
        const result = await connector(`/nomina/fecha-anterior?fecha=${fecha}`, 'GET', {},{}).then((response) => {
            dispatch({
                type: actionTypes.NominaFechaAnterior,
                payload: response.data,
            });
            return response;
        }).catch(err => {
            dispatch({
                type: actionTypes.NominaFechaAnterior,
                payload: err.data,
            });
            return err.response;
        });
        return result && result.response ? result.response.data : result.data;
    }
}

export const getNominaFechasSearch = (fecha) => {
    return async (dispatch) => {
        const result = await connector(`/nomina/nomina-fechas?fecha=${fecha}`, 'GET', {},{}).then((response) => {
            dispatch({
                type: actionTypes.NominaFechaSearch,
                payload: response.data,
            });
            return response;
        }).catch(err => {
            dispatch({
                type: actionTypes.NominaFechaSearch,
                payload: err.data,
            });
            return err.response;
        });
        return result && result.response ? result.response.data : result.data;
    }
}

export const AgregarPenalizacion = (data) => {
    return async (dispatch) => {
        const result = await connector(`/nomina/penalizaciones/generar`, 'PUT',data, {}).then((response) => {
            dispatch({
                type: actionTypes.AgregarPenalizacion,
                payload: response.data,
            });
            return response;
        }).catch(err => {
            dispatch({
                type: actionTypes.AgregarPenalizacion,
                payload: err.data,
            });
            return err.response;
        });
        return result && result.response ? result.response.data : result.data;
    }
}

export const EliminarPenalizacion = (idPenalizacion, idUsuario) => {
    return async (dispatch) => {
        const result = await connector(`/nomina/penalizaciones/eliminar?idPenalizacion=${idPenalizacion}&idUsuario=${idUsuario}`, 'DELETE', {}, {}).then((response) => {
            dispatch({
                type: actionTypes.EliminarPenalizacion,
                payload: response.data,
            });
            return response;
        }).catch(err => {
            dispatch({
                type: actionTypes.EliminarPenalizacion,
                payload: err.data,
            });
            return err.response;
        });
        return result && result.response ? result.response.data : result.data;
    }
}

export const GetPenalizacionesByNomina = (idNomina) => {
    return async (dispatch) => {
        const result = await connector(`/nomina/penalizaciones-nomina?idNomina=${idNomina}`, 'GET', {}, {}).then((response) => {
            dispatch({
                type: actionTypes.GetPenalizacionesByNomina,
                payload: response.data,
            });
            return response;
        }).catch(err => {
            dispatch({
                type: actionTypes.GetPenalizacionesByNomina,
                payload: err.data,
            });
            return err.response;
        });
        return result && result.response ? result.response.data : result.data;
    }
}

export const getNominasValidadas = (idSucursal, fechaInicio, fechaFin) => {
    return async (dispatch) => {
        const result = await connector(`/nomina/validacion?idSucursal=${idSucursal}&fechaInicio=${fechaInicio}&fechaFin=${fechaFin}`, 'GET', {}, {}).then((response) => {
            dispatch({
                type: actionTypes.GetNominasValidadas,
                payload: response.data,
            });
            return response;
        }).catch(err => {
            dispatch({
                type: actionTypes.GetNominasValidadas,
                payload: err.data,
            });
            return err.response;
        });
        return result && result.response ? result.response.data : result.data;
    }
}

export const ValidarNomina = (data) => {
    return async (dispatch) => {
        const result = await connector(`/nomina/validar`, 'POST', data, {}).then((response) => {
            dispatch({
                type: actionTypes.ValidarNomina,
                payload: response.data,
            });
            return response;
        }).catch(err => {
            dispatch({
                type: actionTypes.ValidarNomina,
                payload: err.data,
            });
            return err.response;
        });
        return result && result.response ? result.response.data : result.data;
    }
}