import { createStore, combineReducers, applyMiddleware } from 'redux';
import SucursalesReducer from './Api/Sucursales/sucursalesReducer';
import EmpleadosReducer from './Api/Empleados/empleadosReducer';
import PromocionesReducer from './Api/Promociones/promocionesReducer';
import EquiposReducer from './Api/Equipos/equiposReducer';
import HorariosReducer from './Api/Horarios/horariosReducer';
import PuestosReducer from './Api/Puestos/puestosReducer';
import CitasReducer from './Api/Citas/citasReducer';
import VentasReducer from './Api/Ventas/ventasReducer';
import SesionReducer from './Api/Sesion/sesionReducer';
import thunk from 'redux-thunk';
import ComisionesReducer from './Api/Comisiones/comisionesReducer';
import PrestamosReducer  from './Api/Prestamos/prestamosReducer';
import NominaReducer from './Api/Nomina/nominaReducer';
import ReporteReducer from './Api/Reportes/reportesReducer';
import DepartamentosReducer from './Api/Departamentos/departamentosReducer';

const ApiReducer = combineReducers({
    SucursalesReducer: SucursalesReducer,
    EmpleadosReducer: EmpleadosReducer,
    PromocionesReducer: PromocionesReducer,
    EquiposReducer: EquiposReducer,
    HorariosReducer: HorariosReducer,
    PuestosReducer: PuestosReducer,
    CitasReducer: CitasReducer,
    VentasReducer: VentasReducer,
    SesionReducer: SesionReducer,
    ComisionesReducer: ComisionesReducer,
    PrestamosReducer: PrestamosReducer,
    NominaReducer: NominaReducer,
    ReporteReducer: ReporteReducer,
    DepartamentosReducer: DepartamentosReducer,
});

const store = createStore(ApiReducer, applyMiddleware(thunk));

export default store;