import React from 'react';
import Menu from '../../modulos/Menu/index';
import Grid from '@material-ui/core/Grid';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import Typography from '@material-ui/core/Typography';


const useStyles = makeStyles((theme) => ({
  table: {
      minWidth: 560,
    },
  paper: {
    marginTop: theme.spacing(4),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  form: {
    width: '100%', 
    marginTop: theme.spacing(3),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
  root: {
      display: 'flex',
    },
   toolbar: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'flex-end',
      padding: theme.spacing(0, 1),
      // necessary for content to be below app bar
      ...theme.mixins.toolbar,
    },
    content: {
      flexGrow: 1,
      padding: theme.spacing(3),
    },
    formControl: {
      margin: theme.spacing(1),
      minWidth: 120,
    },
    selectEmpty: {
      marginTop: theme.spacing(2),
    },
}));


  export default function Principal() {
    const classes = useStyles();


    return(
      <div className={classes.root}>
        <Menu title="Bienvenido"/>
        <main className={classes.content}>
          <div className={classes.toolbar} />
          <Container component="main" maxWidth="sm">
      <div className={classes.paper}>
        <Typography variant="h4">
          Pestaña principal 
          (proximamente Menú general)
        </Typography>
        <Grid container spacing={2}>
           <Grid item xs={12} sm={12} >
             Ultimos Cambios
             </ Grid>

             <Grid item xs={12} sm={6} >
             4 abril
             <div>
              ⚫️ Empleados <br />
                -Modificar empleados funciona correctamente <br />
                -Campo departamento en perfil del empleado<br/>
                -Campo departamento al agregar el empleado <br />

             </div>
             </ Grid>
             <Grid item xs={12} sm={6} >
             4 Abril
             <div>
              ⚫️ Departamentos <br />
                -Pestaña departamentos <br />
                -Registrar departamento <br />
                -Eliminar departamento <br />

             </div>
             </ Grid>

             <Grid item xs={12} sm={6} >
             5 abril
             <div>
              ⚫️ Reportes <br />
                -Flujo completo horarios <br />
                -Flujo completo departamentos <br />
                -Flujo completo empleados <br />

             </div>
             </ Grid>
             <Grid item xs={12} sm={6} >
             6 Abril
             <div>
              ⚫️ -Reportes <br />
                - Descargar reportes<br />
                - Flujo completo de reportes (Excepcion de errores de sistema y usuarios) <br />

             </div>
             </ Grid>
             <Grid item xs={12} sm={12} >
              Próximo en agregar (7 abril en adelante)
             </ Grid>

             <Grid item xs={12} sm={6} >
             <div>
             - Reporte flujo usuario <br />
             - Flujo Caja de Ahorro <br />
             - Flujo Nómina<br />
             </div>
             </ Grid>

             <Grid item xs={12} sm={6} >
             <div>
             - Visualizar reporte de nómina <br />
             - Optimización de Código <br/>
             - Agregar seguridad a usuarios <br />
             - Ajustes para reestricciones de usuarios<br />
             - Ajustes generales (reedireccionamiento de páginas, diseño, que al reiniciar no se cierre sesión, etc)
             </div>
             </ Grid>
             

             </ Grid>
             </div>
    </Container>
        </main>
      </div>
    )    
}
