import React, { useEffect } from 'react';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import { createMuiTheme } from '@material-ui/core/styles';
import Menu from '../../modulos/Menu/index';
import { Redirect } from "react-router-dom";
import * as EmpleadosActions from '../../Api/Empleados/actions';
import { connect } from 'react-redux';
import Snackbar from '@material-ui/core/Snackbar';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import DateFnsUtils from '@date-io/date-fns';
import { DatePicker,TimePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import { Empleados } from '../../Api/Prestamos/actionTypes';


const useStyles = makeStyles((theme) => ({
    paper: {
      marginTop: theme.spacing(4),
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
    },
    form: {
      width: '100%', 
      marginTop: theme.spacing(3),
    },
    submit: {
      margin: theme.spacing(3, 0, 2),
    },
    formControl: {
      minWidth: 120,
      width: '100%', 
    },
    selectEmpty: {
      marginTop: theme.spacing(3),
    },
    root: {
        display: 'flex',
      },
     toolbar: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-end',
        padding: theme.spacing(0, 1),
        // necessary for content to be below app bar
        ...theme.mixins.toolbar,
      },
      content: {
        flexGrow: 1,
        padding: theme.spacing(3),
      },
  }));

  const theme = createMuiTheme();
 
function RegistrarEmpleado(props) {  
  const [redirect, setRedirect] = React.useState(false);
  const [apellidoPaterno, setapellidoPaterno]  = React.useState('');
  const [apellidoMaterno, setapellidoMaterno]  = React.useState('');
  const [nombre, setNombre]  = React.useState('');
  const [direccion, setDireccion]  = React.useState('');
  const [telefono, setTelefono]  = React.useState('');
  const [fechaNacimiento, setfechaNacimiento]  = React.useState(new Date());
  const [rfc, setRfc]  = React.useState('');
  const [messageSnackbar, setMessageSnackbar] = React.useState('');
  const [sucur, setSucur] = React.useState('');
  const [sueldo, setSueldo] = React.useState('');
  const [horaInicio, setHoraInicio] = React.useState(new Date());
  const [horaFin, setHoraFin] = React.useState(new Date());
  const [isUserChecked, setIsUserChecked] = React.useState(false);
  const [isAdminChecked, setIsAdminChecked] = React.useState(false);
  const [username, setUserName] = React.useState('');
  const [password, setPassword] = React.useState('');
  const [permit, setPermit] = React.useState(true);
  const [horario, setHorario] = React.useState('');
  const [clave, setClave] = React.useState('');
  const [departamento, setDepartamento] = React.useState('');

  const classes = useStyles();

  useEffect(() => {
    if (permit) {
      props.getHorarios();
      props.getDepartamentos();
    }
    setPermit(false);
}, [permit]);


    const onComeBackToEmpleadosList = () => {
      setRedirect(true);
    }

    if (redirect){
      return <Redirect to='/catalogoempleados' /> 
    }

    const onChangeapellidoPaterno = (e) => {
      setapellidoPaterno(e.target.value);
    }

    const onChangeapellidoMaterno = (e) => {
      setapellidoMaterno(e.target.value);
    }

    const onChangeNombre = (e) => {
      setNombre(e.target.value);
    }

    const onChangeDireccion = (e) => {
      setDireccion(e.target.value);
    }

    const onChangeClave = (e) => {
      setClave(e.target.value);
    }

    const onChangefechaNacimiento = (e) => {
      setfechaNacimiento(e.target.value);
    }

    const onChangeTelefono = (e) => {
      setTelefono(e.target.value);
    }

    const onChangerfc = (e) => {
      setRfc(e.target.value);
    }

    const handleChangeSucursal = (event) => {
      setSucur(event.target.value);
    }

    const onChangeUsername = (e) => {
      setUserName(e.target.value);
    }

    const onChangePassword = (e) => {
      setPassword(e.target.value);
    }

    const onChangeSueldo = (event) => {
      setSueldo(event.target.value);
    }

    const handleChangeSetUser = (event) => {
      setIsAdminChecked(false);
      setUserName('');
      setPassword('');
      setIsUserChecked(event.target.checked);
    };

    const handleChangeSetAdmin = (event) => {
      setIsAdminChecked(event.target.checked);
    };

    const handleChangeHorario = (event) => {
      setHorario(event.target.value);
    }

    const handleChangeDepartamento = (event) => {
      setDepartamento(event.target.value);
    }

    const onRegistrarEmpleado = () => {
      const empleado = {
        primerApellido: apellidoPaterno,
        segundoApellido: apellidoMaterno,
        nombre,
        direccion,
        celular: telefono,
        fechaNacimiento,
        rfc,
        idSucursal: sucur,
        sueldo,
        clave,
        esAdmin: isAdminChecked,
        esUser: isUserChecked,
        username,
        password,
        idHorario:horario,
        idUsuarioAgrego: props.addSesion.user.id,
        idDepartamento: departamento
      };

      props.AddEmpleado(empleado).then((result) => {
          if (result && result.message) {
            setMessageSnackbar(result.message);
          }
      });

    }

    return(
        <div className={classes.root}>
        <Menu title="Administración de empleados"/>
        <main className={classes.content}>
          <div className={classes.toolbar} />
          <Container component="main" maxWidth="sm">
      <div className={classes.paper}>
        <Typography variant="h4">
          Registro de empleados
        </Typography>
        <form className={classes.form}>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={6} >
              <TextField
                name="idEmpelado"
                variant="outlined"
                fullWidth
                id="txtIdEmpleado"
                label="ID Empleado"
                disabled
              />
            </Grid>
        
            <Grid item xs={12} sm={6}>
              <TextField
                name="Nombre"
                variant="outlined"
                fullWidth
                id="txtNombre"
                label="Nombres"
                value={nombre}
                onChange={onChangeNombre}
              />
            </Grid>

            <Grid item xs={12} sm={6}>
              <TextField
                name="apellidoPaterno"
                variant="outlined"
                fullWidth
                id="txtApapellidoPaterno"
                label="Primer Apellido"
                value={apellidoPaterno}
                onChange={onChangeapellidoPaterno}
              />
            </Grid>

            <Grid item xs={12} sm={6}>
              <TextField
                name="apellidoMaterno"
                variant="outlined"
                fullWidth
                id="txtApapellidoMaterno"
                label="Segundo Apellido"
                value={apellidoMaterno}
                onChange={onChangeapellidoMaterno}
              />
            </Grid>

            <Grid item xs={12}>
              <TextField
                name="Direccion"
                variant="outlined"
                fullWidth
                id="txtDireccion"
                label="Dirección"
                value={direccion}
                onChange={onChangeDireccion}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                name="Telefono"
                variant="outlined"
                fullWidth
                id="txtTelefono"
                label="Teléfono"
                value={telefono}
                onChange={onChangeTelefono}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
            <MuiPickersUtilsProvider utils={DateFnsUtils}>
              <DatePicker
                variant="inline"
                inputVariant="outlined"
                fullWidth
                autoOk
                id="fecha"
                label="Fecha de nacimiento"
                format="yyyy-MM-dd"
                defaultValue={fechaNacimiento}
                value={fechaNacimiento}
                onChange={(date) => setfechaNacimiento(date)}
              />
              </MuiPickersUtilsProvider>
            </Grid>
            <Grid item xs={12} sm={6} >
              <TextField
                name="rfc"
                variant="outlined"
                fullWidth
                id="txtRfc"
                label="RFC"
                value={rfc}
                onChange={onChangerfc}
              />
            </Grid>

            <Grid item xs={12} sm={6} >
            <FormControl className={classes.formControl} variant="outlined">
                  <InputLabel id="seleccionar-sucursal">Sucursal</InputLabel>
                  <Select
                    labelId="sucursales"
                    id="sucursaless"
                    value={sucur}
                    onChange={handleChangeSucursal}
                    fullWidth
                  >
                    <MenuItem value="">
                      <em>Ninguna</em>
                    </MenuItem>
                    {
                        props.sucursales.map(item => {
                          return  (<MenuItem value={item.id}>
                          <em>{item.nombre} </em>
                        </MenuItem>);
                        })
                    }
                  </Select>
                </FormControl>
            </Grid>
            <Grid item xs={12} sm={6} >
              <TextField
                name="Sueldo"
                variant="outlined"
                fullWidth
                id="txtSueld"
                label="Sueldo"
                value={sueldo}
                onChange={onChangeSueldo}
              />
            </Grid>
            <Grid item xs={12} sm={6} >
            <FormControl className={classes.formControl} variant="outlined">
                  <InputLabel id="seleccionar-departamento">Departamento</InputLabel>
                  <Select
                    labelId="departamento"
                    id="departamento"
                    value={departamento}
                    onChange={handleChangeDepartamento}
                    fullWidth
                  >
                    <MenuItem value="">
                      <em>Ninguna</em>
                    </MenuItem>
                    {
                        props.departamentos && props.departamentos.departamentoList && props.departamentos.departamentoList.map(item => {
                          return  (<MenuItem value={item.id}>
                          <em>{item.descripcion} </em>
                        </MenuItem>);
                        })
                    }
                  </Select>
                </FormControl>
            </Grid>
            <Grid item xs={12} sm={6} >
            <FormControl className={classes.formControl} variant="outlined">
                  <InputLabel id="seleccionar-sucursal">Horario</InputLabel>
                  <Select
                    labelId="horarios"
                    id="horarios"
                    value={horario}
                    onChange={handleChangeHorario}
                    fullWidth
                  >
                    <MenuItem value="">
                      <em>Ninguna</em>
                    </MenuItem>
                    {
                        props.horarios && props.horarios.horarios && props.horarios.horarios.map(item => {
                          return  (<MenuItem value={item.id}>
                          <em>{item.descripcion} </em>
                        </MenuItem>);
                        })
                    }
                  </Select>
                </FormControl>
            </Grid>
            <Grid item xs={12} sm={6} >
            <TextField
                name="Clave"
                variant="outlined"
                fullWidth
                id="txtClave"
                label="Clave"
                value={clave}
                onChange={onChangeClave}
              />
            </Grid>
            <Grid item xs={12} sm={6} >
              <FormControlLabel
                control={
                  <Checkbox
                    checked={isUserChecked}
                    onChange={handleChangeSetUser}
                    color="primary"
                    inputProps={{ 'aria-label': 'secondary checkbox' }}
                  />
                }
                label="Agregar Usuario"
              />
            </Grid>
            <Grid item xs={12} sm={6} >
            {
              isUserChecked ? 
              <FormControlLabel
                control={
                  <Checkbox
                  checked={isAdminChecked}
                  onChange={handleChangeSetAdmin}
                  color="primary"
                  inputProps={{ 'aria-label': 'secondary checkbox' }}
                  />
                }
                label="Es Adminisrador"
              />
                : ''
            }
            </Grid>
            {
              isUserChecked ? 

              <Grid item xs={12} sm={6} >
              <TextField
                name="Usuario"
                variant="outlined"
                fullWidth
                id="txtUsuario"
                label="Usuario"
                value={username}
                onChange={onChangeUsername}
              />
              </Grid>
              
                : ''
            }
            {
              isUserChecked ? 

              <Grid item xs={12} sm={6} >
              <TextField
                name="Contraseña"
                variant="outlined"
                fullWidth
                id="txtContraseña"
                label="Contraseña"
                value={password}
                type="password"
                onChange={onChangePassword}
              />
              </Grid>
                : ''
            }
            <Grid item xs={12} sm={6} >
            <Button
            fullWidth
            variant="outlined"
            color="primary"
            onClick={onRegistrarEmpleado}
          >
            Registrar
          </Button>
            </Grid>
            <Grid item xs={12} sm={6} >
            <Button
            fullWidth
            variant="outlined"
            color="secondary"
            onClick={onComeBackToEmpleadosList}
          >
            Cancelar
          </Button>
            </Grid>
          </Grid>
        </form>
      </div>
    </Container>

    <Snackbar
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
        open={Boolean(messageSnackbar)}
        onClose={()=>setMessageSnackbar('')}
        message={messageSnackbar}
        autoHideDuration={6000}
      />
        </main>
      </div>
    )

}

const mapStateToProps = state => {
  return {
    addEmpleado: state.EmpleadosReducer.addEmpleado,
    sucursales: state.EmpleadosReducer.sucursales,
    horarios: state.EmpleadosReducer.horarios,
    addSesion: state.SesionReducer.addSesion,
    departamentos: state.EmpleadosReducer.departamentos
  }
}

const mapDispatchToProps = dispatch => {
  return {
    AddEmpleado: (Empleado) => dispatch(EmpleadosActions.AddEmpleado(Empleado)),
    getHorarios: () => dispatch(EmpleadosActions.getAllHorarios()),
    getDepartamentos: () => dispatch(EmpleadosActions.getAllDepartamentos()),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(RegistrarEmpleado);
