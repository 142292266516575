import * as actionTypes from './actionTypes';

const initialState = {
    getComisiones: undefined
};

export default function ComisionesReducer(state, action) {
    if (typeof state === 'undefined') {
        return initialState;
    }
    switch (action.type) {
        case actionTypes.GetComisiones:
            return Object.assign({}, state, {
                getComisiones: action.payload,
            });
        default: return state;
    }
}