import * as actionTypes from './actionTypes';

const initialState = {
    services: undefined,
    addVenta: undefined
};

export default function VentasReducer(state, action) {
    if (typeof state === 'undefined') {
        return initialState;
    }
    switch (action.type) {
        case actionTypes.GetServices:
            return Object.assign({}, state, {
                services: action.payload,
            });
            case actionTypes.AddVenta:
            return Object.assign({}, state, {
                addVenta: action.payload,
            });
        default: return state;
    }
}