import * as actionTypes from './actionTypes';

const initialState = {
    citas: undefined,
    citasModified: undefined,
    CitaById: undefined
};

export default function CitasReducer(state, action) {
    if (typeof state === 'undefined') {
        return initialState;
    }
    switch (action.type) {
        case actionTypes.GetCitas:
            return Object.assign({}, state, {
                citas: action.payload,
            });
            case actionTypes.modifyCitas:
                return Object.assign({}, state, {
                    citasModified: action.payload,
            });
            case actionTypes.GetCitaById:
                    return Object.assign({}, state, {
                        CitaById: action.payload,
            });
        default: return state;
    }
}