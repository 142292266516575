import React from 'react';
import { Switch, Route, BrowserRouter} from 'react-router-dom';
import { AuthRoute } from './AuthRoute.js';
import { connect} from 'react-redux';
import Routes from './routes';
import Login from './Login/index';


function EnrutadorLogin(props){
    return(
        <BrowserRouter>
            <Switch>
                <Route exact path='/login' component={Login} /> 
                <AuthRoute component={Routes} token={props.addSesion}/>
            </Switch>
        </BrowserRouter> 
    )
}

const mapStateToProps = state => {
    return {
        addSesion: state.SesionReducer.addSesion
    }
  }
  
  const mapDispatchToProps = dispatch => {
    return {
        
    }
  }
  
  export default connect(mapStateToProps, mapDispatchToProps)(EnrutadorLogin);