import * as actionTypes from './actionTypes';

const initialState = {
    addSesion: undefined
};

export default function SesionReducer(state, action) {
    if (typeof state === 'undefined') {
        return initialState;
    }
    switch (action.type) {
        case actionTypes.AddSesion:
            return Object.assign({}, state, {
                addSesion: action.payload,
            });
        default: return state;
    }
}