import * as actionTypes from './actionTypes';

const initialState = {
    promociones: undefined,
    promocionesModified: undefined,
    alterPromocion: undefined,
    deletePromocion: undefined,
    promocionById: undefined
};

export default function PromocionesReducer(state, action) {
    if (typeof state === 'undefined') {
        return initialState;
    }
    switch (action.type) {
        case actionTypes.GetPromociones:
            return Object.assign({}, state, {
                promociones: action.payload,
            });
            case actionTypes.modifyPromociones:
                return Object.assign({}, state, {
                    promocionesModified: action.payload,
            });
            case actionTypes.AddPromocion:
                return Object.assign({}, state, {
                    AddPromocion: action.payload,
            });
            case actionTypes.GetPromocionById:
                return Object.assign({}, state, {
                    promocionById: action.payload,
            });
            case actionTypes.AlterPromocion:
                return Object.assign({}, state, {
                    alterPromocion: action.payload,
            });
            case actionTypes.DeletePromocion:
                return Object.assign({}, state, {
                    deletePromocion: action.payload,
            });
        default: return state;
    }
}