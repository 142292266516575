import React, { useEffect } from 'react';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import { createMuiTheme } from '@material-ui/core/styles';
import Menu from '../../modulos/Menu/index';
import { Redirect } from "react-router-dom";
import * as EmpleadosActions from '../../Api/Empleados/actions';
import { connect } from 'react-redux';
import Snackbar from '@material-ui/core/Snackbar';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import DateFnsUtils from '@date-io/date-fns';
import { DatePicker, TimePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import * as HorariosActions from '../../Api/Horarios/actions';
import Box from '@material-ui/core/Box';
import Collapse from '@material-ui/core/Collapse';
import IconButton from '@material-ui/core/IconButton';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';


const useStyles = makeStyles((theme) => ({
    paper: {
        marginTop: theme.spacing(4),
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
    },
    form: {
        width: '100%',
        marginTop: theme.spacing(3),
    },
    submit: {
        margin: theme.spacing(3, 0, 2),
    },
    formControl: {
        minWidth: 120,
        width: '100%',
    },
    selectEmpty: {
        marginTop: theme.spacing(3),
    },
    root: {
        display: 'flex',
    },
    toolbar: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-end',
        padding: theme.spacing(0, 1),
        // necessary for content to be below app bar
        ...theme.mixins.toolbar,
    },
    content: {
        flexGrow: 1,
        padding: theme.spacing(3),
    },
}));

const theme = createMuiTheme();

const useRowStyles = makeStyles({
    root: {
      '& > *': {
        borderBottom: 'unset',
      },
    },
  });

function Row(props) {
    const { row, rowAModificar, openModificar, rowAEliminar, openEliminar } = props;
    const [open, setOpen] = React.useState(false);
    const classes = useRowStyles();
    
    const onSetInfoRowModificar = (row) => {
        openModificar(true);
        console.log(row);
        rowAModificar(row);
    }

    const onEliminarHorario = (row) => {
        openEliminar(true);
        rowAEliminar(row);
    }
  
    return (
      <React.Fragment>
        <TableRow className={classes.root}>
          <TableCell>
            <IconButton aria-label="expand row" size="small" onClick={() => setOpen(!open)}>
              {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
            </IconButton>
          </TableCell>
          <TableCell component="th" scope="row">
            {row.id}
          </TableCell>
          <TableCell align="right">{row.descripcion}</TableCell>
          <TableCell align="right">{row.status}</TableCell>
          <TableCell align="right">{row.createdAt}</TableCell>
          <TableCell align="right">{row.updatedAt}</TableCell>
          <TableCell align="right">{<Button onClick={() => {onSetInfoRowModificar(row)}}>Modificar</Button>}</TableCell>
          <TableCell align="right">{<Button onClick={() => onEliminarHorario(row)}>Eliminar</Button>}</TableCell>
        </TableRow>
        <TableRow>
          <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
            <Collapse in={open} timeout="auto" unmountOnExit>
              <Box margin={1}>
                <Typography variant="h6" gutterBottom component="div">
                  Horario
                </Typography>
                <Table size="small" aria-label="purchases">
                  <TableHead>
                    <TableRow>
                      <TableCell>Día</TableCell>
                      <TableCell>Hora Inicio 1</TableCell>
                      <TableCell >Hora Fin 1</TableCell>
                      <TableCell >Hora Inicio 2</TableCell>
                      <TableCell >Hora Fin 2</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                      <TableRow>
                          <TableCell component="th" scope="row">
                            {"Lunes"}
                          </TableCell>
                          <TableCell component="th" scope="row">
                            {row.horario.lunes && row.horario.lunes.horaInicio1 ? row.horario.lunes.horaInicio1 : '-'}
                          </TableCell>
                          <TableCell component="th" scope="row">
                          {row.horario.lunes && row.horario.lunes.horaFin1 ? row.horario.lunes.horaFin1 : '-'}
                          </TableCell>
                          <TableCell component="th" scope="row">
                          {row.horario.lunes && row.horario.lunes.horaInicio2 ? row.horario.lunes.horaInicio2 : '-'}
                          </TableCell>
                          <TableCell component="th" scope="row">
                          {row.horario.lunes && row.horario.lunes.horaFin2 ? row.horario.lunes.horaFin2 : '-'}
                          </TableCell>
                      </TableRow>
                      <TableRow>
                          <TableCell component="th" scope="row">
                            {"Martes"}
                          </TableCell>
                          <TableCell component="th" scope="row">
                            {row.horario.martes && row.horario.martes.horaInicio1 ? row.horario.martes.horaInicio1 : '-'}
                          </TableCell>
                          <TableCell component="th" scope="row">
                          {row.horario.martes && row.horario.martes.horaFin1 ? row.horario.martes.horaFin1 : '-'}
                          </TableCell>
                          <TableCell component="th" scope="row">
                          {row.horario.martes && row.horario.martes.horaInicio2 ? row.horario.martes.horaInicio2 : '-'}
                          </TableCell>
                          <TableCell component="th" scope="row">
                          {row.horario.martes && row.horario.martes.horaFin2 ? row.horario.martes.horaFin2 : '-'}
                          </TableCell>
                      </TableRow>
                      <TableRow>
                          <TableCell component="th" scope="row">
                            {"Miercoles"}
                          </TableCell>
                          <TableCell component="th" scope="row">
                            {row.horario.miercoles && row.horario.miercoles.horaInicio1 ? row.horario.miercoles.horaInicio1 : '-'}
                          </TableCell>
                          <TableCell component="th" scope="row">
                            {row.horario.miercoles && row.horario.miercoles.horaFin1 ? row.horario.miercoles.horaFin1 : '-'}
                          </TableCell>
                          <TableCell component="th" scope="row">
                          {row.horario.miercoles && row.horario.miercoles.horaInicio2 ? row.horario.miercoles.horaInicio2 : '-'}
                          </TableCell>
                          <TableCell component="th" scope="row">
                          {row.horario.miercoles && row.horario.miercoles.horaFin2 ? row.horario.miercoles.horaFin2 : '-'}
                          </TableCell>
                      </TableRow>
                      <TableRow>
                          <TableCell component="th" scope="row">
                            {"Jueves"}
                          </TableCell>
                          <TableCell component="th" scope="row">
                            {row.horario.jueves && row.horario.jueves.horaInicio1 ? row.horario.jueves.horaInicio1 : '-'}
                          </TableCell>
                          <TableCell component="th" scope="row">
                            {row.horario.jueves && row.horario.jueves.horaFin1 ? row.horario.jueves.horaFin1 : '-'}
                          </TableCell>
                          <TableCell component="th" scope="row">
                          {row.horario.jueves && row.horario.jueves.horaInicio2 ? row.horario.jueves.horaInicio2 : '-'}
                          </TableCell>
                          <TableCell component="th" scope="row">
                          {row.horario.jueves && row.horario.jueves.horaFin2 ? row.horario.jueves.horaFin2 : '-'}
                          </TableCell>
                      </TableRow>
                      <TableRow>
                          <TableCell component="th" scope="row">
                            {"Viernes"}
                          </TableCell>
                          <TableCell component="th" scope="row">
                            {row.horario.viernes && row.horario.viernes.horaInicio1 ? row.horario.viernes.horaInicio1 : '-'}
                          </TableCell>
                          <TableCell component="th" scope="row">
                            {row.horario.viernes && row.horario.viernes.horaFin1 ? row.horario.viernes.horaFin1 : '-'}
                          </TableCell>
                          <TableCell component="th" scope="row">
                          {row.horario.viernes && row.horario.viernes.horaInicio2 ? row.horario.viernes.horaInicio2 : '-'}
                          </TableCell>
                          <TableCell component="th" scope="row">
                          {row.horario.viernes && row.horario.viernes.horaFin2 ? row.horario.viernes.horaFin2 : '-'}
                          </TableCell>
                      </TableRow>
                      <TableRow>
                          <TableCell component="th" scope="row">
                            {"Sábado"}
                          </TableCell>
                          <TableCell component="th" scope="row">
                            {row.horario.sabado && row.horario.sabado.horaInicio1 ? row.horario.sabado.horaInicio1 : '-'}
                          </TableCell>
                          <TableCell component="th" scope="row">
                            {row.horario.sabado && row.horario.sabado.horaFin1 ? row.horario.sabado.horaFin1 : '-'}
                          </TableCell>
                          <TableCell component="th" scope="row">
                          {row.horario.sabado && row.horario.sabado.horaInicio2 ? row.horario.sabado.horaInicio2 : '-'}
                          </TableCell>
                          <TableCell component="th" scope="row">
                          {row.horario.sabado && row.horario.sabado.horaFin2 ? row.horario.sabado.horaFin2 : '-'}
                          </TableCell>
                      </TableRow>
                      <TableRow>
                          <TableCell component="th" scope="row">
                            {"Domingo"}
                          </TableCell>
                          <TableCell component="th" scope="row">
                            {row.horario.domingo && row.horario.domingo.horaInicio1 ? row.horario.domingo.horaInicio1 : '-'}
                          </TableCell>
                          <TableCell component="th" scope="row">
                            {row.horario.domingo && row.horario.domingo.horaFin1 ? row.horario.domingo.horaFin1 : '-'}
                          </TableCell>
                          <TableCell component="th" scope="row">
                          {row.horario.domingo && row.horario.domingo.horaInicio2 ? row.horario.domingo.horaInicio2 : '-'}
                          </TableCell>
                          <TableCell component="th" scope="row">
                          {row.horario.domingo && row.horario.domingo.horaFin2 ? row.horario.domingo.horaFin2 : '-'}
                          </TableCell>
                      </TableRow>
                  </TableBody>
                </Table>
              </Box>
            </Collapse>
          </TableCell>
        </TableRow>
      </React.Fragment>
    );
  }

function Horario(props) {
    const [redirect, setRedirect] = React.useState(false);
    const [fechaNacimiento, setfechaNacimiento] = React.useState(new Date());
    const [rfc, setRfc] = React.useState('');
    const [messageSnackbar, setMessageSnackbar] = React.useState('');

    const [HoraInicio1Lunes, setHoraInicio1Lunes] = React.useState('');
    const [HoraFin1Lunes, setHoraFin1Lunes] = React.useState('');
    const [HoraInicio2Lunes, setHoraInicio2Lunes] = React.useState('');
    const [HoraFin2Lunes, setHoraFin2Lunes] = React.useState('');
    const [HoraInicio3Lunes, setHoraInicio3Lunes] = React.useState('');
    const [HoraFin3Lunes, setHoraFin3Lunes] = React.useState('');

    const [HoraInicio1Martes, setHoraInicio1Martes] = React.useState('');
    const [HoraFin1Martes, setHoraFin1Martes] = React.useState('');
    const [HoraInicio2Martes, setHoraInicio2Martes] = React.useState('');
    const [HoraFin2Martes, setHoraFin2Martes] = React.useState('');
    const [HoraInicio3Martes, setHoraInicio3Martes] = React.useState('');
    const [HoraFin3Martes, setHoraFin3Martes] = React.useState('');

    const [HoraInicio1Miercoles, setHoraInicio1Miercoles] = React.useState('');
    const [HoraFin1Miercoles, setHoraFin1Miercoles] = React.useState('');
    const [HoraInicio2Miercoles, setHoraInicio2Miercoles] = React.useState('');
    const [HoraFin2Miercoles, setHoraFin2Miercoles] = React.useState('');
    const [HoraInicio3Miercoles, setHoraInicio3Miercoles] = React.useState('');
    const [HoraFin3Miercoles, setHoraFin3Miercoles] = React.useState('');

    const [HoraInicio1Jueves, setHoraInicio1Jueves] = React.useState('');
    const [HoraFin1Jueves, setHoraFin1Jueves] = React.useState('');
    const [HoraInicio2Jueves, setHoraInicio2Jueves] = React.useState('');
    const [HoraFin2Jueves, setHoraFin2Jueves] = React.useState('');
    const [HoraInicio3Jueves, setHoraInicio3Jueves] = React.useState('');
    const [HoraFin3Jueves, setHoraFin3Jueves] = React.useState('');

    const [HoraInicio1Viernes, setHoraInicio1Viernes] = React.useState('');
    const [HoraFin1Viernes, setHoraFin1Viernes] = React.useState('');
    const [HoraInicio2Viernes, setHoraInicio2Viernes] = React.useState('');
    const [HoraFin2Viernes, setHoraFin2Viernes] = React.useState('');
    const [HoraInicio3Viernes, setHoraInicio3Viernes] = React.useState('');
    const [HoraFin3Viernes, setHoraFin3Viernes] = React.useState('');

    const [HoraInicio1Sabado, setHoraInicio1Sabado] = React.useState('');
    const [HoraFin1Sabado, setHoraFin1Sabado] = React.useState('');
    const [HoraInicio2Sabado, setHoraInicio2Sabado] = React.useState('');
    const [HoraFin2Sabado, setHoraFin2Sabado] = React.useState('');
    const [HoraInicio3Sabado, setHoraInicio3Sabado] = React.useState('');
    const [HoraFin3Sabado, setHoraFin3Sabado] = React.useState('');

    const [HoraInicio1Domingo, setHoraInicio1Domingo] = React.useState('');
    const [HoraFin1Domingo, setHoraFin1Domingo] = React.useState('');
    const [HoraInicio2Domingo, setHoraInicio2Domingo] = React.useState('');
    const [HoraFin2Domingo, setHoraFin2Domingo] = React.useState('');
    const [HoraInicio3Domingo, setHoraInicio3Domingo] = React.useState('');
    const [HoraFin3Domingo, setHoraFin3Domingo] = React.useState('');

    const [descripcion, setDescripcion] = React.useState('');

    const [agregarEmpleado, setOpenAgregarEmpleado] = React.useState(false);
    const [lunes, setLunesChecked] = React.useState(false);
    const [martes, setMartesChecked] = React.useState(false);
    const [miercoles, setMiercolesChecked] = React.useState(false);
    const [jueves, setJuevesChecked] = React.useState(false);
    const [viernes, setViernesChecked] = React.useState(false);
    const [sabado, setSabadoChecked] = React.useState(false);
    const [domingo, setDomingoChecked] = React.useState(false);
    const [permit, setPermit] = React.useState(true);
    const [horarios, setHorarios] = React.useState('');

    const [modificarHorario, setModificarHorario] = React.useState(false);

    const [horarioRotativo, setHorarioRotativo]  = React.useState(false);

    const [eliminarHorario, setHorarioEliminar] = React.useState('');
    const [openEliminar, setOpenEliminar] = React.useState(false);

    const classes = useStyles();

    
    const [descripcionModificar, setDescripcionModificar] = React.useState('');
    const [horarioRotativoModificar, setHorarioRotativoModificar]  = React.useState(false);

    const [lunesModificar, setLunesCheckedModificar] = React.useState(false);
    const [martesModificar, setMartesCheckedModificar] = React.useState(false);
    const [miercolesModificar, setMiercolesCheckedModificar] = React.useState(false);
    const [juevesModificar, setJuevesCheckedModificar] = React.useState(false);
    const [viernesModificar, setViernesCheckedModificar] = React.useState(false);
    const [sabadoModificar, setSabadoCheckedModificar] = React.useState(false);
    const [domingoModificar, setDomingoCheckedModificar] = React.useState(false);

    const [HoraInicio1LunesModificar, setHoraInicio1LunesModificar] = React.useState('');
    const [HoraFin1LunesModificar, setHoraFin1LunesModificar] = React.useState('');
    const [HoraInicio2LunesModificar, setHoraInicio2LunesModificar] = React.useState('');
    const [HoraFin2LunesModificar, setHoraFin2LunesModificar] = React.useState('');
    const [HoraInicio3LunesModificar, setHoraInicio3LunesModificar] = React.useState('');
    const [HoraFin3LunesModificar, setHoraFin3LunesModificar] = React.useState('');

    const [HoraInicio1MartesModificar, setHoraInicio1MartesModificar] = React.useState('');
    const [HoraFin1MartesModificar, setHoraFin1MartesModificar] = React.useState('');
    const [HoraInicio2MartesModificar, setHoraInicio2MartesModificar] = React.useState('');
    const [HoraFin2MartesModificar, setHoraFin2MartesModificar] = React.useState('');
    const [HoraInicio3MartesModificar, setHoraInicio3MartesModificar] = React.useState('');
    const [HoraFin3MartesModificar, setHoraFin3MartesModificar] = React.useState('');

    const [HoraInicio1MiercolesModificar, setHoraInicio1MiercolesModificar] = React.useState('');
    const [HoraFin1MiercolesModificar, setHoraFin1MiercolesModificar] = React.useState('');
    const [HoraInicio2MiercolesModificar, setHoraInicio2MiercolesModificar] = React.useState('');
    const [HoraFin2MiercolesModificar, setHoraFin2MiercolesModificar] = React.useState('');
    const [HoraInicio3MiercolesModificar, setHoraInicio3MiercolesModificar] = React.useState('');
    const [HoraFin3MiercolesModificar, setHoraFin3MiercolesModificar] = React.useState('');

    const [HoraInicio1JuevesModificar, setHoraInicio1JuevesModificar] = React.useState('');
    const [HoraFin1JuevesModificar, setHoraFin1JuevesModificar] = React.useState('');
    const [HoraInicio2JuevesModificar, setHoraInicio2JuevesModificar] = React.useState('');
    const [HoraFin2JuevesModificar, setHoraFin2JuevesModificar] = React.useState('');
    const [HoraInicio3JuevesModificar, setHoraInicio3JuevesModificar] = React.useState('');
    const [HoraFin3JuevesModificar, setHoraFin3JuevesModificar] = React.useState('');

    const [HoraInicio1ViernesModificar, setHoraInicio1ViernesModificar] = React.useState('');
    const [HoraFin1ViernesModificar, setHoraFin1ViernesModificar] = React.useState('');
    const [HoraInicio2ViernesModificar, setHoraInicio2ViernesModificar] = React.useState('');
    const [HoraFin2ViernesModificar, setHoraFin2ViernesModificar] = React.useState('');
    const [HoraInicio3ViernesModificar, setHoraInicio3ViernesModificar] = React.useState('');
    const [HoraFin3ViernesModificar, setHoraFin3ViernesModificar] = React.useState('');

    const [HoraInicio1SabadoModificar, setHoraInicio1SabadoModificar] = React.useState('');
    const [HoraFin1SabadoModificar, setHoraFin1SabadoModificar] = React.useState('');
    const [HoraInicio2SabadoModificar, setHoraInicio2SabadoModificar] = React.useState('');
    const [HoraFin2SabadoModificar, setHoraFin2SabadoModificar] = React.useState('');
    const [HoraInicio3SabadoModificar, setHoraInicio3SabadoModificar] = React.useState('');
    const [HoraFin3SabadoModificar, setHoraFin3SabadoModificar] = React.useState('');

    const [HoraInicio1DomingoModificar, setHoraInicio1DomingoModificar] = React.useState('');
    const [HoraFin1DomingoModificar, setHoraFin1DomingoModificar] = React.useState('');
    const [HoraInicio2DomingoModificar, setHoraInicio2DomingoModificar] = React.useState('');
    const [HoraFin2DomingoModificar, setHoraFin2DomingoModificar] = React.useState('');
    const [HoraInicio3DomingoModificar, setHoraInicio3DomingoModificar] = React.useState('');
    const [HoraFin3DomingoModificar, setHoraFin3DomingoModificar] = React.useState('');

    const [ IDModificar,setIDModificar] = React.useState('');


    useEffect(() => {
        if (permit) {
            props.addSesion && props.addSesion.user && props.addSesion.user.admin == false ?
                props.getHorarios()
                :
                props.getHorarios().then(result => {
                    if (result && Array.isArray(result)) {
                        setHorarios(result);
                        setPermit(false);
                    }
                });
        }
        setPermit(false);
    }, [permit]);


    if (redirect) {
        return <Redirect to='/catalogoempleados' />
    }

    const onChangerfc = (e) => {
        setRfc(e.target.value);
    }

    const handleOpenAgregarHorario = () => {
        setOpenAgregarEmpleado(true);
    }

    const handleCloseAgregarEmpleado = () => {
        setLunesChecked(false);
        setMartesChecked(false);
        setMiercolesChecked(false);
        setJuevesChecked(false);
        setViernesChecked(false);
        setSabadoChecked(false);
        setDomingoChecked(false);

        setHoraInicio1Lunes('');
        setHoraFin1Lunes('');
        setHoraInicio2Lunes('');
        setHoraFin2Lunes('');
        setHoraInicio3Lunes('');
        setHoraFin3Lunes('');

        setHoraInicio1Martes('');
        setHoraFin1Martes('');
        setHoraInicio2Martes('');
        setHoraFin2Martes('');
        setHoraInicio3Martes('');
        setHoraFin3Martes('');

        setHoraInicio1Miercoles('');
        setHoraFin1Miercoles('');
        setHoraInicio2Miercoles('');
        setHoraFin2Miercoles('');
        setHoraInicio3Miercoles('');
        setHoraFin3Miercoles('');

        setHoraInicio1Jueves('');
        setHoraFin1Jueves('');
        setHoraInicio2Jueves('');
        setHoraFin2Jueves('');
        setHoraInicio3Jueves('');
        setHoraFin3Jueves('');

        setHoraInicio1Viernes('');
        setHoraFin1Viernes('');
        setHoraInicio2Viernes('');
        setHoraFin2Viernes('');
        setHoraInicio3Viernes('');
        setHoraFin3Viernes('');

        setHoraInicio1Sabado('');
        setHoraFin1Sabado('');
        setHoraInicio2Sabado('');
        setHoraFin2Sabado('');
        setHoraInicio3Sabado('');
        setHoraFin3Sabado('');

        setHoraInicio1Domingo('');
        setHoraFin1Domingo('');
        setHoraInicio2Domingo('');
        setHoraFin2Domingo('');
        setHoraInicio3Domingo('');
        setHoraFin3Domingo('');

        setOpenAgregarEmpleado(false);
    }

    const handleChangeLunes = () => {
        setLunesChecked(!lunes);
    }
    const handleChangeMartes = () => {
        setMartesChecked(!martes);
    }
    const handleChangeMiercoles = () => {
        setMiercolesChecked(!miercoles);
    }
    const handleChangeJueves = () => {
        setJuevesChecked(!jueves);
    }
    const handleChangeViernes = () => {
        setViernesChecked(!viernes);
    }
    const handleChangeSabado = () => {
        setSabadoChecked(!sabado);
    }
    const handleChangeDomingo = () => {
        setDomingoChecked(!domingo);
    }


    const handleChangeLunesModificar = () => {
        setLunesCheckedModificar(!lunesModificar);
    }
    const handleChangeMartesModificar = () => {
        setMartesCheckedModificar(!martesModificar);
    }
    const handleChangeMiercolesModificar = () => {
        setMiercolesCheckedModificar(!miercolesModificar);
    }
    const handleChangeJuevesModificar = () => {
        setJuevesCheckedModificar(!juevesModificar);
    }
    const handleChangeViernesModificar = () => {
        setViernesCheckedModificar(!viernesModificar);
    }
    const handleChangeSabadoModificar = () => {
        setSabadoCheckedModificar(!sabadoModificar);
    }
    const handleChangeDomingoModificar = () => {
        setDomingoCheckedModificar(!domingoModificar);
    }

    const handleChangeHorarioRotativo = () => {
        setHorarioRotativo(!horarioRotativo);
    }

    const handleChangeHorarioRotativoModificar = () => {
        setHorarioRotativoModificar(!horarioRotativoModificar);
    }

    const onChangeDescripcion = (e) => {
        setDescripcion(e.target.value);
    }

    const handleAgregarEmpleado = () => {

        const horaInicio1LunesCasteada = HoraInicio1Lunes && `${('0' + HoraInicio1Lunes.getHours()).slice(-2)}:${('0' + HoraInicio1Lunes.getMinutes()).slice(-2)}`;
        const horaFin1LunesCasteada = HoraFin1Lunes && `${('0' + HoraFin1Lunes.getHours()).slice(-2)}:${('0' + HoraFin1Lunes.getMinutes()).slice(-2)}`;
        const horaInicio2LunesCasteada = HoraInicio2Lunes && `${('0' + HoraInicio2Lunes.getHours()).slice(-2)}:${('0' + HoraInicio2Lunes.getMinutes()).slice(-2)}`;
        const horaFin2LunesCasteada = HoraFin2Lunes && `${('0' + HoraFin2Lunes.getHours()).slice(-2)}:${('0' + HoraFin2Lunes.getMinutes()).slice(-2)}`;

        const horaInicio1MartesCasteada = HoraInicio1Martes && `${('0' + HoraInicio1Martes.getHours()).slice(-2)}:${('0' + HoraInicio1Martes.getMinutes()).slice(-2)}`;
        const horaFin1MartesCasteada = HoraFin1Martes && `${('0' + HoraFin1Martes.getHours()).slice(-2)}:${('0' + HoraFin1Martes.getMinutes()).slice(-2)}`;
        const horaInicio2MartesCasteada = HoraInicio2Martes && `${('0' + HoraInicio2Martes.getHours()).slice(-2)}:${('0' + HoraInicio2Martes.getMinutes()).slice(-2)}`;
        const horaFin2MartesCasteada = HoraFin2Martes && `${('0' + HoraFin2Martes.getHours()).slice(-2)}:${('0' + HoraFin2Martes.getMinutes()).slice(-2)}`;

        const horaInicio1MiercolesCasteada = HoraInicio1Miercoles && `${('0' + HoraInicio1Miercoles.getHours()).slice(-2)}:${('0' + HoraInicio1Miercoles.getMinutes()).slice(-2)}`;
        const horaFin1MiercolesCasteada = HoraFin1Miercoles && `${('0' + HoraFin1Miercoles.getHours()).slice(-2)}:${('0' + HoraFin1Miercoles.getMinutes()).slice(-2)}`;
        const horaInicio2MiercolesCasteada = HoraInicio2Miercoles && `${('0' + HoraInicio2Miercoles.getHours()).slice(-2)}:${('0' + HoraInicio2Miercoles.getMinutes()).slice(-2)}`;
        const horaFin2MiercolesCasteada = HoraFin2Miercoles && `${('0' + HoraFin2Miercoles.getHours()).slice(-2)}:${('0' + HoraFin2Miercoles.getMinutes()).slice(-2)}`;

        const horaInicio1JuevesCasteada = HoraInicio1Jueves && `${('0' + HoraInicio1Jueves.getHours()).slice(-2)}:${('0' + HoraInicio1Jueves.getMinutes()).slice(-2)}`;
        const horaFin1JuevesCasteada = HoraFin1Jueves && `${('0' + HoraFin1Jueves.getHours()).slice(-2)}:${('0' + HoraFin1Jueves.getMinutes()).slice(-2)}`;
        const horaInicio2JuevesCasteada = HoraInicio2Jueves && `${('0' + HoraInicio2Jueves.getHours()).slice(-2)}:${('0' + HoraInicio2Jueves.getMinutes()).slice(-2)}`;
        const horaFin2JuevesCasteada = HoraFin2Jueves && `${('0' + HoraFin2Jueves.getHours()).slice(-2)}:${('0' + HoraFin2Jueves.getMinutes()).slice(-2)}`;

        const horaInicio1ViernesCasteada = HoraInicio1Viernes && `${('0' + HoraInicio1Viernes.getHours()).slice(-2)}:${('0' + HoraInicio1Viernes.getMinutes()).slice(-2)}`;
        const horaFin1ViernesCasteada = HoraFin1Viernes && `${('0' + HoraFin1Viernes.getHours()).slice(-2)}:${('0' + HoraFin1Viernes.getMinutes()).slice(-2)}`;
        const horaInicio2ViernesCasteada = HoraInicio2Viernes && `${('0' + HoraInicio2Viernes.getHours()).slice(-2)}:${('0' + HoraInicio2Viernes.getMinutes()).slice(-2)}`;
        const horaFin2ViernesCasteada = HoraFin2Viernes && `${('0' + HoraFin2Viernes.getHours()).slice(-2)}:${('0' + HoraFin2Viernes.getMinutes()).slice(-2)}`;

        const horaInicio1SabadoCasteada = HoraInicio1Sabado && `${('0' + HoraInicio1Sabado.getHours()).slice(-2)}:${('0' + HoraInicio1Sabado.getMinutes()).slice(-2)}`;
        const horaFin1SabadoCasteada = HoraFin1Sabado && `${('0' + HoraFin1Sabado.getHours()).slice(-2)}:${('0' + HoraFin1Sabado.getMinutes()).slice(-2)}`;
        const horaInicio2SabadoCasteada = HoraInicio2Sabado && `${('0' + HoraInicio2Sabado.getHours()).slice(-2)}:${('0' + HoraInicio2Sabado.getMinutes()).slice(-2)}`;
        const horaFin2SabadoCasteada = HoraFin2Sabado && `${('0' + HoraFin2Sabado.getHours()).slice(-2)}:${('0' + HoraFin2Sabado.getMinutes()).slice(-2)}`;

        const horaInicio1DomingoCasteada = HoraInicio1Domingo && `${('0' + HoraInicio1Domingo.getHours()).slice(-2)}:${('0' + HoraInicio1Domingo.getMinutes()).slice(-2)}`;
        const horaFin1DomingoCasteada = HoraFin1Domingo && `${('0' + HoraFin1Domingo.getHours()).slice(-2)}:${('0' + HoraFin1Domingo.getMinutes()).slice(-2)}`;
        const horaInicio2DomingoCasteada = HoraInicio2Domingo && `${('0' + HoraInicio2Domingo.getHours()).slice(-2)}:${('0' + HoraInicio2Domingo.getMinutes()).slice(-2)}`;
        const horaFin2DomingoCasteada = HoraFin2Domingo && `${('0' + HoraFin2Domingo.getHours()).slice(-2)}:${('0' + HoraFin2Domingo.getMinutes()).slice(-2)}`;

        let horaInicio3LunesCasteada,horaFin3LunesCasteada,horaInicio3MartesCasteada,horaFin3MartesCasteada,
        horaInicio3MiercolesCasteada,horaFin3MiercolesCasteada,horaInicio3JuevesCasteada,horaFin3JuevesCasteada,
        horaInicio3ViernesCasteada,horaFin3ViernesCasteada,horaInicio3SabadoCasteada,horaFin3SabadoCasteada,
        horaInicio3DomingoCasteada,horaFin3DomingoCasteada;

        if (horarioRotativo) {
            horaInicio3LunesCasteada = HoraInicio3Lunes && `${('0' + HoraInicio3Lunes.getHours()).slice(-2)}:${('0' + HoraInicio3Lunes.getMinutes()).slice(-2)}`;
            horaFin3LunesCasteada = HoraFin3Lunes && `${('0' + HoraFin3Lunes.getHours()).slice(-2)}:${('0' + HoraFin3Lunes.getMinutes()).slice(-2)}`;
    
            horaInicio3MartesCasteada = HoraInicio3Martes && `${('0' + HoraInicio3Martes.getHours()).slice(-2)}:${('0' + HoraInicio3Martes.getMinutes()).slice(-2)}`;
            horaFin3MartesCasteada = HoraFin3Martes && `${('0' + HoraFin3Martes.getHours()).slice(-2)}:${('0' + HoraFin3Martes.getMinutes()).slice(-2)}`;
    
            horaInicio3MiercolesCasteada = HoraInicio3Miercoles && `${('0' + HoraInicio3Miercoles.getHours()).slice(-2)}:${('0' + HoraInicio3Miercoles.getMinutes()).slice(-2)}`;
            horaFin3MiercolesCasteada = HoraFin3Miercoles && `${('0' + HoraFin3Miercoles.getHours()).slice(-2)}:${('0' + HoraFin3Miercoles.getMinutes()).slice(-2)}`;
    
            horaInicio3JuevesCasteada = HoraInicio3Jueves && `${('0' + HoraInicio3Jueves.getHours()).slice(-2)}:${('0' + HoraInicio3Jueves.getMinutes()).slice(-2)}`;
            horaFin3JuevesCasteada = HoraFin3Jueves && `${('0' + HoraFin3Jueves.getHours()).slice(-2)}:${('0' + HoraFin3Jueves.getMinutes()).slice(-2)}`;
    
            horaInicio3ViernesCasteada = HoraInicio3Viernes && `${('0' + HoraInicio3Viernes.getHours()).slice(-2)}:${('0' + HoraInicio3Viernes.getMinutes()).slice(-2)}`;
            horaFin3ViernesCasteada = HoraFin3Viernes && `${('0' + HoraFin3Viernes.getHours()).slice(-2)}:${('0' + HoraFin3Viernes.getMinutes()).slice(-2)}`;
    
            horaInicio3SabadoCasteada = HoraInicio3Sabado && `${('0' + HoraInicio3Sabado.getHours()).slice(-2)}:${('0' + HoraInicio3Sabado.getMinutes()).slice(-2)}`;
            horaFin3SabadoCasteada = HoraFin3Sabado && `${('0' + HoraFin3Sabado.getHours()).slice(-2)}:${('0' + HoraFin3Sabado.getMinutes()).slice(-2)}`;
    
            horaInicio3DomingoCasteada = HoraInicio3Domingo && `${('0' + HoraInicio3Domingo.getHours()).slice(-2)}:${('0' + HoraInicio3Domingo.getMinutes()).slice(-2)}`;
            horaFin3DomingoCasteada = HoraFin3Domingo && `${('0' + HoraFin3Domingo.getHours()).slice(-2)}:${('0' + HoraFin3Domingo.getMinutes()).slice(-2)}`;
        }

        let horario = {
            lunes: {},
            martes: {},
            miercoles: {},
            jueves: {},
            viernes: {},
            sabado: {},
            domingo: {}
        };
        if (lunes) {
            horaInicio1LunesCasteada && (horario.lunes.horaInicio1 = horaInicio1LunesCasteada);
            horaFin1LunesCasteada && (horario.lunes.horaFin1 = horaFin1LunesCasteada);
            horaInicio2LunesCasteada && (horario.lunes.horaInicio2 = horaInicio2LunesCasteada);
            horaFin2LunesCasteada && (horario.lunes.horaInicio2 = horaFin2LunesCasteada);
            if (horarioRotativo){
                horaInicio3LunesCasteada && (horario.lunes.horaInicio3 = horaInicio3LunesCasteada);
                horaFin3LunesCasteada && (horario.lunes.horaFin3 = horaFin3LunesCasteada);
            }
        } else {
            horario.lunes = null;
        }
        if (martes) {
            horaInicio1MartesCasteada && (horario.martes.horaInicio1 = horaInicio1MartesCasteada)
            horaFin1MartesCasteada && (horario.martes.horaFin1 = horaFin1MartesCasteada)
            horaInicio2MartesCasteada && (horario.martes.horaInicio2 = horaInicio2MartesCasteada)
            horaFin2MartesCasteada && (horario.martes.horaFin2 = horaFin2MartesCasteada)
            if (horarioRotativo){
                horaInicio3MartesCasteada && (horario.martes.horaInicio3 = horaInicio3MartesCasteada)
                horaFin3MartesCasteada && (horario.martes.horaFin3 = horaFin3MartesCasteada)
            }
        } else {
            horario.martes = null;
        }
        if (miercoles) {
            horaInicio1MiercolesCasteada && (horario.miercoles.horaInicio1 = horaInicio1MiercolesCasteada)
            horaFin1MiercolesCasteada && (horario.miercoles.horaFin1 = horaFin1MiercolesCasteada)
            horaInicio2MiercolesCasteada && (horario.miercoles.horaInicio2 = horaInicio2MiercolesCasteada)
            horaFin2MiercolesCasteada && (horario.miercoles.horaFin2 = horaFin2MiercolesCasteada)
            if (horarioRotativo){
                horaInicio3MiercolesCasteada && (horario.miercoles.horaInicio3 = horaInicio3MiercolesCasteada)
                horaFin3MiercolesCasteada && (horario.miercoles.horaFin3 = horaFin3MiercolesCasteada)
            }
        } else {
            horario.miercoles = null;
        }
        if (jueves) {
            horaInicio1JuevesCasteada && (horario.jueves.horaInicio1 = horaInicio1JuevesCasteada)
            horaFin1JuevesCasteada && (horario.jueves.horaFin1 = horaFin1JuevesCasteada)
            horaInicio2JuevesCasteada && (horario.jueves.horaInicio2 = horaInicio2JuevesCasteada)
            horaFin2JuevesCasteada && (horario.jueves.horaFin2 = horaFin2JuevesCasteada)
            if (horarioRotativo){
                horaInicio3JuevesCasteada && (horario.jueves.horaInicio3 = horaInicio3JuevesCasteada)
                horaFin3JuevesCasteada && (horario.jueves.horaFin3 = horaFin3JuevesCasteada)
            }
        } else {
            horario.jueves = null;
        }
        if (viernes) {
            horaInicio1ViernesCasteada && (horario.viernes.horaInicio1 = horaInicio1ViernesCasteada)
            horaFin1ViernesCasteada && (horario.viernes.horaFin1 = horaFin1ViernesCasteada)
            horaInicio2ViernesCasteada && (horario.viernes.horaInicio2 = horaInicio2ViernesCasteada)
            horaFin2ViernesCasteada && (horario.viernes.horaFin2 = horaFin2ViernesCasteada)
            if (horarioRotativo){
                horaInicio3ViernesCasteada && (horario.viernes.horaInicio3 = horaInicio3ViernesCasteada)
                horaFin3ViernesCasteada && (horario.viernes.horaFin3 = horaFin3ViernesCasteada)
            }
        } else {
            horario.viernes = null;
        }
        if (sabado) {
            horaInicio1SabadoCasteada && (horario.sabado.horaInicio1 = horaInicio1SabadoCasteada)
            horaFin1SabadoCasteada && (horario.sabado.horaFin1 = horaFin1SabadoCasteada)
            horaInicio2SabadoCasteada && (horario.sabado.horaInicio2 = horaInicio2SabadoCasteada)
            horaFin2SabadoCasteada && (horario.sabado.horaFin2 = horaFin2SabadoCasteada)
            if (horarioRotativo){
                horaInicio3SabadoCasteada && (horario.sabado.horaInicio3 = horaInicio3SabadoCasteada)
                horaFin3SabadoCasteada && (horario.sabado.horaFin3 = horaFin3SabadoCasteada)
            }
        } else {
            horario.sabado = null;
        }
        if (domingo) {
            horaInicio1DomingoCasteada && (horario.domingo.horaInicio1 = horaInicio1DomingoCasteada)
            horaFin1DomingoCasteada && (horario.domingo.horaFin1 = horaFin1DomingoCasteada)
            horaInicio2DomingoCasteada && (horario.domingo.horaInicio2 = horaInicio2DomingoCasteada)
            horaFin2DomingoCasteada && (horario.domingo.horaFin2 = horaFin2DomingoCasteada)
            if (horarioRotativo){
                horaInicio3DomingoCasteada && (horario.domingo.horaInicio3 = horaInicio3DomingoCasteada)
                horaFin3DomingoCasteada && (horario.domingo.horaFin3 = horaFin3DomingoCasteada)
            }
        } else {
            horario.domingo = null;
        }

        const idUsuario = props.addSesion.user.id;

        props.AddHorario({ descripcion, horario, horariosRotativos: horarioRotativo }, idUsuario).then(result => {
            if (!result.error) {
                props.getHorarios();
                handleCloseAgregarEmpleado();
            }
            setMessageSnackbar(result.message);
        });
    }


    const handleCloseModificarHorario = () => {
        setModificarHorario(false);
        setLunesCheckedModificar(false);
        setMartesCheckedModificar(false);
        setMiercolesCheckedModificar(false);
        setJuevesCheckedModificar(false);
        setViernesCheckedModificar(false);
        setSabadoCheckedModificar(false);
        setDomingoCheckedModificar(false);

        setHoraInicio1LunesModificar('');
        setHoraFin1LunesModificar('');
        setHoraInicio2LunesModificar('');
        setHoraFin2LunesModificar('');
        setHoraInicio3LunesModificar('');
        setHoraFin3LunesModificar('');

        setHoraInicio1MartesModificar('');
        setHoraFin1MartesModificar('');
        setHoraInicio2MartesModificar('');
        setHoraFin2MartesModificar('');
        setHoraInicio3MartesModificar('');
        setHoraFin3MartesModificar('');

        setHoraInicio1MiercolesModificar('');
        setHoraFin1MiercolesModificar('');
        setHoraInicio2MiercolesModificar('');
        setHoraFin2MiercolesModificar('');
        setHoraInicio3MiercolesModificar('');
        setHoraFin3MiercolesModificar('');

        setHoraInicio1JuevesModificar('');
        setHoraFin1JuevesModificar('');
        setHoraInicio2JuevesModificar('');
        setHoraFin2JuevesModificar('');
        setHoraInicio3JuevesModificar('');
        setHoraFin3JuevesModificar('');

        setHoraInicio1ViernesModificar('');
        setHoraFin1ViernesModificar('');
        setHoraInicio2ViernesModificar('');
        setHoraFin2ViernesModificar('');
        setHoraInicio3ViernesModificar('');
        setHoraFin3ViernesModificar('');

        setHoraInicio1SabadoModificar('');
        setHoraFin1SabadoModificar('');
        setHoraInicio2SabadoModificar('');
        setHoraFin2SabadoModificar('');
        setHoraInicio3SabadoModificar('');
        setHoraFin3SabadoModificar('');

        setHoraInicio1DomingoModificar('');
        setHoraFin1DomingoModificar('');
        setHoraInicio2DomingoModificar('');
        setHoraFin2DomingoModificar('');
        setHoraInicio3DomingoModificar('');
        setHoraFin3DomingoModificar('');
    }

    const onChangeDescripcionModificar = (e) => {
        setDescripcionModificar(e.target.value);
    }

    const openModificarSet = async variable => {
        setModificarHorario(variable);
      };

    const rowAModificarInfoSet = async info => {

        if (info.descripcion) {
            setDescripcionModificar(info.descripcion);
        }

        if (info.id) {
            setIDModificar(info.id);
        }

        if (info.horario.lunes) {
            setLunesCheckedModificar(true);
            if (info.horario.lunes.horaInicio1){
                let fecha = new Date();
                let var1=info.horario.lunes.horaInicio1.split(':')[0];
                let var2=info.horario.lunes.horaInicio1.split(':')[1];
                fecha.setHours(var1);
                fecha.setMinutes(var2);
                setHoraInicio1LunesModificar(fecha);
            }
            if (info.horario.lunes.horaFin1){
                let fecha = new Date();
                let var1=info.horario.lunes.horaFin1.split(':')[0];
                let var2=info.horario.lunes.horaFin1.split(':')[1];
                fecha.setHours(var1);
                fecha.setMinutes(var2);
                setHoraFin1LunesModificar(fecha);
            }
            if (info.horario.lunes.horaInicio2){
                let fecha = new Date();
                let var1=info.horario.lunes.horaInicio2.split(':')[0];
                let var2=info.horario.lunes.horaInicio2.split(':')[1];
                fecha.setHours(var1);
                fecha.setMinutes(var2);
                setHoraInicio2LunesModificar(fecha);
            }
            if (info.horario.lunes.horaFin2){
                let fecha = new Date();
                let var1=info.horario.lunes.horaFin2.split(':')[0];
                let var2=info.horario.lunes.horaFin2.split(':')[1];
                fecha.setHours(var1);
                fecha.setMinutes(var2);
                setHoraFin2LunesModificar(fecha);
            }
        }
        if (info.horario.martes) {
            setMartesCheckedModificar(true);
            if (info.horario.martes.horaInicio1){
                let fecha = new Date();
                let var1=info.horario.martes.horaInicio1.split(':')[0];
                let var2=info.horario.martes.horaInicio1.split(':')[1];
                fecha.setHours(var1);
                fecha.setMinutes(var2);
                setHoraInicio1MartesModificar(fecha);
            }
            if (info.horario.martes.horaFin1){
                let fecha = new Date();
                let var1=info.horario.martes.horaFin1.split(':')[0];
                let var2=info.horario.martes.horaFin1.split(':')[1];
                fecha.setHours(var1);
                fecha.setMinutes(var2);
                setHoraFin1MartesModificar(fecha);
            }
            if (info.horario.martes.horaInicio2){
                let fecha = new Date();
                let var1=info.horario.martes.horaInicio2.split(':')[0];
                let var2=info.horario.martes.horaInicio2.split(':')[1];
                fecha.setHours(var1);
                fecha.setMinutes(var2);
                setHoraInicio2MartesModificar(fecha);
            }
            if (info.horario.martes.horaFin2){
                let fecha = new Date();
                let var1=info.horario.martes.horaFin2.split(':')[0];
                let var2=info.horario.martes.horaFin2.split(':')[1];
                fecha.setHours(var1);
                fecha.setMinutes(var2);
                setHoraFin2MartesModificar(fecha);
            }
        }

        if (info.horario.miercoles) {
            setMiercolesCheckedModificar(true);
            if (info.horario.miercoles.horaInicio1){
                let fecha = new Date();
                let var1=info.horario.miercoles.horaInicio1.split(':')[0];
                let var2=info.horario.miercoles.horaInicio1.split(':')[1];
                fecha.setHours(var1);
                fecha.setMinutes(var2);
                setHoraInicio1MiercolesModificar(fecha);
            }
            if (info.horario.miercoles.horaFin1){
                let fecha = new Date();
                let var1=info.horario.miercoles.horaFin1.split(':')[0];
                let var2=info.horario.miercoles.horaFin1.split(':')[1];
                fecha.setHours(var1);
                fecha.setMinutes(var2);
                setHoraFin1MiercolesModificar(fecha);
            }
            if (info.horario.miercoles.horaInicio2){
                let fecha = new Date();
                let var1=info.horario.miercoles.horaInicio2.split(':')[0];
                let var2=info.horario.miercoles.horaInicio2.split(':')[1];
                fecha.setHours(var1);
                fecha.setMinutes(var2);
                setHoraInicio2MiercolesModificar(fecha);
            }
            if (info.horario.miercoles.horaFin2){
                let fecha = new Date();
                let var1=info.horario.miercoles.horaFin2.split(':')[0];
                let var2=info.horario.miercoles.horaFin2.split(':')[1];
                fecha.setHours(var1);
                fecha.setMinutes(var2);
                setHoraFin2MiercolesModificar(fecha);
            }
        }

        if (info.horario.jueves) {
            setJuevesCheckedModificar(true);
            if (info.horario.jueves.horaInicio1){
                let fecha = new Date();
                let var1=info.horario.jueves.horaInicio1.split(':')[0];
                let var2=info.horario.jueves.horaInicio1.split(':')[1];
                fecha.setHours(var1);
                fecha.setMinutes(var2);
                setHoraInicio1JuevesModificar(fecha);
            }
            if (info.horario.jueves.horaFin1){
                let fecha = new Date();
                let var1=info.horario.jueves.horaFin1.split(':')[0];
                let var2=info.horario.jueves.horaFin1.split(':')[1];
                fecha.setHours(var1);
                fecha.setMinutes(var2);
                setHoraFin1JuevesModificar(fecha);
            }
            if (info.horario.jueves.horaInicio2){
                let fecha = new Date();
                let var1=info.horario.jueves.horaInicio2.split(':')[0];
                let var2=info.horario.jueves.horaInicio2.split(':')[1];
                fecha.setHours(var1);
                fecha.setMinutes(var2);
                setHoraInicio2JuevesModificar(fecha);
            }
            if (info.horario.jueves.horaFin2){
                let fecha = new Date();
                let var1=info.horario.jueves.horaFin2.split(':')[0];
                let var2=info.horario.jueves.horaFin2.split(':')[1];
                fecha.setHours(var1);
                fecha.setMinutes(var2);
                setHoraFin2JuevesModificar(fecha);
            }
        }

        if (info.horario.viernes) {
            setViernesCheckedModificar(true);
            if (info.horario.viernes.horaInicio1){
                let fecha = new Date();
                let var1=info.horario.viernes.horaInicio1.split(':')[0];
                let var2=info.horario.viernes.horaInicio1.split(':')[1];
                fecha.setHours(var1);
                fecha.setMinutes(var2);
                setHoraInicio1ViernesModificar(fecha);
            }
            if (info.horario.viernes.horaFin1){
                let fecha = new Date();
                let var1=info.horario.viernes.horaFin1.split(':')[0];
                let var2=info.horario.viernes.horaFin1.split(':')[1];
                fecha.setHours(var1);
                fecha.setMinutes(var2);
                setHoraFin1ViernesModificar(fecha);
            }
            if (info.horario.viernes.horaInicio2){
                let fecha = new Date();
                let var1=info.horario.viernes.horaInicio2.split(':')[0];
                let var2=info.horario.viernes.horaInicio2.split(':')[1];
                fecha.setHours(var1);
                fecha.setMinutes(var2);
                setHoraInicio2ViernesModificar(fecha);
            }
            if (info.horario.viernes.horaFin2){
                let fecha = new Date();
                let var1=info.horario.viernes.horaFin2.split(':')[0];
                let var2=info.horario.viernes.horaFin2.split(':')[1];
                fecha.setHours(var1);
                fecha.setMinutes(var2);
                setHoraFin2ViernesModificar(fecha);
            }
        }

        if (info.horario.sabado) {
            setSabadoCheckedModificar(true);
            if (info.horario.sabado.horaInicio1){
                let fecha = new Date();
                let var1=info.horario.sabado.horaInicio1.split(':')[0];
                let var2=info.horario.sabado.horaInicio1.split(':')[1];
                fecha.setHours(var1);
                fecha.setMinutes(var2);
                setHoraInicio1SabadoModificar(fecha);
            }
            if (info.horario.sabado.horaFin1){
                let fecha = new Date();
                let var1=info.horario.sabado.horaFin1.split(':')[0];
                let var2=info.horario.sabado.horaFin1.split(':')[1];
                fecha.setHours(var1);
                fecha.setMinutes(var2);
                setHoraFin1SabadoModificar(fecha);
            }
            if (info.horario.sabado.horaInicio2){
                let fecha = new Date();
                let var1=info.horario.sabado.horaInicio2.split(':')[0];
                let var2=info.horario.sabado.horaInicio2.split(':')[1];
                fecha.setHours(var1);
                fecha.setMinutes(var2);
                setHoraInicio2SabadoModificar(fecha);
            }
            if (info.horario.sabado.horaFin2){
                let fecha = new Date();
                let var1=info.horario.sabado.horaFin2.split(':')[0];
                let var2=info.horario.sabado.horaFin2.split(':')[1];
                fecha.setHours(var1);
                fecha.setMinutes(var2);
                setHoraFin2SabadoModificar(fecha);
            }
        }

        if (info.horario.domingo) {
            setDomingoCheckedModificar(true);
            if (info.horario.domingo.horaInicio1){
                let fecha = new Date();
                let var1=info.horario.domingo.horaInicio1.split(':')[0];
                let var2=info.horario.domingo.horaInicio1.split(':')[1];
                fecha.setHours(var1);
                fecha.setMinutes(var2);
                setHoraInicio1DomingoModificar(fecha);
            }
            if (info.horario.domingo.horaFin1){
                let fecha = new Date();
                let var1=info.horario.domingo.horaFin1.split(':')[0];
                let var2=info.horario.domingo.horaFin1.split(':')[1];
                fecha.setHours(var1);
                fecha.setMinutes(var2);
                setHoraFin1DomingoModificar(fecha);
            }
            if (info.horario.domingo.horaInicio2){
                let fecha = new Date();
                let var1=info.horario.domingo.horaInicio2.split(':')[0];
                let var2=info.horario.domingo.horaInicio2.split(':')[1];
                fecha.setHours(var1);
                fecha.setMinutes(var2);
                setHoraInicio2DomingoModificar(fecha);
            }
            if (info.horario.domingo.horaFin2){
                let fecha = new Date();
                let var1=info.horario.domingo.horaFin2.split(':')[0];
                let var2=info.horario.domingo.horaFin2.split(':')[1];
                fecha.setHours(var1);
                fecha.setMinutes(var2);
                setHoraFin2DomingoModificar(fecha);
            }
        }
        
        if(info.horariosRotativos) {
            setHorarioRotativoModificar(true);
            if (info.horario.lunes) {
                if (info.horario.lunes.horaInicio3){
                    let fecha = new Date();
                    let var1=info.horario.lunes.horaInicio3.split(':')[0];
                    let var2=info.horario.lunes.horaInicio3.split(':')[1];
                    fecha.setHours(var1);
                    fecha.setMinutes(var2);
                    setHoraInicio3LunesModificar(fecha);
                }
                if (info.horario.lunes.horaFin3){
                    let fecha = new Date();
                    let var1=info.horario.lunes.horaFin3.split(':')[0];
                    let var2=info.horario.lunes.horaFin3.split(':')[1];
                    fecha.setHours(var1);
                    fecha.setMinutes(var2);
                    setHoraFin3LunesModificar(fecha);
                }
        }
        if (info.horario.martes) {
            if (info.horario.martes.horaInicio3){
                let fecha = new Date();
                let var1=info.horario.martes.horaInicio3.split(':')[0];
                let var2=info.horario.martes.horaInicio3.split(':')[1];
                fecha.setHours(var1);
                fecha.setMinutes(var2);
                setHoraInicio3MartesModificar(fecha);
            }
            if (info.horario.martes.horaFin3){
                let fecha = new Date();
                let var1=info.horario.martes.horaFin3.split(':')[0];
                let var2=info.horario.martes.horaFin3.split(':')[1];
                fecha.setHours(var1);
                fecha.setMinutes(var2);
                setHoraFin3MartesModificar(fecha);
            }
    }
    if (info.horario.miercoles) {
        if (info.horario.miercoles.horaInicio3){
            let fecha = new Date();
            let var1=info.horario.miercoles.horaInicio3.split(':')[0];
            let var2=info.horario.miercoles.horaInicio3.split(':')[1];
            fecha.setHours(var1);
            fecha.setMinutes(var2);
            setHoraInicio3MiercolesModificar(fecha);
        }
        if (info.horario.miercoles.horaFin3){
            let fecha = new Date();
            let var1=info.horario.miercoles.horaFin3.split(':')[0];
            let var2=info.horario.miercoles.horaFin3.split(':')[1];
            fecha.setHours(var1);
            fecha.setMinutes(var2);
            setHoraFin3MiercolesModificar(fecha);
        }
}
if (info.horario.jueves) {
    if (info.horario.jueves.horaInicio3){
        let fecha = new Date();
        let var1=info.horario.jueves.horaInicio3.split(':')[0];
        let var2=info.horario.jueves.horaInicio3.split(':')[1];
        fecha.setHours(var1);
        fecha.setMinutes(var2);
        setHoraInicio3JuevesModificar(fecha);
    }
    if (info.horario.jueves.horaFin3){
        let fecha = new Date();
        let var1=info.horario.jueves.horaFin3.split(':')[0];
        let var2=info.horario.jueves.horaFin3.split(':')[1];
        fecha.setHours(var1);
        fecha.setMinutes(var2);
        setHoraFin3JuevesModificar(fecha);
    }
}
if (info.horario.viernes) {
    if (info.horario.viernes.horaInicio3){
        let fecha = new Date();
        let var1=info.horario.viernes.horaInicio3.split(':')[0];
        let var2=info.horario.viernes.horaInicio3.split(':')[1];
        fecha.setHours(var1);
        fecha.setMinutes(var2);
        setHoraInicio3ViernesModificar(fecha);
    }
    if (info.horario.viernes.horaFin3){
        let fecha = new Date();
        let var1=info.horario.viernes.horaFin3.split(':')[0];
        let var2=info.horario.viernes.horaFin3.split(':')[1];
        fecha.setHours(var1);
        fecha.setMinutes(var2);
        setHoraFin3ViernesModificar(fecha);
    }
}
if (info.horario.sabado) {
    if (info.horario.sabado.horaInicio3){
        let fecha = new Date();
        let var1=info.horario.sabado.horaInicio3.split(':')[0];
        let var2=info.horario.sabado.horaInicio3.split(':')[1];
        fecha.setHours(var1);
        fecha.setMinutes(var2);
        setHoraInicio3SabadoModificar(fecha);
    }
    if (info.horario.sabado.horaFin3){
        let fecha = new Date();
        let var1=info.horario.sabado.horaFin3.split(':')[0];
        let var2=info.horario.sabado.horaFin3.split(':')[1];
        fecha.setHours(var1);
        fecha.setMinutes(var2);
        setHoraFin3SabadoModificar(fecha);
    }
}
if (info.horario.domingo) {
    if (info.horario.domingo.horaInicio3){
        let fecha = new Date();
        let var1=info.horario.domingo.horaInicio3.split(':')[0];
        let var2=info.horario.domingo.horaInicio3.split(':')[1];
        fecha.setHours(var1);
        fecha.setMinutes(var2);
        setHoraInicio3DomingoModificar(fecha);
    }
    if (info.horario.domingo.horaFin3){
        let fecha = new Date();
        let var1=info.horario.domingo.horaFin3.split(':')[0];
        let var2=info.horario.domingo.horaFin3.split(':')[1];
        fecha.setHours(var1);
        fecha.setMinutes(var2);
        setHoraFin3DomingoModificar(fecha);
    }
}

    }
        console.log(info);
    }

    const rowAEliminar = async row  => {
        setHorarioEliminar(row);
    }

    const openEliminarModal = async variable => {
        setOpenEliminar(variable);
    }

    const handleCloseEliminarHorario = () => {
        setOpenEliminar(false);
    }

    const handleEliminarHorario = () => {
        const idUsuario = props.addSesion.user.id;
        props.DeleteHorario(eliminarHorario.id, idUsuario).then(result => {
            setMessageSnackbar(result.message);
            setHorarioEliminar('');
            setOpenEliminar(false);
            props.getHorarios().then(result => {
                if (result && Array.isArray(result)) {
                    setHorarios(result);
                    setPermit(false);
                }
            });
        })
    }

    const handleModificarHorario = () => {

        const horaInicio1LunesCasteada = HoraInicio1LunesModificar && `${('0' + HoraInicio1LunesModificar.getHours()).slice(-2)}:${('0' + HoraInicio1LunesModificar.getMinutes()).slice(-2)}`;
        const horaFin1LunesCasteada = HoraFin1LunesModificar && `${('0' + HoraFin1LunesModificar.getHours()).slice(-2)}:${('0' + HoraFin1LunesModificar.getMinutes()).slice(-2)}`;
        const horaInicio2LunesCasteada = HoraInicio2LunesModificar && `${('0' + HoraInicio2LunesModificar.getHours()).slice(-2)}:${('0' + HoraInicio2LunesModificar.getMinutes()).slice(-2)}`;
        const horaFin2LunesCasteada = HoraFin2LunesModificar && `${('0' + HoraFin2LunesModificar.getHours()).slice(-2)}:${('0' + HoraFin2LunesModificar.getMinutes()).slice(-2)}`;

        const horaInicio1MartesCasteada = HoraInicio1MartesModificar && `${('0' + HoraInicio1MartesModificar.getHours()).slice(-2)}:${('0' + HoraInicio1MartesModificar.getMinutes()).slice(-2)}`;
        const horaFin1MartesCasteada = HoraFin1MartesModificar && `${('0' + HoraFin1MartesModificar.getHours()).slice(-2)}:${('0' + HoraFin1MartesModificar.getMinutes()).slice(-2)}`;
        const horaInicio2MartesCasteada = HoraInicio2MartesModificar && `${('0' + HoraInicio2MartesModificar.getHours()).slice(-2)}:${('0' + HoraInicio2MartesModificar.getMinutes()).slice(-2)}`;
        const horaFin2MartesCasteada = HoraFin2MartesModificar && `${('0' + HoraFin2MartesModificar.getHours()).slice(-2)}:${('0' + HoraFin2MartesModificar.getMinutes()).slice(-2)}`;

        const horaInicio1MiercolesCasteada = HoraInicio1MiercolesModificar && `${('0' + HoraInicio1MiercolesModificar.getHours()).slice(-2)}:${('0' + HoraInicio1MiercolesModificar.getMinutes()).slice(-2)}`;
        const horaFin1MiercolesCasteada = HoraFin1MiercolesModificar && `${('0' + HoraFin1MiercolesModificar.getHours()).slice(-2)}:${('0' + HoraFin1MiercolesModificar.getMinutes()).slice(-2)}`;
        const horaInicio2MiercolesCasteada = HoraInicio2MiercolesModificar && `${('0' + HoraInicio2MiercolesModificar.getHours()).slice(-2)}:${('0' + HoraInicio2MiercolesModificar.getMinutes()).slice(-2)}`;
        const horaFin2MiercolesCasteada = HoraFin2MiercolesModificar && `${('0' + HoraFin2MiercolesModificar.getHours()).slice(-2)}:${('0' + HoraFin2MiercolesModificar.getMinutes()).slice(-2)}`;

        const horaInicio1JuevesCasteada = HoraInicio1JuevesModificar && `${('0' + HoraInicio1JuevesModificar.getHours()).slice(-2)}:${('0' + HoraInicio1JuevesModificar.getMinutes()).slice(-2)}`;
        const horaFin1JuevesCasteada = HoraFin1JuevesModificar && `${('0' + HoraFin1JuevesModificar.getHours()).slice(-2)}:${('0' + HoraFin1JuevesModificar.getMinutes()).slice(-2)}`;
        const horaInicio2JuevesCasteada = HoraInicio2JuevesModificar && `${('0' + HoraInicio2JuevesModificar.getHours()).slice(-2)}:${('0' + HoraInicio2JuevesModificar.getMinutes()).slice(-2)}`;
        const horaFin2JuevesCasteada = HoraFin2JuevesModificar && `${('0' + HoraFin2JuevesModificar.getHours()).slice(-2)}:${('0' + HoraFin2JuevesModificar.getMinutes()).slice(-2)}`;

        const horaInicio1ViernesCasteada = HoraInicio1ViernesModificar && `${('0' + HoraInicio1ViernesModificar.getHours()).slice(-2)}:${('0' + HoraInicio1ViernesModificar.getMinutes()).slice(-2)}`;
        const horaFin1ViernesCasteada = HoraFin1ViernesModificar && `${('0' + HoraFin1ViernesModificar.getHours()).slice(-2)}:${('0' + HoraFin1ViernesModificar.getMinutes()).slice(-2)}`;
        const horaInicio2ViernesCasteada = HoraInicio2ViernesModificar && `${('0' + HoraInicio2ViernesModificar.getHours()).slice(-2)}:${('0' + HoraInicio2ViernesModificar.getMinutes()).slice(-2)}`;
        const horaFin2ViernesCasteada = HoraFin2ViernesModificar && `${('0' + HoraFin2ViernesModificar.getHours()).slice(-2)}:${('0' + HoraFin2ViernesModificar.getMinutes()).slice(-2)}`;

        const horaInicio1SabadoCasteada = HoraInicio1SabadoModificar && `${('0' + HoraInicio1SabadoModificar.getHours()).slice(-2)}:${('0' + HoraInicio1SabadoModificar.getMinutes()).slice(-2)}`;
        const horaFin1SabadoCasteada = HoraFin1SabadoModificar && `${('0' + HoraFin1SabadoModificar.getHours()).slice(-2)}:${('0' + HoraFin1SabadoModificar.getMinutes()).slice(-2)}`;
        const horaInicio2SabadoCasteada = HoraInicio2SabadoModificar && `${('0' + HoraInicio2SabadoModificar.getHours()).slice(-2)}:${('0' + HoraInicio2SabadoModificar.getMinutes()).slice(-2)}`;
        const horaFin2SabadoCasteada = HoraFin2SabadoModificar && `${('0' + HoraFin2SabadoModificar.getHours()).slice(-2)}:${('0' + HoraFin2SabadoModificar.getMinutes()).slice(-2)}`;

        const horaInicio1DomingoCasteada = HoraInicio1DomingoModificar && `${('0' + HoraInicio1DomingoModificar.getHours()).slice(-2)}:${('0' + HoraInicio1DomingoModificar.getMinutes()).slice(-2)}`;
        const horaFin1DomingoCasteada = HoraFin1DomingoModificar && `${('0' + HoraFin1DomingoModificar.getHours()).slice(-2)}:${('0' + HoraFin1DomingoModificar.getMinutes()).slice(-2)}`;
        const horaInicio2DomingoCasteada = HoraInicio2DomingoModificar && `${('0' + HoraInicio2DomingoModificar.getHours()).slice(-2)}:${('0' + HoraInicio2DomingoModificar.getMinutes()).slice(-2)}`;
        const horaFin2DomingoCasteada = HoraFin2DomingoModificar && `${('0' + HoraFin2DomingoModificar.getHours()).slice(-2)}:${('0' + HoraFin2DomingoModificar.getMinutes()).slice(-2)}`;

        let horaInicio3LunesCasteada,horaFin3LunesCasteada,horaInicio3MartesCasteada,horaFin3MartesCasteada,
        horaInicio3MiercolesCasteada,horaFin3MiercolesCasteada,horaInicio3JuevesCasteada,horaFin3JuevesCasteada,
        horaInicio3ViernesCasteada,horaFin3ViernesCasteada,horaInicio3SabadoCasteada,horaFin3SabadoCasteada,
        horaInicio3DomingoCasteada,horaFin3DomingoCasteada;

        if (horarioRotativoModificar) {
            horaInicio3LunesCasteada = HoraInicio3LunesModificar && `${('0' + HoraInicio3LunesModificar.getHours()).slice(-2)}:${('0' + HoraInicio3LunesModificar.getMinutes()).slice(-2)}`;
            horaFin3LunesCasteada = HoraFin3LunesModificar && `${('0' + HoraFin3LunesModificar.getHours()).slice(-2)}:${('0' + HoraFin3LunesModificar.getMinutes()).slice(-2)}`;
    
            horaInicio3MartesCasteada = HoraInicio3MartesModificar && `${('0' + HoraInicio3MartesModificar.getHours()).slice(-2)}:${('0' + HoraInicio3MartesModificar.getMinutes()).slice(-2)}`;
            horaFin3MartesCasteada = HoraFin3MartesModificar && `${('0' + HoraFin3MartesModificar.getHours()).slice(-2)}:${('0' + HoraFin3MartesModificar.getMinutes()).slice(-2)}`;
    
            horaInicio3MiercolesCasteada = HoraInicio3MiercolesModificar && `${('0' + HoraInicio3MiercolesModificar.getHours()).slice(-2)}:${('0' + HoraInicio3MiercolesModificar.getMinutes()).slice(-2)}`;
            horaFin3MiercolesCasteada = HoraFin3MiercolesModificar && `${('0' + HoraFin3MiercolesModificar.getHours()).slice(-2)}:${('0' + HoraFin3MiercolesModificar.getMinutes()).slice(-2)}`;
    
            horaInicio3JuevesCasteada = HoraInicio3JuevesModificar && `${('0' + HoraInicio3JuevesModificar.getHours()).slice(-2)}:${('0' + HoraInicio3JuevesModificar.getMinutes()).slice(-2)}`;
            horaFin3JuevesCasteada = HoraFin3JuevesModificar && `${('0' + HoraFin3JuevesModificar.getHours()).slice(-2)}:${('0' + HoraFin3JuevesModificar.getMinutes()).slice(-2)}`;
    
            horaInicio3ViernesCasteada = HoraInicio3ViernesModificar && `${('0' + HoraInicio3ViernesModificar.getHours()).slice(-2)}:${('0' + HoraInicio3ViernesModificar.getMinutes()).slice(-2)}`;
            horaFin3ViernesCasteada = HoraFin3ViernesModificar && `${('0' + HoraFin3ViernesModificar.getHours()).slice(-2)}:${('0' + HoraFin3ViernesModificar.getMinutes()).slice(-2)}`;
    
            horaInicio3SabadoCasteada = HoraInicio3SabadoModificar && `${('0' + HoraInicio3SabadoModificar.getHours()).slice(-2)}:${('0' + HoraInicio3SabadoModificar.getMinutes()).slice(-2)}`;
            horaFin3SabadoCasteada = HoraFin3SabadoModificar && `${('0' + HoraFin3SabadoModificar.getHours()).slice(-2)}:${('0' + HoraFin3SabadoModificar.getMinutes()).slice(-2)}`;
    
            horaInicio3DomingoCasteada = HoraInicio3DomingoModificar && `${('0' + HoraInicio3DomingoModificar.getHours()).slice(-2)}:${('0' + HoraInicio3DomingoModificar.getMinutes()).slice(-2)}`;
            horaFin3DomingoCasteada = HoraFin3DomingoModificar && `${('0' + HoraFin3DomingoModificar.getHours()).slice(-2)}:${('0' + HoraFin3DomingoModificar.getMinutes()).slice(-2)}`;
        }

        let horario = {
            lunes: {},
            martes: {},
            miercoles: {},
            jueves: {},
            viernes: {},
            sabado: {},
            domingo: {}
        };
        if (lunesModificar) {
            horaInicio1LunesCasteada && (horario.lunes.horaInicio1 = horaInicio1LunesCasteada);
            horaFin1LunesCasteada && (horario.lunes.horaFin1 = horaFin1LunesCasteada);
            horaInicio2LunesCasteada && (horario.lunes.horaInicio2 = horaInicio2LunesCasteada);
            horaFin2LunesCasteada && (horario.lunes.horaFin2 = horaFin2LunesCasteada);
            if (horarioRotativoModificar){
                horaInicio3LunesCasteada && (horario.lunes.horaInicio3 = horaInicio3LunesCasteada);
                horaFin3LunesCasteada && (horario.lunes.horaFin3 = horaFin3LunesCasteada);
            }
        } else {
            horario.lunes = null;
        }
        if (martesModificar) {
            horaInicio1MartesCasteada && (horario.martes.horaInicio1 = horaInicio1MartesCasteada)
            horaFin1MartesCasteada && (horario.martes.horaFin1 = horaFin1MartesCasteada)
            horaInicio2MartesCasteada && (horario.martes.horaInicio2 = horaInicio2MartesCasteada)
            horaFin2MartesCasteada && (horario.martes.horaFin2 = horaFin2MartesCasteada)
            if (horarioRotativoModificar){
                horaInicio3MartesCasteada && (horario.martes.horaInicio3 = horaInicio3MartesCasteada)
                horaFin3MartesCasteada && (horario.martes.horaFin3 = horaFin3MartesCasteada)
            }
        } else {
            horario.martes = null;
        }
        if (miercolesModificar) {
            horaInicio1MiercolesCasteada && (horario.miercoles.horaInicio1 = horaInicio1MiercolesCasteada)
            horaFin1MiercolesCasteada && (horario.miercoles.horaFin1 = horaFin1MiercolesCasteada)
            horaInicio2MiercolesCasteada && (horario.miercoles.horaInicio2 = horaInicio2MiercolesCasteada)
            horaFin2MiercolesCasteada && (horario.miercoles.horaFin2 = horaFin2MiercolesCasteada)
            if (horarioRotativoModificar){
                horaInicio3MiercolesCasteada && (horario.miercoles.horaInicio3 = horaInicio3MiercolesCasteada)
                horaFin3MiercolesCasteada && (horario.miercoles.horaFin3 = horaFin3MiercolesCasteada)
            }
        } else {
            horario.miercoles = null;
        }
        if (juevesModificar) {
            horaInicio1JuevesCasteada && (horario.jueves.horaInicio1 = horaInicio1JuevesCasteada)
            horaFin1JuevesCasteada && (horario.jueves.horaFin1 = horaFin1JuevesCasteada)
            horaInicio2JuevesCasteada && (horario.jueves.horaInicio2 = horaInicio2JuevesCasteada)
            horaFin2JuevesCasteada && (horario.jueves.horaFin2 = horaFin2JuevesCasteada)
            if (horarioRotativoModificar){
                horaInicio3JuevesCasteada && (horario.jueves.horaInicio3 = horaInicio3JuevesCasteada)
                horaFin3JuevesCasteada && (horario.jueves.horaFin3 = horaFin3JuevesCasteada)
            }
        } else {
            horario.jueves = null;
        }
        if (viernesModificar) {
            horaInicio1ViernesCasteada && (horario.viernes.horaInicio1 = horaInicio1ViernesCasteada)
            horaFin1ViernesCasteada && (horario.viernes.horaFin1 = horaFin1ViernesCasteada)
            horaInicio2ViernesCasteada && (horario.viernes.horaInicio2 = horaInicio2ViernesCasteada)
            horaFin2ViernesCasteada && (horario.viernes.horaFin2 = horaFin2ViernesCasteada)
            if (horarioRotativoModificar){
                horaInicio3ViernesCasteada && (horario.viernes.horaInicio3 = horaInicio3ViernesCasteada)
                horaFin3ViernesCasteada && (horario.viernes.horaFin3 = horaFin3ViernesCasteada)
            }
        } else {
            horario.viernes = null;
        }
        if (sabadoModificar) {
            horaInicio1SabadoCasteada && (horario.sabado.horaInicio1 = horaInicio1SabadoCasteada)
            horaFin1SabadoCasteada && (horario.sabado.horaFin1 = horaFin1SabadoCasteada)
            horaInicio2SabadoCasteada && (horario.sabado.horaInicio2 = horaInicio2SabadoCasteada)
            horaFin2SabadoCasteada && (horario.sabado.horaFin2 = horaFin2SabadoCasteada)
            if (horarioRotativoModificar){
                horaInicio3SabadoCasteada && (horario.sabado.horaInicio3 = horaInicio3SabadoCasteada)
                horaFin3SabadoCasteada && (horario.sabado.horaFin3 = horaFin3SabadoCasteada)
            }
        } else {
            horario.sabado = null;
        }
        if (domingoModificar) {
            horaInicio1DomingoCasteada && (horario.domingo.horaInicio1 = horaInicio1DomingoCasteada)
            horaFin1DomingoCasteada && (horario.domingo.horaFin1 = horaFin1DomingoCasteada)
            horaInicio2DomingoCasteada && (horario.domingo.horaInicio2 = horaInicio2DomingoCasteada)
            horaFin2DomingoCasteada && (horario.domingo.horaFin2 = horaFin2DomingoCasteada)
            if (horarioRotativoModificar){
                horaInicio3DomingoCasteada && (horario.domingo.horaInicio3 = horaInicio3DomingoCasteada)
                horaFin3DomingoCasteada && (horario.domingo.horaFin3 = horaFin3DomingoCasteada)
            }
        } else {
            horario.domingo = null;
        }

        const idUsuario = props.addSesion.user.id;

        props.AlterHorario({ id: IDModificar, descripcion: descripcionModificar, horario, horariosRotativos: horarioRotativoModificar }, idUsuario).then(result => {
            if (!result.error) {
                props.getHorarios();
                handleCloseModificarHorario();
            }
            setMessageSnackbar(result.message);
        });

    }

    return (
        <div className={classes.root}>
            <Menu title="Administración de horarios" />
            <main className={classes.content}>
                <div className={classes.toolbar} />
                <Container component="main" maxWidth="lg">
                    <div className={classes.paper}>
                        <Typography variant="h4">
                            Horarios
                        </Typography>
                        <form className={classes.form}>
                            <Grid container spacing={3}>

                                <Grid item xs={12} sm={4}>
                                    <Button
                                        color="primary"
                                        variant="contained"
                                        onClick={handleOpenAgregarHorario}
                                    >
                                        Agregar Horario
                                    </Button>
                                </Grid>
                                <Grid item xs={12} sm={4}>
                                </Grid>
                                <Grid item xs={12} sm={4} >
                                </Grid>

                            </Grid>
                        </form>

                        <TableContainer component={Paper}>
                            <Table aria-label="collapsible table">
                                <TableHead>
                                    <TableRow>
                                        <TableCell />
                                        <TableCell>Id Horario</TableCell>
                                        <TableCell align="right">Descripción</TableCell>
                                        <TableCell align="right">Estatus</TableCell>
                                        <TableCell align="right">Fecha Creación</TableCell>
                                        <TableCell align="right">Última modificación</TableCell>
                                        <TableCell align="right"></TableCell>
                                        <TableCell align="right"></TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {props.horarios && props.horarios.horarios ? props.horarios.horarios.map((row) => (
                                        <Row 
                                        key={row.id} 
                                        row={row} 
                                        openModificar={openModificarSet} 
                                        rowAModificar={rowAModificarInfoSet} 
                                        rowAEliminar={rowAEliminar}
                                        openEliminar={openEliminarModal}
                                        />
                                  )): 'Cargando... '}
                                </TableBody>
                            </Table>
                        </TableContainer>

                        <Dialog
                            open={agregarEmpleado}
                            onClose={handleCloseAgregarEmpleado}
                            aria-labelledby="alert-dialog-title"
                            aria-describedby="alert-dialog-description"
                        >
                            <DialogTitle id="alert-dialog-title">{"Agregar Horario"}</DialogTitle>
                            <DialogContent>
                                <DialogContentText id="alert-dialog-description">
                                    Favor de seleccionar las opciones para agregar el horarario
                                </DialogContentText>
                                <TextField
                                    name="descripcionHorario"
                                    variant="outlined"
                                    fullWidth
                                    id="txtDescripcionHorario"
                                    label="Descipción Horario"
                                    value={descripcion}
                                    onChange={onChangeDescripcion}
                                />
                                <div>
                                <Checkbox
                                        checked={horarioRotativo}
                                        onClick={handleChangeHorarioRotativo}
                                    />Horarios Rotativos
                                </div>
                                <div>
                                    <Checkbox
                                        checked={lunes}
                                        onClick={handleChangeLunes}
                                    />Lunes
                                </div>
                                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                    <TimePicker
                                        inputVariant="outlined"
                                        clearable
                                        ampm={false}
                                        label="Hora Inicio 1"
                                        value={HoraInicio1Lunes}
                                        minutesStep={30}
                                        onChange={(e) => setHoraInicio1Lunes(e)}
                                    />
                                </MuiPickersUtilsProvider>
                                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                    <TimePicker
                                        inputVariant="outlined"
                                        clearable
                                        ampm={false}
                                        label="Hora Fin 1"
                                        value={HoraFin1Lunes}
                                        minutesStep={30}
                                        onChange={(e) => setHoraFin1Lunes(e)}
                                    />
                                </MuiPickersUtilsProvider>

                                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                    <TimePicker
                                        inputVariant="outlined"
                                        clearable
                                        ampm={false}
                                        label="Hora Inicio 2"
                                        value={HoraInicio2Lunes}
                                        minutesStep={30}
                                        onChange={(e) => {
                                            console.log(e);
                                            setHoraInicio2Lunes(e);
                                        }
                                        }
                                    />
                                </MuiPickersUtilsProvider>
                                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                    <TimePicker
                                        inputVariant="outlined"
                                        clearable
                                        ampm={false}
                                        label="Hora Fin 2"
                                        value={HoraFin2Lunes}
                                        minutesStep={30}
                                        onChange={(e) => setHoraFin2Lunes(e)}
                                    />
                                </MuiPickersUtilsProvider>

{ horarioRotativo ? <div>
                                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                    <TimePicker
                                        inputVariant="outlined"
                                        clearable
                                        ampm={false}
                                        label="Hora Inicio 3"
                                        value={HoraInicio3Lunes}
                                        minutesStep={30}
                                        onChange={(e) => {
                                            console.log(e);
                                            setHoraInicio3Lunes(e);
                                        }
                                        }
                                    />
                                </MuiPickersUtilsProvider>
                                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                    <TimePicker
                                        inputVariant="outlined"
                                        clearable
                                        ampm={false}
                                        label="Hora Fin 3"
                                        value={HoraFin3Lunes}
                                        minutesStep={30}
                                        onChange={(e) => setHoraFin3Lunes(e)}
                                    />
                                </MuiPickersUtilsProvider></div>
: ''}
                                <div>
                                    <Checkbox
                                        checked={martes}
                                        onClick={handleChangeMartes}
                                    />Martes
                                </div>
                                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                    <TimePicker
                                        inputVariant="outlined"
                                        clearable
                                        ampm={false}
                                        label="Hora Inicio 1"
                                        value={HoraInicio1Martes}
                                        minutesStep={30}
                                        onChange={(e) => setHoraInicio1Martes(e)}
                                    />
                                </MuiPickersUtilsProvider>
                                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                    <TimePicker
                                        inputVariant="outlined"
                                        clearable
                                        ampm={false}
                                        label="Hora Fin 1"
                                        value={HoraFin1Martes}
                                        minutesStep={30}
                                        onChange={(e) => setHoraFin1Martes(e)}
                                    />
                                </MuiPickersUtilsProvider>

                                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                    <TimePicker
                                        inputVariant="outlined"
                                        clearable
                                        ampm={false}
                                        label="Hora Inicio 2"
                                        value={HoraInicio2Martes}
                                        minutesStep={30}
                                        onChange={(e) => {
                                            console.log(e);
                                            setHoraInicio2Martes(e);
                                        }
                                        }
                                    />
                                </MuiPickersUtilsProvider>
                                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                    <TimePicker
                                        inputVariant="outlined"
                                        clearable
                                        ampm={false}
                                        label="Hora Fin 2"
                                        value={HoraFin2Martes}
                                        minutesStep={30}
                                        onChange={(e) => setHoraFin2Martes(e)}
                                    />
                                </MuiPickersUtilsProvider>
                                { horarioRotativo ? <div>
                                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                    <TimePicker
                                        inputVariant="outlined"
                                        clearable
                                        ampm={false}
                                        label="Hora Inicio 3"
                                        value={HoraInicio3Martes}
                                        minutesStep={30}
                                        onChange={(e) => {
                                            console.log(e);
                                            setHoraInicio3Martes(e);
                                        }
                                        }
                                    />
                                </MuiPickersUtilsProvider>
                                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                    <TimePicker
                                        inputVariant="outlined"
                                        clearable
                                        ampm={false}
                                        label="Hora Fin 3"
                                        value={HoraFin3Martes}
                                        minutesStep={30}
                                        onChange={(e) => setHoraFin3Martes(e)}
                                    />
                                </MuiPickersUtilsProvider></div>
: ''}
                                <div>
                                    <Checkbox
                                        checked={miercoles}
                                        onClick={handleChangeMiercoles}
                                    />Miercoles
                                </div>
                                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                    <TimePicker
                                        inputVariant="outlined"
                                        clearable
                                        ampm={false}
                                        label="Hora Inicio 1"
                                        value={HoraInicio1Miercoles}
                                        minutesStep={30}
                                        onChange={(e) => setHoraInicio1Miercoles(e)}
                                    />
                                </MuiPickersUtilsProvider>
                                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                    <TimePicker
                                        inputVariant="outlined"
                                        clearable
                                        ampm={false}
                                        label="Hora Fin 1"
                                        value={HoraFin1Miercoles}
                                        minutesStep={30}
                                        onChange={(e) => setHoraFin1Miercoles(e)}
                                    />
                                </MuiPickersUtilsProvider>

                                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                    <TimePicker
                                        inputVariant="outlined"
                                        clearable
                                        ampm={false}
                                        label="Hora Inicio 2"
                                        value={HoraInicio2Miercoles}
                                        minutesStep={30}
                                        onChange={(e) => {
                                            console.log(e);
                                            setHoraInicio2Miercoles(e);
                                        }
                                        }
                                    />
                                </MuiPickersUtilsProvider>
                                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                    <TimePicker
                                        inputVariant="outlined"
                                        clearable
                                        ampm={false}
                                        label="Hora Fin 2"
                                        value={HoraFin2Miercoles}
                                        minutesStep={30}
                                        onChange={(e) => setHoraFin2Miercoles(e)}
                                    />
                                </MuiPickersUtilsProvider>
                                { horarioRotativo ? <div>
                                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                    <TimePicker
                                        inputVariant="outlined"
                                        clearable
                                        ampm={false}
                                        label="Hora Inicio 3"
                                        value={HoraInicio3Miercoles}
                                        minutesStep={30}
                                        onChange={(e) => {
                                            console.log(e);
                                            setHoraInicio3Miercoles(e);
                                        }
                                        }
                                    />
                                </MuiPickersUtilsProvider>
                                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                    <TimePicker
                                        inputVariant="outlined"
                                        clearable
                                        ampm={false}
                                        label="Hora Fin 3"
                                        value={HoraFin3Miercoles}
                                        minutesStep={30}
                                        onChange={(e) => setHoraFin3Miercoles(e)}
                                    />
                                </MuiPickersUtilsProvider></div>
: ''}
                                <div>
                                    <Checkbox
                                        checked={jueves}
                                        onClick={handleChangeJueves}
                                    />Jueves
                                </div>
                                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                    <TimePicker
                                        inputVariant="outlined"
                                        clearable
                                        ampm={false}
                                        label="Hora Inicio 1"
                                        value={HoraInicio1Jueves}
                                        minutesStep={30}
                                        onChange={(e) => setHoraInicio1Jueves(e)}
                                    />
                                </MuiPickersUtilsProvider>
                                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                    <TimePicker
                                        inputVariant="outlined"
                                        clearable
                                        ampm={false}
                                        label="Hora Fin 1"
                                        value={HoraFin1Jueves}
                                        minutesStep={30}
                                        onChange={(e) => setHoraFin1Jueves(e)}
                                    />
                                </MuiPickersUtilsProvider>

                                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                    <TimePicker
                                        inputVariant="outlined"
                                        clearable
                                        ampm={false}
                                        label="Hora Inicio 2"
                                        value={HoraInicio2Jueves}
                                        minutesStep={30}
                                        onChange={(e) => {
                                            console.log(e);
                                            setHoraInicio2Jueves(e);
                                        }
                                        }
                                    />
                                </MuiPickersUtilsProvider>
                                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                    <TimePicker
                                        inputVariant="outlined"
                                        clearable
                                        ampm={false}
                                        label="Hora Fin 2"
                                        value={HoraFin2Jueves}
                                        minutesStep={30}
                                        onChange={(e) => setHoraFin2Jueves(e)}
                                    />
                                </MuiPickersUtilsProvider>
                                { horarioRotativo ? <div>
                                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                    <TimePicker
                                        inputVariant="outlined"
                                        clearable
                                        ampm={false}
                                        label="Hora Inicio 3"
                                        value={HoraInicio3Jueves}
                                        minutesStep={30}
                                        onChange={(e) => {
                                            console.log(e);
                                            setHoraInicio3Jueves(e);
                                        }
                                        }
                                    />
                                </MuiPickersUtilsProvider>
                                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                    <TimePicker
                                        inputVariant="outlined"
                                        clearable
                                        ampm={false}
                                        label="Hora Fin 3"
                                        value={HoraFin3Jueves}
                                        minutesStep={30}
                                        onChange={(e) => setHoraFin3Jueves(e)}
                                    />
                                </MuiPickersUtilsProvider></div>
: ''}
                                <div>
                                    <Checkbox
                                        checked={viernes}
                                        onClick={handleChangeViernes}
                                    />Viernes
                                </div>
                                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                    <TimePicker
                                        inputVariant="outlined"
                                        clearable
                                        ampm={false}
                                        label="Hora Inicio 1"
                                        value={HoraInicio1Viernes}
                                        minutesStep={30}
                                        onChange={(e) => setHoraInicio1Viernes(e)}
                                    />
                                </MuiPickersUtilsProvider>
                                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                    <TimePicker
                                        inputVariant="outlined"
                                        clearable
                                        ampm={false}
                                        label="Hora Fin 1"
                                        value={HoraFin1Viernes}
                                        minutesStep={30}
                                        onChange={(e) => setHoraFin1Viernes(e)}
                                    />
                                </MuiPickersUtilsProvider>

                                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                    <TimePicker
                                        inputVariant="outlined"
                                        clearable
                                        ampm={false}
                                        label="Hora Inicio 2"
                                        value={HoraInicio2Viernes}
                                        minutesStep={30}
                                        onChange={(e) => {
                                            console.log(e);
                                            setHoraInicio2Viernes(e);
                                        }
                                        }
                                    />
                                </MuiPickersUtilsProvider>
                                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                    <TimePicker
                                        inputVariant="outlined"
                                        clearable
                                        ampm={false}
                                        label="Hora Fin 2"
                                        value={HoraFin2Viernes}
                                        minutesStep={30}
                                        onChange={(e) => setHoraFin2Viernes(e)}
                                    />
                                </MuiPickersUtilsProvider>
                                { horarioRotativo ? <div>
                                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                    <TimePicker
                                        inputVariant="outlined"
                                        clearable
                                        ampm={false}
                                        label="Hora Inicio 3"
                                        value={HoraInicio3Viernes}
                                        minutesStep={30}
                                        onChange={(e) => {
                                            console.log(e);
                                            setHoraInicio3Viernes(e);
                                        }
                                        }
                                    />
                                </MuiPickersUtilsProvider>
                                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                    <TimePicker
                                        inputVariant="outlined"
                                        clearable
                                        ampm={false}
                                        label="Hora Fin 3"
                                        value={HoraFin3Viernes}
                                        minutesStep={30}
                                        onChange={(e) => setHoraFin3Viernes(e)}
                                    />
                                </MuiPickersUtilsProvider></div>
: ''}
                                <div>
                                    <Checkbox
                                        checked={sabado}
                                        onClick={handleChangeSabado}
                                    />Sabado
                                </div>
                                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                    <TimePicker
                                        inputVariant="outlined"
                                        clearable
                                        ampm={false}
                                        label="Hora Inicio 1"
                                        value={HoraInicio1Sabado}
                                        minutesStep={30}
                                        onChange={(e) => setHoraInicio1Sabado(e)}
                                    />
                                </MuiPickersUtilsProvider>
                                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                    <TimePicker
                                        inputVariant="outlined"
                                        clearable
                                        ampm={false}
                                        label="Hora Fin 1"
                                        value={HoraFin1Sabado}
                                        minutesStep={30}
                                        onChange={(e) => setHoraFin1Sabado(e)}
                                    />
                                </MuiPickersUtilsProvider>

                                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                    <TimePicker
                                        inputVariant="outlined"
                                        clearable
                                        ampm={false}
                                        label="Hora Inicio 2"
                                        value={HoraInicio2Sabado}
                                        minutesStep={30}
                                        onChange={(e) => {
                                            console.log(e);
                                            setHoraInicio2Sabado(e);
                                        }
                                        }
                                    />
                                </MuiPickersUtilsProvider>
                                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                    <TimePicker
                                        inputVariant="outlined"
                                        clearable
                                        ampm={false}
                                        label="Hora Fin 2"
                                        value={HoraFin2Sabado}
                                        minutesStep={30}
                                        onChange={(e) => setHoraFin2Sabado(e)}
                                    />
                                </MuiPickersUtilsProvider>
                                { horarioRotativo ? <div>
                                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                    <TimePicker
                                        inputVariant="outlined"
                                        clearable
                                        ampm={false}
                                        label="Hora Inicio 3"
                                        value={HoraInicio3Sabado}
                                        minutesStep={30}
                                        onChange={(e) => {
                                            console.log(e);
                                            setHoraInicio3Sabado(e);
                                        }
                                        }
                                    />
                                </MuiPickersUtilsProvider>
                                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                    <TimePicker
                                        inputVariant="outlined"
                                        clearable
                                        ampm={false}
                                        label="Hora Fin 3"
                                        value={HoraFin3Sabado}
                                        minutesStep={30}
                                        onChange={(e) => setHoraFin3Sabado(e)}
                                    />
                                </MuiPickersUtilsProvider></div>
: ''}
                                <div>
                                    <Checkbox
                                        checked={domingo}
                                        onClick={handleChangeDomingo}
                                    />Domingo
                                </div>
                                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                    <TimePicker
                                        inputVariant="outlined"
                                        clearable
                                        ampm={false}
                                        label="Hora Inicio 1"
                                        value={HoraInicio1Domingo}
                                        minutesStep={30}
                                        onChange={(e) => setHoraInicio1Domingo(e)}
                                    />
                                </MuiPickersUtilsProvider>
                                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                    <TimePicker
                                        inputVariant="outlined"
                                        clearable
                                        ampm={false}
                                        label="Hora Fin 1"
                                        value={HoraFin1Domingo}
                                        minutesStep={30}
                                        onChange={(e) => setHoraFin1Domingo(e)}
                                    />
                                </MuiPickersUtilsProvider>

                                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                    <TimePicker
                                        inputVariant="outlined"
                                        clearable
                                        ampm={false}
                                        label="Hora Inicio 2"
                                        value={HoraInicio2Domingo}
                                        minutesStep={30}
                                        onChange={(e) => {
                                            console.log(e);
                                            setHoraInicio2Domingo(e);
                                        }
                                        }
                                    />
                                </MuiPickersUtilsProvider>
                                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                    <TimePicker
                                        inputVariant="outlined"
                                        clearable
                                        ampm={false}
                                        label="Hora Fin 2"
                                        value={HoraFin2Domingo}
                                        minutesStep={30}
                                        onChange={(e) => setHoraFin2Domingo(e)}
                                    />
                                </MuiPickersUtilsProvider>
                                { horarioRotativo ? <div>
                                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                    <TimePicker
                                        inputVariant="outlined"
                                        clearable
                                        ampm={false}
                                        label="Hora Inicio 3"
                                        value={HoraInicio3Sabado}
                                        minutesStep={30}
                                        onChange={(e) => {
                                            console.log(e);
                                            setHoraInicio3Sabado(e);
                                        }
                                        }
                                    />
                                </MuiPickersUtilsProvider>
                                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                    <TimePicker
                                        inputVariant="outlined"
                                        clearable
                                        ampm={false}
                                        label="Hora Fin 3"
                                        value={HoraFin3Sabado}
                                        minutesStep={30}
                                        onChange={(e) => setHoraFin3Sabado(e)}
                                    />
                                </MuiPickersUtilsProvider></div>
: ''}

                            </DialogContent>
                            <DialogActions>
                                <Button onClick={handleCloseAgregarEmpleado} color="primary">
                                    Cerrar
                                </Button>
                                <Button onClick={handleAgregarEmpleado} color="primary" autoFocus>
                                    Agregar Horario
                                </Button>
                            </DialogActions>
                        </Dialog>



                        <Dialog
                            open={modificarHorario}
                            onClose={handleCloseModificarHorario}
                            aria-labelledby="alert-dialog-title"
                            aria-describedby="alert-dialog-description"
                        >
                            <DialogTitle id="alert-dialog-title">{"Modificar Horario"}</DialogTitle>
                            <DialogContent>
                                <DialogContentText id="alert-dialog-description">
                                    Favor de seleccionar las opciones para Modificar el horarario
                                </DialogContentText>
                                <TextField
                                    name="descripcionHorario"
                                    variant="outlined"
                                    fullWidth
                                    id="txtDescripcionHorario"
                                    label="Descipción Horario"
                                    value={descripcionModificar}
                                    onChange={onChangeDescripcionModificar}
                                />
                                <div>
                                <Checkbox
                                        checked={horarioRotativoModificar}
                                        onClick={handleChangeHorarioRotativoModificar}
                                    />Horarios Rotativos
                                </div>
                                <div>
                                    <Checkbox
                                        checked={lunesModificar}
                                        onClick={handleChangeLunesModificar}
                                    />Lunes
                                </div>
                                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                    <TimePicker
                                        inputVariant="outlined"
                                        clearable
                                        ampm={false}
                                        label="Hora Inicio 1"
                                        value={HoraInicio1LunesModificar}
                                        minutesStep={30}
                                        onChange={(e) => setHoraInicio1LunesModificar(e)}
                                    />
                                </MuiPickersUtilsProvider>
                                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                    <TimePicker
                                        inputVariant="outlined"
                                        clearable
                                        ampm={false}
                                        label="Hora Fin 1"
                                        value={HoraFin1LunesModificar}
                                        minutesStep={30}
                                        onChange={(e) => setHoraFin1LunesModificar(e)}
                                    />
                                </MuiPickersUtilsProvider>

                                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                    <TimePicker
                                        inputVariant="outlined"
                                        clearable
                                        ampm={false}
                                        label="Hora Inicio 2"
                                        value={HoraInicio2LunesModificar}
                                        minutesStep={30}
                                        onChange={(e) => {
                                            console.log(e);
                                            setHoraInicio2LunesModificar(e);
                                        }
                                        }
                                    />
                                </MuiPickersUtilsProvider>
                                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                    <TimePicker
                                        inputVariant="outlined"
                                        clearable
                                        ampm={false}
                                        label="Hora Fin 2"
                                        value={HoraFin2LunesModificar}
                                        minutesStep={30}
                                        onChange={(e) => setHoraFin2LunesModificar(e)}
                                    />
                                </MuiPickersUtilsProvider>

{ horarioRotativoModificar ? <div>
                                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                    <TimePicker
                                        inputVariant="outlined"
                                        clearable
                                        ampm={false}
                                        label="Hora Inicio 3"
                                        value={HoraInicio3LunesModificar}
                                        minutesStep={30}
                                        onChange={(e) => {
                                            console.log(e);
                                            setHoraInicio3LunesModificar(e);
                                        }
                                        }
                                    />
                                </MuiPickersUtilsProvider>
                                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                    <TimePicker
                                        inputVariant="outlined"
                                        clearable
                                        ampm={false}
                                        label="Hora Fin 3"
                                        value={HoraFin3LunesModificar}
                                        minutesStep={30}
                                        onChange={(e) => setHoraFin3LunesModificar(e)}
                                    />
                                </MuiPickersUtilsProvider></div>
: ''}
                                <div>
                                    <Checkbox
                                        checked={martesModificar}
                                        onClick={handleChangeMartesModificar}
                                    />Martes
                                </div>
                                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                    <TimePicker
                                        inputVariant="outlined"
                                        clearable
                                        ampm={false}
                                        label="Hora Inicio 1"
                                        value={HoraInicio1MartesModificar}
                                        minutesStep={30}
                                        onChange={(e) => setHoraInicio1MartesModificar(e)}
                                    />
                                </MuiPickersUtilsProvider>
                                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                    <TimePicker
                                        inputVariant="outlined"
                                        clearable
                                        ampm={false}
                                        label="Hora Fin 1"
                                        value={HoraFin1MartesModificar}
                                        minutesStep={30}
                                        onChange={(e) => setHoraFin1MartesModificar(e)}
                                    />
                                </MuiPickersUtilsProvider>

                                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                    <TimePicker
                                        inputVariant="outlined"
                                        clearable
                                        ampm={false}
                                        label="Hora Inicio 2"
                                        value={HoraInicio2MartesModificar}
                                        minutesStep={30}
                                        onChange={(e) => {
                                            console.log(e);
                                            setHoraInicio2MartesModificar(e);
                                        }
                                        }
                                    />
                                </MuiPickersUtilsProvider>
                                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                    <TimePicker
                                        inputVariant="outlined"
                                        clearable
                                        ampm={false}
                                        label="Hora Fin 2"
                                        value={HoraFin2MartesModificar}
                                        minutesStep={30}
                                        onChange={(e) => setHoraFin2MartesModificar(e)}
                                    />
                                </MuiPickersUtilsProvider>
                                { horarioRotativoModificar ? <div>
                                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                    <TimePicker
                                        inputVariant="outlined"
                                        clearable
                                        ampm={false}
                                        label="Hora Inicio 3"
                                        value={HoraInicio3MartesModificar}
                                        minutesStep={30}
                                        onChange={(e) => {
                                            console.log(e);
                                            setHoraInicio3MartesModificar(e);
                                        }
                                        }
                                    />
                                </MuiPickersUtilsProvider>
                                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                    <TimePicker
                                        inputVariant="outlined"
                                        clearable
                                        ampm={false}
                                        label="Hora Fin 3"
                                        value={HoraFin3MartesModificar}
                                        minutesStep={30}
                                        onChange={(e) => setHoraFin3MartesModificar(e)}
                                    />
                                </MuiPickersUtilsProvider></div>
: ''}
                                <div>
                                    <Checkbox
                                        checked={miercolesModificar}
                                        onClick={handleChangeMiercolesModificar}
                                    />Miercoles
                                </div>
                                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                    <TimePicker
                                        inputVariant="outlined"
                                        clearable
                                        ampm={false}
                                        label="Hora Inicio 1"
                                        value={HoraInicio1MiercolesModificar}
                                        minutesStep={30}
                                        onChange={(e) => setHoraInicio1MiercolesModificar(e)}
                                    />
                                </MuiPickersUtilsProvider>
                                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                    <TimePicker
                                        inputVariant="outlined"
                                        clearable
                                        ampm={false}
                                        label="Hora Fin 1"
                                        value={HoraFin1MiercolesModificar}
                                        minutesStep={30}
                                        onChange={(e) => setHoraFin1MiercolesModificar(e)}
                                    />
                                </MuiPickersUtilsProvider>

                                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                    <TimePicker
                                        inputVariant="outlined"
                                        clearable
                                        ampm={false}
                                        label="Hora Inicio 2"
                                        value={HoraInicio2MiercolesModificar}
                                        minutesStep={30}
                                        onChange={(e) => {
                                            console.log(e);
                                            setHoraInicio2MiercolesModificar(e);
                                        }
                                        }
                                    />
                                </MuiPickersUtilsProvider>
                                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                    <TimePicker
                                        inputVariant="outlined"
                                        clearable
                                        ampm={false}
                                        label="Hora Fin 2"
                                        value={HoraFin2MiercolesModificar}
                                        minutesStep={30}
                                        onChange={(e) => setHoraFin2MiercolesModificar(e)}
                                    />
                                </MuiPickersUtilsProvider>
                                { horarioRotativoModificar ? <div>
                                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                    <TimePicker
                                        inputVariant="outlined"
                                        clearable
                                        ampm={false}
                                        label="Hora Inicio 3"
                                        value={HoraInicio3MiercolesModificar}
                                        minutesStep={30}
                                        onChange={(e) => {
                                            console.log(e);
                                            setHoraInicio3MiercolesModificar(e);
                                        }
                                        }
                                    />
                                </MuiPickersUtilsProvider>
                                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                    <TimePicker
                                        inputVariant="outlined"
                                        clearable
                                        ampm={false}
                                        label="Hora Fin 3"
                                        value={HoraFin3MiercolesModificar}
                                        minutesStep={30}
                                        onChange={(e) => setHoraFin3MiercolesModificar(e)}
                                    />
                                </MuiPickersUtilsProvider></div>
: ''}
                                <div>
                                    <Checkbox
                                        checked={jueves}
                                        onClick={handleChangeJueves}
                                    />Jueves
                                </div>
                                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                    <TimePicker
                                        inputVariant="outlined"
                                        clearable
                                        ampm={false}
                                        label="Hora Inicio 1"
                                        value={HoraInicio1JuevesModificar}
                                        minutesStep={30}
                                        onChange={(e) => setHoraInicio1JuevesModificar(e)}
                                    />
                                </MuiPickersUtilsProvider>
                                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                    <TimePicker
                                        inputVariant="outlined"
                                        clearable
                                        ampm={false}
                                        label="Hora Fin 1"
                                        value={HoraFin1JuevesModificar}
                                        minutesStep={30}
                                        onChange={(e) => setHoraFin1JuevesModificar(e)}
                                    />
                                </MuiPickersUtilsProvider>

                                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                    <TimePicker
                                        inputVariant="outlined"
                                        clearable
                                        ampm={false}
                                        label="Hora Inicio 2"
                                        value={HoraInicio2Jueves}
                                        minutesStep={30}
                                        onChange={(e) => {
                                            console.log(e);
                                            setHoraInicio2JuevesModificar(e);
                                        }
                                        }
                                    />
                                </MuiPickersUtilsProvider>
                                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                    <TimePicker
                                        inputVariant="outlined"
                                        clearable
                                        ampm={false}
                                        label="Hora Fin 2"
                                        value={HoraFin2JuevesModificar}
                                        minutesStep={30}
                                        onChange={(e) => setHoraFin2JuevesModificar(e)}
                                    />
                                </MuiPickersUtilsProvider>
                                { horarioRotativoModificar ? <div>
                                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                    <TimePicker
                                        inputVariant="outlined"
                                        clearable
                                        ampm={false}
                                        label="Hora Inicio 3"
                                        value={HoraInicio3JuevesModificar}
                                        minutesStep={30}
                                        onChange={(e) => {
                                            console.log(e);
                                            setHoraInicio3JuevesModificar(e);
                                        }
                                        }
                                    />
                                </MuiPickersUtilsProvider>
                                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                    <TimePicker
                                        inputVariant="outlined"
                                        clearable
                                        ampm={false}
                                        label="Hora Fin 3"
                                        value={HoraFin3JuevesModificar}
                                        minutesStep={30}
                                        onChange={(e) => setHoraFin3JuevesModificar(e)}
                                    />
                                </MuiPickersUtilsProvider></div>
: ''}
                                <div>
                                    <Checkbox
                                        checked={viernesModificar}
                                        onClick={handleChangeViernesModificar}
                                    />Viernes
                                </div>
                                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                    <TimePicker
                                        inputVariant="outlined"
                                        clearable
                                        ampm={false}
                                        label="Hora Inicio 1"
                                        value={HoraInicio1ViernesModificar}
                                        minutesStep={30}
                                        onChange={(e) => setHoraInicio1ViernesModificar(e)}
                                    />
                                </MuiPickersUtilsProvider>
                                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                    <TimePicker
                                        inputVariant="outlined"
                                        clearable
                                        ampm={false}
                                        label="Hora Fin 1"
                                        value={HoraFin1ViernesModificar}
                                        minutesStep={30}
                                        onChange={(e) => setHoraFin1ViernesModificar(e)}
                                    />
                                </MuiPickersUtilsProvider>

                                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                    <TimePicker
                                        inputVariant="outlined"
                                        clearable
                                        ampm={false}
                                        label="Hora Inicio 2"
                                        value={HoraInicio2ViernesModificar}
                                        minutesStep={30}
                                        onChange={(e) => {
                                            console.log(e);
                                            setHoraInicio2ViernesModificar(e);
                                        }
                                        }
                                    />
                                </MuiPickersUtilsProvider>
                                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                    <TimePicker
                                        inputVariant="outlined"
                                        clearable
                                        ampm={false}
                                        label="Hora Fin 2"
                                        value={HoraFin2ViernesModificar}
                                        minutesStep={30}
                                        onChange={(e) => setHoraFin2ViernesModificar(e)}
                                    />
                                </MuiPickersUtilsProvider>
                                { horarioRotativoModificar ? <div>
                                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                    <TimePicker
                                        inputVariant="outlined"
                                        clearable
                                        ampm={false}
                                        label="Hora Inicio 3"
                                        value={HoraInicio3ViernesModificar}
                                        minutesStep={30}
                                        onChange={(e) => {
                                            console.log(e);
                                            setHoraInicio3ViernesModificar(e);
                                        }
                                        }
                                    />
                                </MuiPickersUtilsProvider>
                                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                    <TimePicker
                                        inputVariant="outlined"
                                        clearable
                                        ampm={false}
                                        label="Hora Fin 3"
                                        value={HoraFin3ViernesModificar}
                                        minutesStep={30}
                                        onChange={(e) => setHoraFin3ViernesModificar(e)}
                                    />
                                </MuiPickersUtilsProvider></div>
: ''}
                                <div>
                                    <Checkbox
                                        checked={sabadoModificar}
                                        onClick={handleChangeSabadoModificar}
                                    />Sabado
                                </div>
                                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                    <TimePicker
                                        inputVariant="outlined"
                                        clearable
                                        ampm={false}
                                        label="Hora Inicio 1"
                                        value={HoraInicio1SabadoModificar}
                                        minutesStep={30}
                                        onChange={(e) => setHoraInicio1SabadoModificar(e)}
                                    />
                                </MuiPickersUtilsProvider>
                                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                    <TimePicker
                                        inputVariant="outlined"
                                        clearable
                                        ampm={false}
                                        label="Hora Fin 1"
                                        value={HoraFin1SabadoModificar}
                                        minutesStep={30}
                                        onChange={(e) => setHoraFin1SabadoModificar(e)}
                                    />
                                </MuiPickersUtilsProvider>

                                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                    <TimePicker
                                        inputVariant="outlined"
                                        clearable
                                        ampm={false}
                                        label="Hora Inicio 2"
                                        value={HoraInicio2SabadoModificar}
                                        minutesStep={30}
                                        onChange={(e) => {
                                            console.log(e);
                                            setHoraInicio2SabadoModificar(e);
                                        }
                                        }
                                    />
                                </MuiPickersUtilsProvider>
                                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                    <TimePicker
                                        inputVariant="outlined"
                                        clearable
                                        ampm={false}
                                        label="Hora Fin 2"
                                        value={HoraFin2SabadoModificar}
                                        minutesStep={30}
                                        onChange={(e) => setHoraFin2SabadoModificar(e)}
                                    />
                                </MuiPickersUtilsProvider>
                                { horarioRotativoModificar ? <div>
                                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                    <TimePicker
                                        inputVariant="outlined"
                                        clearable
                                        ampm={false}
                                        label="Hora Inicio 3"
                                        value={HoraInicio3SabadoModificar}
                                        minutesStep={30}
                                        onChange={(e) => {
                                            console.log(e);
                                            setHoraInicio3SabadoModificar(e);
                                        }
                                        }
                                    />
                                </MuiPickersUtilsProvider>
                                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                    <TimePicker
                                        inputVariant="outlined"
                                        clearable
                                        ampm={false}
                                        label="Hora Fin 3"
                                        value={HoraFin3SabadoModificar}
                                        minutesStep={30}
                                        onChange={(e) => setHoraFin3SabadoModificar(e)}
                                    />
                                </MuiPickersUtilsProvider></div>
: ''}
                                <div>
                                    <Checkbox
                                        checked={domingoModificar}
                                        onClick={handleChangeDomingoModificar}
                                    />Domingo
                                </div>
                                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                    <TimePicker
                                        inputVariant="outlined"
                                        clearable
                                        ampm={false}
                                        label="Hora Inicio 1"
                                        value={HoraInicio1DomingoModificar}
                                        minutesStep={30}
                                        onChange={(e) => setHoraInicio1DomingoModificar(e)}
                                    />
                                </MuiPickersUtilsProvider>
                                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                    <TimePicker
                                        inputVariant="outlined"
                                        clearable
                                        ampm={false}
                                        label="Hora Fin 1"
                                        value={HoraFin1DomingoModificar}
                                        minutesStep={30}
                                        onChange={(e) => setHoraFin1DomingoModificar(e)}
                                    />
                                </MuiPickersUtilsProvider>

                                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                    <TimePicker
                                        inputVariant="outlined"
                                        clearable
                                        ampm={false}
                                        label="Hora Inicio 2"
                                        value={HoraInicio2DomingoModificar}
                                        minutesStep={30}
                                        onChange={(e) => {
                                            console.log(e);
                                            setHoraInicio2DomingoModificar(e);
                                        }
                                        }
                                    />
                                </MuiPickersUtilsProvider>
                                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                    <TimePicker
                                        inputVariant="outlined"
                                        clearable
                                        ampm={false}
                                        label="Hora Fin 2"
                                        value={HoraFin2DomingoModificar}
                                        minutesStep={30}
                                        onChange={(e) => setHoraFin2DomingoModificar(e)}
                                    />
                                </MuiPickersUtilsProvider>
                                { horarioRotativoModificar ? <div>
                                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                    <TimePicker
                                        inputVariant="outlined"
                                        clearable
                                        ampm={false}
                                        label="Hora Inicio 3"
                                        value={HoraInicio3DomingoModificar}
                                        minutesStep={30}
                                        onChange={(e) => {
                                            console.log(e);
                                            setHoraInicio3DomingoModificar(e);
                                        }
                                        }
                                    />
                                </MuiPickersUtilsProvider>
                                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                    <TimePicker
                                        inputVariant="outlined"
                                        clearable
                                        ampm={false}
                                        label="Hora Fin 3"
                                        value={HoraFin3DomingoModificar}
                                        minutesStep={30}
                                        onChange={(e) => setHoraFin3DomingoModificar(e)}
                                    />
                                </MuiPickersUtilsProvider></div>
: ''}

                            </DialogContent>
                            <DialogActions>
                                <Button onClick={handleCloseModificarHorario} color="primary">
                                    Cerrar
                                </Button>
                                <Button onClick={handleModificarHorario} color="primary" autoFocus>
                                    Agregar Horario
                                </Button>
                            </DialogActions>
                        </Dialog>





                        <Dialog
        open={openEliminar}
        onClose={handleCloseEliminarHorario}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{"¿Desea eliminar este Horario?"}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Una vez eliminado, no se podrá revertir está acción
          </DialogContentText>
        </DialogContent>
        <DialogActions>
        <Button onClick={handleCloseEliminarHorario} color="primary" autoFocus>
            Cerrar
          </Button>
          <Button onClick={handleEliminarHorario} color="primary">
            Eliminar
          </Button>
        </DialogActions>
      </Dialog>

                    </div>
                </Container>

                <Snackbar
                    anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
                    open={Boolean(messageSnackbar)}
                    onClose={() => setMessageSnackbar('')}
                    message={messageSnackbar}
                    autoHideDuration={6000}
                />
            </main>
        </div>
    )

}

const mapStateToProps = state => {
    return {
        addEmpleado: state.EmpleadosReducer.addEmpleado,
        sucursales: state.EmpleadosReducer.sucursales,
        addHorario: state.HorariosReducer.addHorario,
        horarios: state.HorariosReducer.horarios,
        deleteHorario: state.HorariosReducer.deleteHorario,
        addSesion: state.SesionReducer.addSesion,
        alterHorario: state.SesionReducer.alterHorario,
    }
}

const mapDispatchToProps = dispatch => {
    return {
        getHorarios: () => dispatch(HorariosActions.getAllHorarios()),
        AddHorario: (Horario, idUsuario) => dispatch(HorariosActions.AddHorario(Horario, idUsuario)),
        DeleteHorario: (idHorario, idUsuario) => dispatch(HorariosActions.DeleteHorario(idHorario, idUsuario)),
        AlterHorario: (Horario, idUsuario) => dispatch(HorariosActions.AlterHorario(Horario, idUsuario)),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Horario);
