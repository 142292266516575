import * as actionTypes from './actionTypes';

const initialState = {
    listadoDepartamentos: undefined,
    agregarDepartamento: undefined,
    eliminarDpto: undefined,
};

export default function DepartamentosReducer(state, action) {
    if (typeof state === 'undefined') {
        return initialState;
    }
    switch (action.type) {
        case actionTypes.GetListadoDepartamentos:
            return Object.assign({}, state, {
                listadoDepartamentos: action.payload,
            });
        case actionTypes.agregarDepartamentos:
            return Object.assign({}, state, {
                agregarDepartamento: action.payload,
            });
        case actionTypes.eliminarDepartamento:
            return Object.assign({}, state, {
                eliminarDpto: action.payload,
            });
        default: return state;
    }
}