import * as actionTypes from './actionTypes';
import { connector } from '../connector/index';

export const getAllDepartamentos = () => {
    return async (dispatch) => {
        const result = await connector(`/departamentos/all`, 'GET', {},{}).then((response) => {
            dispatch({
                type: actionTypes.GetListadoDepartamentos,
                payload: response.data,
            });
            return response;
        }).catch(err => {
            dispatch({
                type: actionTypes.GetListadoDepartamentos,
                payload: err.data,
            });
            return err.response;
        });
        return result && result.response ? result.response.data : result.data;
    }
}

export const agregarDepartamento = (data, idUsuario) => {
    return async (dispatch) => {
        const result = await connector(`/departamentos/agregar?idUsuario=${idUsuario}`, 'PUT', data,{}).then((response) => {
            dispatch({
                type: actionTypes.agregarDepartamentos,
                payload: response.data,
            });
            return response;
        }).catch(err => {
            dispatch({
                type: actionTypes.agregarDepartamentos,
                payload: err.data,
            });
            return err.response;
        });
        return result && result.response ? result.response.data : result.data;
    }
}

export const eliminarDepartamento = (idDpto, idUsuario) => {
    return async (dispatch) => {
        const result = await connector(`/departamentos/eliminar?idUsuario=${idUsuario}&idDepartamento=${idDpto}`, 'DELETE', {},{}).then((response) => {
            dispatch({
                type: actionTypes.eliminarDepartamento,
                payload: response.data,
            });
            return response;
        }).catch(err => {
            dispatch({
                type: actionTypes.eliminarDepartamento,
                payload: err.data,
            });
            return err.response;
        });
        return result && result.response ? result.response.data : result.data;
    }
}