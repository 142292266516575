import React, {useEffect } from 'react';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import { createMuiTheme } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Add from '@material-ui/icons/Add';
import Paper from '@material-ui/core/Paper';
import Menu from '../../modulos/Menu/index';
import { connect } from 'react-redux';
import * as EmpleadosActions from '../../Api/Empleados/actions';
import { Redirect } from 'react-router';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Snackbar from '@material-ui/core/Snackbar';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';

const useStyles = makeStyles((theme) => ({
    table: {
        minWidth: 560,
      },
    paper: {
      marginTop: theme.spacing(4),
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
    },
    form: {
      width: '100%', 
      marginTop: theme.spacing(3),
    },
    submit: {
      margin: theme.spacing(3, 0, 2),
    },
    root: {
        display: 'flex',
      },
     toolbar: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-end',
        padding: theme.spacing(0, 1),
        // necessary for content to be below app bar
        ...theme.mixins.toolbar,
      },
      content: {
        flexGrow: 1,
        padding: theme.spacing(3),
      },
      formControl: {
        margin: theme.spacing(1),
        minWidth: 250,
      },
      selectEmpty: {
        marginTop: theme.spacing(2),
      },
  }));

  const theme = createMuiTheme();

function CatalogoEmpleados(props) {
  const [redirect, setRedirect] = React.useState(false);
  const [permit, setPermit] = React.useState(true);
  const [redirectAlter, setRedirectAlter] = React.useState(false);
  const [redirectProfile, setRedirectProfile] = React.useState(false);
  const [idEmpleado, setIdEmpleado] = React.useState('');
  const [open, setOpen] = React.useState(false);
  const [idEmpleadoToDelete, setIdEmpleadoToDelete] = React.useState('');
  const [messageSnackbar, setMessageSnackbar] = React.useState('');
  const [sucur, setSucur] = React.useState('');
  const [arraySucursales, setArraySucursales] = React.useState([]);
  
  useEffect(() => {
    if(permit){
      props.addSesion && props.addSesion.user && props.addSesion.user.admin == false ?
      props.getEmpleados(props.addSesion.empleados.idSucursal)
      :
      props.getSucursales().then(result => {
        if (result && Array.isArray(result)){
          setArraySucursales(result);
          setPermit(false);
        }
      });
    }
    }, [permit]); 

    const handleClose = () => {
      setOpen(false);
    };

    const classes = useStyles();

    const onRedirectToAddEmpleado = () => {
      setRedirect(true);
    }

    const onRedirectToModifyEmpleado = (id) => {
      setIdEmpleado(id);
      setRedirectAlter(true);
    }

    const onRedirectToVerPerfil = (id) => {
      setIdEmpleado(id);
      setRedirectProfile(true);
    }

    const openModalEliminarEmpleado = (id) => {
      setIdEmpleadoToDelete(id);
      setOpen(true);
    }

    const handleEliminarEmpleado = () => {
      props.EraseEmpleado(idEmpleadoToDelete, props.addSesion.user.id).then(result => {
        if (result && result.message) {
          setOpen(false);
          setMessageSnackbar(result.message);
          setIdEmpleadoToDelete('');
          props.getEmpleados(sucur);
        }
      })
    }

    const onBuscarEmpleadosPorSucursal = () => {
      props.getEmpleados(sucur);
    }


    if(redirectAlter){
      return <Redirect to={`/actualizarempleados/${idEmpleado}`} /> 
    }

    if(redirect){
      return <Redirect to='/registrarempleados' /> 
    }

    if(redirectProfile){
      return <Redirect to={`/empleado/perfil/${idEmpleado}`}  /> 
    }
    
    const handleChangeSucursal = (event) => {
      setSucur(event.target.value);
    }

    return(
        <div className={classes.root}>
        <Menu title="Administración de empleados"/>
        <main className={classes.content}>
          <div className={classes.toolbar} />
          <Container component="main" >
      <div className={classes.paper}>
        <Typography variant="h4">
        Listado de empleados
        </Typography>
          <Grid container spacing={2}>
          <Grid item xs={6} sm={4} >

          {props.addSesion && props.addSesion.user && props.addSesion.user.admin ?
                <Button
                  type="submit"
                  fullWidth
                  variant="outlined"
                  color="primary"
                  onClick={onRedirectToAddEmpleado}
                >
                <Add />
                  Agregar
                </Button>
                : ''
          }
                </Grid>



                {props.addSesion && props.addSesion.user && props.addSesion.user.admin ?
                  <Grid container spacing={2} >
                   <Grid item xs={12} sm={3}>
                  <FormControl className={classes.formControl}>
                  <InputLabel id="seleccionar-sucursal">Sucursal</InputLabel>
                  <Select
                    labelId="sucursales"
                    id="sucursaless"
                    value={sucur}
                    onChange={handleChangeSucursal}
                    fullWidth
                  >
                    <MenuItem value="">
                      <em>Ninguna</em>
                    </MenuItem>
                    <MenuItem value="TODAS">
                      <em>Todas</em>
                    </MenuItem>
                    {
                        arraySucursales.map(item => {
                          return  (<MenuItem value={item.id}>
                          <em>{item.nombre} </em>
                        </MenuItem>);
                        })
                    }
                  </Select>
                </FormControl>
                </Grid>
                <Grid item xs={12} sm={2}>
                <Button
                  type="submit"
                  fullWidth
                  variant="outlined"
                  color="primary"
                  onClick={onBuscarEmpleadosPorSucursal}
                  style={{marginTop: '20px'}}
                >
                  Buscar
                </Button>
                </Grid>
              </Grid>
                  : ''
                }

            <Grid item xs={7} md={12} >
            <TableContainer component={Paper}>
      <Table className={classes.table} size="small" aria-label="a dense table">
        <TableHead>
          <TableRow>
            <TableCell align="center">ID</TableCell>
            <TableCell align="center">Nombre</TableCell>
            <TableCell align="center">Teléfono</TableCell>
            <TableCell align="center">Sucursal</TableCell>
            <TableCell align="center"></TableCell>
            {      props.addSesion && props.addSesion.user && props.addSesion.user.admin?
                <TableCell align="center"></TableCell> : ''}
          </TableRow>
        </TableHead>
        <TableBody>
        {props.empleados && props.empleados.empleados && props.empleados.empleados.length >=1 ? 
          props.empleados.empleados.map((empleado) => (
            <TableRow key={empleado.idEmpleado}>
              <TableCell component="th" scope="row" align="center">
                {empleado.idEmpleado}
              </TableCell>
              <TableCell align="center">{empleado.nombre}</TableCell>
              <TableCell align="center">{empleado.celular}</TableCell>
              <TableCell align="center">{empleado.nombreSucursal}</TableCell>
              <TableCell align="center">
                <Button
                      type=""
                      variant="outlined"
                      color="primary"
                      onClick={() => onRedirectToVerPerfil(empleado.idEmpleado)}
                      >Ver Perfil</Button></TableCell>
{      props.addSesion && props.addSesion.user && props.addSesion.user.admin ?
              <TableCell align="center">
              <Button
                    type=""
                    variant="outlined"
                    color="primary"
                    onClick = {() => openModalEliminarEmpleado(empleado.idEmpleado)}
                    >Eliminar
              </Button>
            </TableCell> : ''
}
            </TableRow>
          ))
          :
          (!props.empleados ? '' :
            props.empleados && props.empleados.empleados && props.empleados.empleados.length==0 ? 'No se encontraron resultados': 'No se encontraron resultados')
          }
        </TableBody>
      </Table>
    </TableContainer>
            </Grid>
            <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{"¿Desea eliminar este empleado?"}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Una vez eliminado, no se podrá revertir está acción
          </DialogContentText>
        </DialogContent>
        <DialogActions>
        <Button onClick={handleClose} color="primary" autoFocus>
            Cerrar
          </Button>
          <Button onClick={handleEliminarEmpleado} color="primary">
            Eliminar
          </Button>
        </DialogActions>
      </Dialog>
      <Snackbar
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
        open={Boolean(messageSnackbar)}
        onClose={()=>setMessageSnackbar('')}
        message={messageSnackbar}
        autoHideDuration={6000}
      />
          </Grid>
      </div>
    </Container>
        </main>
      </div>
    )

}

const mapStateToProps = state => {
  return {
    empleados: state.EmpleadosReducer.empleados,
    deleteEmpleado: state.EmpleadosReducer.deleteEmpleado,
    sucursales: state.EmpleadosReducer.sucursales,
    addSesion: state.SesionReducer.addSesion,
  }
}

const mapDispatchToProps = dispatch => {
  return {
    getEmpleados: (sucur) => dispatch(EmpleadosActions.getEmpleadosPorSucursal(sucur)),
    EraseEmpleado: (id, idUsuario) => dispatch(EmpleadosActions.DeleteEmpleado(id, idUsuario)),
    getSucursales: () => dispatch(EmpleadosActions.GetSucursales())
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(CatalogoEmpleados);