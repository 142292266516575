export const GetEmpleados = 'empleados/GET_EMPLEADOS';

export const modifyEmpleados = 'empleados/MODIFY';

export const AddEmpleado = 'empleados/ADD';

export const GetEmpleadoById = 'empleados/GETBYID';

export const AlterEmpleado = 'empleados/ALTER';

export const DeleteEmpleado = 'empleados/DELETE';

export const Sucursales = 'empleados/SUCURSALES';

export const GetSucursalById = 'empleados/SUCURSALBYID';

export const GetHorarios = 'horarios/GET_HORARIOS';

export const GetDepartamentos = 'empleados/DEPARTAMENTOS';

export const GetDepartamentoById = 'empleados/DEPARTAMENTOBYID';