export const GetPrestamosByIdSucursal = 'prestamos/GET_PRESTAMOS_BY_SUCURSAL';

export const ModificarPrestamo = 'prestamos/MODIFICAR';

export const AgregarPrestamo = 'prestamos/AGREGAR';

export const GetSucursales = 'prestamos/SUCURSALES';

export const Empleados = 'prestamos/EMPLEADOS';

