import React from 'react';
import { Redirect, Route} from 'react-router-dom';

export const AuthRoute = ({ component: Component, token, ...rest}) => {
    return (
        <Route 
            {...rest}
            render={
                props => 
                token && token.user ? (
                    <Component {...props} />
                ) : (
                    <Redirect to={{
                        pathname: '/login',
                        state: {from: props.location}
                    }}
                    />
                )
            }
        /> 
    );
;}