export const GetEquipos = 'equipos/GET_SERVICES';

export const modifyEquipos = 'equipos/MODIFY';

export const AddEquipo = 'equipos/ADD';

export const GetEquipoById = 'equipos/GETBYID';

export const AlterEquipo = 'equipos/ALTER';

export const DeleteEquipo = 'equipos/DELETE';