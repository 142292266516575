export const GetPuestos = 'puesto/GET_SERVICES';

export const modifyPuestos = 'puesto/MODIFY';

export const AddPuesto = 'puesto/ADD';

export const GetPuetsoById = 'puesto/GETBYID';

export const AlterPuesto = 'puesto/ALTER';

export const DeletePuesto = 'puesto/DELETE';