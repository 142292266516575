import React, { useEffect } from 'react';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import { createMuiTheme } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Add from '@material-ui/icons/Add';
import Paper from '@material-ui/core/Paper';
import Menu from '../../modulos/Menu/index';
import { connect } from 'react-redux';
import * as PrestamosActions from '../../Api/Prestamos/actions';
import { Redirect } from 'react-router';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Snackbar from '@material-ui/core/Snackbar';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormHelperText from '@material-ui/core/FormHelperText';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import TextField from '@material-ui/core/TextField';

const useStyles = makeStyles((theme) => ({
  table: {
    minWidth: 560,
  },
  paper: {
    marginTop: theme.spacing(4),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  form: {
    width: '100%',
    marginTop: theme.spacing(3),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
  root: {
    display: 'flex',
  },
  toolbar: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(3),
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
}));

const theme = createMuiTheme();

function Prestamos(props) {
  const [redirect, setRedirect] = React.useState(false);
  const [permit, setPermit] = React.useState(true);
  const [redirectAlter, setRedirectAlter] = React.useState(false);
  const [redirectProfile, setRedirectProfile] = React.useState(false);
  const [idEmpleado, setIdEmpleado] = React.useState('');
  const [open, setOpen] = React.useState(false);
  const [prestamoAModificar, setPrestamoAModificar] = React.useState('');
  const [messageSnackbar, setMessageSnackbar] = React.useState('');
  const [sucur, setSucur] = React.useState('');
  const [arraySucursales, setArraySucursales] = React.useState([]);
  const [openGeneralPrestamo, openModalGenerarPrestamo] = React.useState(false);
  const [status, setStatus] = React.useState('ACTIVO');
  const [empleados, setEmpleados] = React.useState([]);
  const [empleadoSelect, setEmpleadoSelect] = React.useState('');
  const [monto, setMonto] = React.useState('');
  const [descuentoSemanal, setDescuentoSemanal] = React.useState('');


  useEffect(() => {
    if (permit) {
      props.addSesion && props.addSesion.user && props.addSesion.user.admin == false ?
        props.getPrestamos(props.addSesion.empleados.idSucursal)
        :
        props.getSucursales().then(result => {
          if (result && Array.isArray(result)) {
            setArraySucursales(result);
            setPermit(false);
          }
        });
    }
  }, [permit]);

  const handleClose = () => {
    setOpen(false);
    setPrestamoAModificar('');
    setStatus("")
  };

  const handleOpenGenerarPrestamo = () => {
    openModalGenerarPrestamo(true);
    props.getEmpleados().then(result => {
      if (result && result.empleados && Array.isArray(result.empleados) && result.empleados.length > 0) {
        setEmpleados(result.empleados);
      } else {
        openModalGenerarPrestamo(false);
      }
    })
  }

  const handleCloseGenerarPrestamo = () => {
    openModalGenerarPrestamo(false);
    setMonto('');
    setEmpleadoSelect('');
    setDescuentoSemanal('');
  }

  const classes = useStyles();

  const openModalEliminarEmpleado = (prestamo) => {
    setPrestamoAModificar(prestamo);
    setStatus("ACTIVO")
    setOpen(true);
  }

  const handleModificarPrestamo = () => {
    let prestModificado = prestamoAModificar;
    console.log(prestamoAModificar);
    prestModificado.status = status;
    props.modificarPrestamo(prestModificado).then(result => {
      if (!result.error) {
        setPrestamoAModificar('');
        setStatus("")
        setOpen(false);
        props.addSesion && props.addSesion.user && props.addSesion.user.admin == false ?
          props.getPrestamos(props.addSesion.empleados.idSucursal)
          :
          props.getPrestamos("TODAS")
      }
      setMessageSnackbar(result.message);
    })
  }

  const onBuscarEmpleadosPorSucursal = () => {
    props.getPrestamos(sucur);
  }

  if (redirectAlter) {
    return <Redirect to={`/actualizarempleados/${idEmpleado}`} />
  }

  if (redirect) {
    return <Redirect to='/registrarempleados' />
  }

  if (redirectProfile) {
    return <Redirect to={`/empleado/perfil/${idEmpleado}`} />
  }

  const handleChangeSucursal = (event) => {
    console.log(event.target.value)
    setSucur(event.target.value);
  }

  const handleChangeStatusModifiy = (event) => {
    setStatus(event.target.value);
  };

  const handleChangeEmpleadoSelected = (event) => {
    setEmpleadoSelect(event.target.value);
  };

  const onChangeMonto = (event) => {
    setMonto(event.target.value);
  }

  const onChangeDescuentoSemanal = (event) => {
    setDescuentoSemanal(event.target.value);
  }

  const handleAgregarPrestamo = () => {
    let data= {
      monto,
      descuentoSemanal,
      idEmpleado: empleadoSelect,
      status: "ACTIVO",
      idEmpleadoAutorizo: props.addSesion.user.idEmpleado
    }
    props.generarPrestamo(data).then(result => {
      if (!result.error) {
        handleCloseGenerarPrestamo();
        setPrestamoAModificar('');
        setStatus("")
        setOpen(false);
        props.addSesion && props.addSesion.user && props.addSesion.user.admin == false ?
          props.getPrestamos(props.addSesion.empleados.idSucursal)
          :
          props.getPrestamos("TODAS")
      }
      setMessageSnackbar(result.message);
    })
  }

  return (
    <div className={classes.root}>
      <Menu title="Administración de Prestamos" />
      <main className={classes.content}>
        <div className={classes.toolbar} />
        <Container component="main" >
          <div className={classes.paper}>
            <Typography variant="h4">
              Administración de prestamos
            </Typography>
            <Grid container spacing={2}>
              <Grid item xs={6} sm={4} >

                {props.addSesion && props.addSesion.user && props.addSesion.user.admin ?
                  <Button
                    type="submit"
                    fullWidth
                    variant="outlined"
                    color="primary"
                    onClick={handleOpenGenerarPrestamo}
                  >
                    <Add />
                    Generar prestamo
                  </Button>
                  : ''
                }
              </Grid>
              {props.addSesion && props.addSesion.user && props.addSesion.user.admin ?
                <Grid item xs={7} md={12} >
                  <FormControl className={classes.formControl}>
                    <InputLabel id="seleccionar-sucursal">Sucursal</InputLabel>
                    <Select
                      labelId="sucursales"
                      id="sucursaless"
                      value={sucur}
                      onChange={handleChangeSucursal}
                      fullWidth
                    >
                      <MenuItem value="">
                        <em>Ninguna</em>
                      </MenuItem>
                      <MenuItem value="TODAS">
                        <em>Todas</em>
                      </MenuItem>
                      {
                        arraySucursales.map(item => {
                          return (<MenuItem value={item.id}>
                            <em>{item.nombre} </em>
                          </MenuItem>);
                        })
                      }
                    </Select>
                  </FormControl>
                  <Button
                    type="submit"
                    fullWidth
                    variant="outlined"
                    color="primary"
                    onClick={onBuscarEmpleadosPorSucursal}
                  >
                    Buscar
                  </Button>
                </Grid>
                : ''
              }

              <Grid item xs={7} md={12} >
                <TableContainer component={Paper}>
                  <Table className={classes.table} size="small" aria-label="a dense table">
                    <TableHead>
                      <TableRow>
                        <TableCell align="center">Id Prestamo</TableCell>
                        <TableCell align="center">Empleado</TableCell>
                        <TableCell align="center">Monto</TableCell>
                        <TableCell align="center">Depositado</TableCell>
                        <TableCell align="center">Empleado generó</TableCell>
                        <TableCell align="center">Estatus</TableCell>
                        <TableCell align="center">Sucursal</TableCell>
                        <TableCell align="center">Fecha Creación</TableCell>
                        <TableCell align="center">Última Modificación</TableCell>
                        <TableCell align="center"></TableCell>
                        <TableCell align="center"></TableCell>

                        <TableCell align="center"></TableCell>
                        {props.addSesion && props.addSesion.user && props.addSesion.user.admin ?
                          <TableCell align="center"></TableCell> : ''}
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {props.prestamos && props.prestamos.prestamosList && props.prestamos.prestamosList.length >= 1 ?
                        props.prestamos.prestamosList.map((prestamo) => (
                          <TableRow key={prestamo.id}>
                            <TableCell component="th" scope="row" align="center">
                              {prestamo.id}
                            </TableCell>
                            <TableCell align="center">{prestamo.empleado ? prestamo.empleado.nombre : 'No disponible'}</TableCell>
                            <TableCell align="center">{prestamo.monto}</TableCell>
                            <TableCell align="center">{prestamo.pagado ? prestamo.pagado : '0'}</TableCell>
                            <TableCell align="center">{prestamo.empleadoGenero ? prestamo.empleadoGenero.nombre : 'No disponible'}</TableCell>
                            <TableCell align="center">{prestamo.status ? prestamo.status : 'No disponible'}</TableCell>
                            <TableCell align="center">{prestamo.sucursal ? prestamo.sucursal.nombre : 'No disponible'}</TableCell>
                            <TableCell align="center">{prestamo.fechaCreacion ? prestamo.fechaCreacion : 'No disponible'}</TableCell>
                            <TableCell align="center">{prestamo.fechaActualizacion ? prestamo.fechaActualizacion : 'No disponible'}</TableCell>

                            {props.addSesion && props.addSesion.user && props.addSesion.user.admin ?
                              <TableCell align="center">
                                <Button
                                  type=""
                                  variant="outlined"
                                  color="primary"
                                  disabled={prestamo.status !== "ACTIVO" ? true : false}
                                  onClick={() => openModalEliminarEmpleado(prestamo)}
                                >Modificar
                                </Button>
                              </TableCell> : ''
                            }
                          </TableRow>
                        ))
                        :
                        (!props.prestamos ? '' :
                          props.prestamos && props.prestamos.prestamosList && props.prestamos.prestamosList.length == 0 ? 'No se encontraron resultados' : 'No se encontraron resultados')
                      }
                    </TableBody>
                  </Table>
                </TableContainer>
              </Grid>
              <Dialog
                open={open}
                onClose={handleClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
              >
                <DialogTitle id="alert-dialog-title">{"Modificación de prestamo"}</DialogTitle>
                <DialogContent>
                  <DialogContentText id="alert-dialog-description">
                    ALERTA: Una vez modificado, no se podrá revertir está acción
                    <div>
                      <FormControl >
                        <InputLabel id="demo-simple-select-label">Age</InputLabel>
                        <Select
                          labelId="demo-simple-select-label"
                          id="demo-simple-select"
                          value={status}
                          label="Age"
                          onChange={handleChangeStatusModifiy}
                        >
                          <MenuItem value={'ACTIVO'}>Activo</MenuItem>
                          <MenuItem value={'PAGADO'}>Pagado</MenuItem>
                          <MenuItem value={'CANCELADO'}>Cancelado</MenuItem>
                        </Select>
                      </FormControl>
                    </div>
                  </DialogContentText>
                </DialogContent>
                <DialogActions>
                  <Button onClick={handleClose} color="primary">
                    Cerrar
                  </Button>
                  <Button onClick={handleModificarPrestamo} color="primary" autoFocus>
                    Modificar
                  </Button>
                </DialogActions>
              </Dialog>

              <Dialog
                open={openGeneralPrestamo}
                onClose={handleCloseGenerarPrestamo}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
              >
                <DialogTitle id="alert-dialog-title">{"Generar préstamo"}</DialogTitle>
                <DialogContent>
                  <DialogContentText id="alert-dialog-description">
                    Favor de seleccionar e ingresar los datos para el prestamo:
                    <div>
                      <FormControl fullWidth >
                        <InputLabel id="demo-simple-select-label">Empleado</InputLabel>
                        <Select
                          labelId="demo-simple-select-label"
                          id="demo-simple-select"
                          value={empleadoSelect}
                          fullWidth
                          label="Age"
                          onChange={handleChangeEmpleadoSelected}
                        >
                          <MenuItem value={''}></MenuItem>
                          {
                            props.empleados && props.empleados && props.empleados.empleados.map(item => {
                              return (<MenuItem value={item.id}>{item.nombre}  Sucursal:{item.sucursal.nombre}</MenuItem>);
                            })
                          }
                        </Select>
                      </FormControl>
                    </div>
                    <TextField
                          name="monto"
                          variant="outlined"
                          fullWidth
                          id="txtMonto"
                          label="Monto"
                          value={monto}
                          onChange={onChangeMonto}
                        />
                    <TextField
                          name="descuentoSemanal"
                          variant="outlined"
                          fullWidth
                          id="txtDescuentoSemanal"
                          label="Descuento Semanal"
                          value={descuentoSemanal}
                          onChange={onChangeDescuentoSemanal}
                    />
                  </DialogContentText>
                </DialogContent>
                <DialogActions>
                  <Button onClick={handleCloseGenerarPrestamo} color="primary">
                    Cerrar
                  </Button>
                  <Button onClick={handleAgregarPrestamo} color="primary" autoFocus>
                    Generar
                  </Button>
                </DialogActions>
              </Dialog>
              <Snackbar
                anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
                open={Boolean(messageSnackbar)}
                onClose={() => setMessageSnackbar('')}
                message={messageSnackbar}
                autoHideDuration={6000}
              />
            </Grid>
          </div>
        </Container>
      </main>
    </div>
  )

}

const mapStateToProps = state => {
  return {
    prestamos: state.PrestamosReducer.prestamosByIdSucursal,
    empleadoModificado: state.PrestamosReducer.prestamosModified,
    sucursales: state.EmpleadosReducer.sucursales,
    addSesion: state.SesionReducer.addSesion,
    empleados: state.PrestamosReducer.empleados,
    prestamoGenerado: state.PrestamosReducer.prestamoGenerado,
  }
}

const mapDispatchToProps = dispatch => {
  return {
    getPrestamos: (sucur) => dispatch(PrestamosActions.getAllPrestamosByidSucursal(sucur)),
    getSucursales: () => dispatch(PrestamosActions.GetSucursales()),
    modificarPrestamo: (prestamo) => dispatch(PrestamosActions.UpdateStatus(prestamo)),
    getEmpleados: () => dispatch(PrestamosActions.EmpleadosParaPrestamo()),
    generarPrestamo: (data) => dispatch(PrestamosActions.GenerarPrestamo(data))
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Prestamos);