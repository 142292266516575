import React, { useEffect } from 'react';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import { createMuiTheme } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Add from '@material-ui/icons/Add';
import Paper from '@material-ui/core/Paper';
import Menu from '../../modulos/Menu/index';
import { connect } from 'react-redux';
import * as PrestamosActions from '../../Api/Prestamos/actions';
import { Redirect } from 'react-router';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Snackbar from '@material-ui/core/Snackbar';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormHelperText from '@material-ui/core/FormHelperText';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import TextField from '@material-ui/core/TextField';
import * as XLSX from 'xlsx';
import * as NominaActions from '../../Api/Nomina/actions';
import moment from 'moment';
import { Divider } from '@material-ui/core';
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Box from '@material-ui/core/Box';
import PropTypes from 'prop-types';
import jsPDF from 'jspdf';
import 'jspdf-autotable';
import tapanosaLogo from '../../img/tapanosa-logo.png';

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}


const tabStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.paper,
  },
}));


const useStyles = makeStyles((theme) => ({
  table: {
    minWidth: 560,
  },
  paper: {
    marginTop: theme.spacing(4),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  form: {
    width: '100%',
    marginTop: theme.spacing(3),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
  root: {
    display: 'flex',
  },
  toolbar: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(3),
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
}));

function Nomina(props) {
  const [permit, setPermit] = React.useState(true);
  const [prestamoAModificar, setPrestamoAModificar] = React.useState('');
  const [messageSnackbar, setMessageSnackbar] = React.useState('');
  const [sucur, setSucur] = React.useState('');
  const [arraySucursales, setArraySucursales] = React.useState([]);
  const [openGeneralPrestamo, openModalGenerarPrestamo] = React.useState(false);
  const [status, setStatus] = React.useState('ACTIVO');
  const [sucursales, setSucursales] = React.useState([]);
  const [sucursarSelect, setSucursalSelected] = React.useState('');
  const [monto, setMonto] = React.useState('');
  const [descuentoSemanal, setDescuentoSemanal] = React.useState('');
  const [excel, setExcel] = React.useState('');
  const [excelSueldos, setExcelSueldos] = React.useState('');
  const [nominaFechas, setNominaFechas] = React.useState('');

  const [nominaVerificar, setNominaVerificar] = React.useState('');
  const [openVerificar, setOpenVerificar] = React.useState(false);

  const [fechaInicioNomina, setFechaInicioNomina] = React.useState('2022-05-01');
  const [fechaFinNomina, setFechaFinNomina] = React.useState('2022-05-01');

  const [fechaInicioNominaValidarSucursal, setFechaInicioNominaValidarSucursal] = React.useState('2022-05-01');
  const [fechaFinNominaValidarSucursal, setFechaFinNominaValidarSucursal] = React.useState('2022-05-01');

  const [fechaInicioValidacionAdmin, setFechaInicioValidacionAdmin] = React.useState('2022-05-01');
  const [fechaFinValidacionAdmin, setFechaFinValidacionAdmin] = React.useState('2022-05-01');

  //verificar Nomina
  const [sueldoSemanal, setSueldoSemanal] = React.useState(0.00);
  const [fonacot, setFonacot] = React.useState(0.00);
  const [inasistencias, setInasistencias] = React.useState(0.00);
  const [omisionesRetardos, setOmisionesRetardos] = React.useState(0.00);
  const [primaVacacional, setPrimaVacacional] = React.useState(0.00);
  const [sueldoSemanalFiscal, setSueldoSemanalFiscal] = React.useState(0.00);
  const [errorGenerarNomina, setErrorGenerarNomina] = React.useState(0.00);
  const [sueldoSemanalRestante, setSueldoSemanalRestante] = React.useState(0.00);
  const [vacaciones, setVacaciones] = React.useState(0.00);
  const [prestamosValue, setPrestamosValue] = React.useState(0.00);

  //penalizaciones
  const [openPenalizacion, setOpenPenalizacion] = React.useState(false);
  const [tipoPenalizacion, setTipoPenalizacion] = React.useState('');
  const [descripcionPenalizacion, setDescripcionPenalizacion] = React.useState('');
  const [descuentoPenalización, setDescuentoPenalizacion] = React.useState('');

  const [penalizacionADescontarTotal, setPenalizacionADescontarTotal] = React.useState(0.00);

  //tab
  const tabClasses = tabStyles();
  const [value, setValue] = React.useState(0)
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  useEffect(() => {
    if (permit) {
      let fecha = new Date();
      const result = fecha.toISOString().split('T')[0]
      props.getNominaFechaAnterior(result);
      props.getNominaFechasSearch(result);
      props.addSesion && props.addSesion.user && props.addSesion.user.admin == false ?
        props.getPrestamos(props.addSesion.empleados.idSucursal)
        :
        props.getSucursales().then(result => {
          if (result && Array.isArray(result)) {
            setArraySucursales(result);
            setPermit(false);
          }
        });
    }
  }, [permit]);

  const handleOpenGenerarNomina = () => {
    openModalGenerarPrestamo(true);
    props.getSucursales().then(result => {
      if (result && Array.isArray(result) && result.length > 0) {
        setSucursales(result);
      } else {
        openModalGenerarPrestamo(false);
      }
    })
  }

  const handleCloseGenerarNomina = () => {
    openModalGenerarPrestamo(false);
  }

  const handleCloseVerificarNomina = () => {
    setOpenVerificar(false);
    setNominaVerificar('');
    setSueldoSemanal(0.00);
    setInasistencias(0.00);
    setOmisionesRetardos(0.00);
    setPrimaVacacional(0.00);
    setSueldoSemanalFiscal(0.00);
    setErrorGenerarNomina(false);
    setVacaciones(0.00);
  }

  const classes = useStyles();

  const openModalEliminarEmpleado = (prestamo) => {
    setPrestamoAModificar(prestamo);
    setStatus("ACTIVO")
  }

  const onBuscarEmpleadosPorSucursal = () => {
    if (!sucur) {
      setMessageSnackbar("La sucursal no puede ir vacía")
    }
    if (!fechaInicioNominaValidarSucursal){
      setMessageSnackbar("Ingresar la fecha inicio")
    }
    if (!fechaFinNominaValidarSucursal){
      setMessageSnackbar("Ingresar la fecha fin")
    }
    props.getNominasInRevision(sucur, fechaInicioNominaValidarSucursal, fechaFinNominaValidarSucursal);
  }

  const onBuscarNominasValidadas = () => {
    if (!sucur) {
      setMessageSnackbar("La sucursal no puede ir vacía")
    }
    if (!fechaInicioValidacionAdmin){
      setMessageSnackbar("Ingresar la fecha inicio")
    }
    if (!fechaFinValidacionAdmin){
      setMessageSnackbar("Ingresar la fecha fin")
    }
    props.getNominasValidadas(sucur, fechaInicioValidacionAdmin, fechaFinValidacionAdmin);
  }

  const handleChangeSucursal = (event) => {
    setSucur(event.target.value);
  }
  const handleChangeNominaFechas = (event) => {
    setNominaFechas(event.target.value);
  }

  const handleChangeSucursalSelected = (event) => {
    setSucursalSelected(event.target.value);
  };

  const calcularRetraso = (minutos) => {
    if (minutos <= 10) {
      return 0;
    } else if (minutos >= 11 && minutos <= 20) {
      return parseInt(minutos) * 4;
    } else {
      return parseInt(minutos) * 8;
    }
  }

  const handleGenerarNomina = async () => {
    console.log(fechaInicioNomina);
    console.log(fechaFinNomina);
    if (!sucursarSelect) {
      setMessageSnackbar("Favor de seleccionar una sucursal");
      return;
    }
    if (!excel) {
      setMessageSnackbar("Favor de ingresar el excel con los horarios");
      return;
    }
    if (!props.nominaFechaAnterior) {
      setMessageSnackbar("Hay un error para generar la nómina, favor de consultar con el administrador");
      return;
    }

    if (!fechaInicioNomina) {
      setMessageSnackbar("Fecha inicio requerida");
      return;
    }

    if (!fechaFinNomina) {
      setMessageSnackbar("Fecha final requerida");
      return;
    }

    const resultEmpleados = await props.getEmpleadosSucursal(sucursarSelect);

    if (resultEmpleados && resultEmpleados.empleados && resultEmpleados.empleados.length > 0) {
      resultEmpleados.empleados.map(item => {
        //console.log(item);
        item.checador = [];
        excel.map(excelItem => {
          let nombreCompletoSeteadoDB;
          //console.log(excelItem);
          nombreCompletoSeteadoDB = item.nombre.trim().toUpperCase() + item.primerApellido.trim().toUpperCase() + item.segundoApellido.trim().toUpperCase();
          let nombreCompletoSeteadoExcel = excelItem.Nombre.toString().trim().toUpperCase();
          //.replace(/ /g, "")
          let nombreSinEspaciosDB = nombreCompletoSeteadoDB.replace(/ /g, "");
          let nombreSinEspaciosExcel = nombreCompletoSeteadoExcel.replace(/ /g, "");
          //console.log(nombreSinEspaciosDB);
          //console.log(nombreSinEspaciosExcel);
          if (item.clave === excelItem.Codigo) {
            item.checador.push(excelItem);
          }
        })
      });
    }
    console.log(resultEmpleados);
    if (resultEmpleados && resultEmpleados.empleados && resultEmpleados.empleados.length > 0) {
      resultEmpleados.empleados.map(item => {
        item.sueldoFiscal = 0;
        excelSueldos.map(excelItem => {
          if (excelItem.nombre && excelItem.sueldo) {
            let nombreCompletoSeteadoDB;
            //console.log(item);
            nombreCompletoSeteadoDB = item.primerApellido.trim().toUpperCase() + item.segundoApellido.trim().toUpperCase() + item.nombre.trim().toUpperCase();
            let nombreCompletoSeteadoExcel = excelItem.nombre.toString().trim().toUpperCase();
            //.replace(/ /g, "")
            let nombreSinEspaciosDB = nombreCompletoSeteadoDB.replace(/ /g, "");

            let nombreSinEspaciosExcel = nombreCompletoSeteadoExcel.replace(/ /g, "");
            //console.log(nombreSinEspaciosDB);
            //console.log(nombreSinEspaciosExcel);
            if (nombreSinEspaciosDB === nombreSinEspaciosExcel) {
              item.sueldoFiscal = parseFloat(excelItem.sueldo);
            }
          }
        })
      });
    }

    let dateArray = [];

    let currentDate = moment(fechaInicioNomina);
    let stopDate = moment(fechaFinNomina);
    while (currentDate <= stopDate) {
      dateArray.push(moment(currentDate).format('DD/MM/YYYY'))
      currentDate = moment(currentDate).add(1, 'days');
    }

    let messages = [];

      // console.log(fechaARecorrer);
      await resultEmpleados.empleados.map(async item => {
        //const fechaARevisar = [];
        console.log(item);

        const intento = [];

        dateArray.map(fechita => {
          let intra = { 
            date: fechita,
            checador: [] 
           }
          intento.push(intra);
        });

        //console.log(item);
        if (item.checador && item.checador.length > 0 && item.horario && item.horario.id) {
          let objetoFechas = [];
          let diaGlobal;
          //console.log(item.checador);
          intento.map(intentito => {
              item.checador.map(itemChecador => {
                //console.log(itemChecador);
                if (itemChecador.Codigo.toString() === item.clave.toString()) {
    
                  const fechaRegistro = itemChecador.fecha;//`${itemChecador.Tiempo.substring(6, 10)}-${itemChecador.Tiempo.substring(3, 5)}-${itemChecador.Tiempo.substring(0, 2)}`;
                  let horaRegistro = itemChecador.hora;//itemChecador.Tiempo.substring(11, 19);
                  let isAmOPm = itemChecador.ampm; //itemChecador.Tiempo.substring(20, 25).trim();
    
                  if (item.horario.horariosRotativos) {
    
                  } else {
                    if (intentito.date === fechaRegistro) {
                      intentito.checador.push(itemChecador);
                    }
                    dateArray.map(fechaARecorrer => {
                    
                    if (fechaARecorrer === fechaRegistro) {
                      //console.log(fechaARecorrer);
                      
                      //fechaARevisar.push(itemChecador);
                    }
                  });
                  }
                }
              });
            
          });


          let ResultadoArrayNomina = {};
          intento.map(fechaInterna => {
            let fechaARevisar = fechaInterna.checador;

            for (var i = 0; i < fechaARevisar.length; i++) {
              let countParaChecar = 0;
              let countTotal = 0;
              let calcularChecadorhoraInicio1;
              let calcularChecadorhoraFin1;
              let calcularChecadorhoraInicio2;
              let calcularChecadorhoraFin2;
              let faltas = 0;
  
              switch (fechaARevisar[i].dia) {
                case 'Monday':

                  if (fechaARevisar[0] && fechaARevisar[0].Estado && fechaARevisar[0].Estado.toUpperCase() === "FALTA") {
                    ResultadoArrayNomina.faltaLunes = true;
                    break;
                  }
  
                  if (item.horario && item.horario.horario) {
                    if (item.horario.horario.lunes) {
                      if (item.horario.horario.lunes.horaInicio1 && fechaARevisar[0]) {
  
                        let horaInicioRevisar = parseInt(item.horario.horario.lunes.horaInicio1.split(':')[0]);
                        let minutoInicioRevisar = parseInt(item.horario.horario.lunes.horaInicio1.split(':')[1]);
                        let horaInicioChecador = parseInt(fechaARevisar[0].hora.split(':')[0]);
                        let minutoInicioChecador = parseInt(fechaARevisar[0].hora.split(':')[1]);
  
                        if (fechaARevisar[0] && fechaARevisar[0].Estado && fechaARevisar[0].Estado.toUpperCase() === "IGNORAR") {
                          calcularChecadorhoraInicio1 = 0;
                        } else if (fechaARevisar[0] && fechaARevisar[0].Estado && fechaARevisar[0].Estado.toUpperCase() === "X") {
                          countTotal++;
                        } else {
                          if (horaInicioChecador == horaInicioRevisar) {
                            if (minutoInicioChecador > minutoInicioRevisar) {
                              calcularChecadorhoraInicio1 = (parseInt(minutoInicioChecador) - parseInt(minutoInicioRevisar));
                            }
                          } else if (horaInicioChecador > horaInicioRevisar) {
                            if ((horaInicioChecador - horaInicioRevisar) >= 1 && ((minutoInicioChecador) > minutoInicioRevisar)) {
                              calcularChecadorhoraInicio1 = (60 * (parseInt(horaInicioChecador) - parseInt(horaInicioRevisar))) + (parseInt(minutoInicioChecador) - parseInt(minutoInicioRevisar))
                            } else {
                              calcularChecadorhoraInicio1 = ((60 * (horaInicioRevisar - horaInicioChecador)) - 60) + ((60 + minutoInicioRevisar) - minutoInicioChecador);
                            }
                          }
                        }
                      }
  
                      ResultadoArrayNomina.lunesRetrasohoraInicio1 = calcularChecadorhoraInicio1 || 0;
  
                      if (item.horario.horario.lunes.horaFin1 && fechaARevisar[1]) {
  
                        let horaInicioRevisar = parseInt(item.horario.horario.lunes.horaFin1.split(':')[0]);
                        let minutoInicioRevisar = parseInt(item.horario.horario.lunes.horaFin1.split(':')[1]);
                        let horaInicioChecador = parseInt(fechaARevisar[1].hora.split(':')[0]);
                        let minutoInicioChecador = parseInt(fechaARevisar[1].hora.split(':')[1]);
  
                        if (fechaARevisar[1] && fechaARevisar[1].Estado && fechaARevisar[1].Estado.toUpperCase() === "IGNORAR") {
                          calcularChecadorhoraFin1 = 0;
                        } else if (fechaARevisar[1] && fechaARevisar[1].Estado && fechaARevisar[1].Estado.toUpperCase() === "X") {
                          countTotal++;
                        } else {
                          if (horaInicioChecador == horaInicioRevisar) {
                            if (minutoInicioChecador < minutoInicioRevisar) {
                              calcularChecadorhoraFin1 = (parseInt(minutoInicioRevisar) - parseInt(minutoInicioChecador));
                            }
                          } else if (horaInicioChecador < horaInicioRevisar) {
                            if ((horaInicioRevisar - horaInicioChecador) >= 1 && (minutoInicioChecador < minutoInicioRevisar)) {
                              calcularChecadorhoraFin1 = (60 * (parseInt(horaInicioRevisar) - parseInt(horaInicioChecador))) + (parseInt(minutoInicioRevisar) - parseInt(minutoInicioChecador))
                            } else {
                              calcularChecadorhoraFin1 = ((60 * (horaInicioRevisar - horaInicioChecador)) - 60) + ((60 + minutoInicioRevisar) - minutoInicioChecador);
                            }
                          }
                        }
                      }
  
                      ResultadoArrayNomina.lunesRetrasohoraFin1 = calcularChecadorhoraFin1 || 0;
  
                      if (item.horario.horario.lunes.horaInicio2 && fechaARevisar[2]) {
  
                        let horaInicioRevisar = parseInt(item.horario.horario.lunes.horaInicio2.split(':')[0]);
                        let minutoInicioRevisar = parseInt(item.horario.horario.lunes.horaInicio2.split(':')[1]);
                        let horaInicioChecador = parseInt(fechaARevisar[2].hora.split(':')[0]);
                        let minutoInicioChecador = parseInt(fechaARevisar[2].hora.split(':')[1]);
  
  
                        if (fechaARevisar[2] && fechaARevisar[2].Estado && fechaARevisar[2].Estado.toUpperCase() === "IGNORAR") {
                          calcularChecadorhoraInicio2 = 0;
                        } else if (fechaARevisar[2] && fechaARevisar[2].Estado && fechaARevisar[2].Estado.toUpperCase() === "X") {
                          countTotal++;
                        } else {
                          if (horaInicioChecador == horaInicioRevisar) {
                            if (minutoInicioChecador > minutoInicioRevisar) {
                              calcularChecadorhoraInicio2 = (parseInt(minutoInicioChecador) - parseInt(minutoInicioRevisar));
                            }
                          } else if (horaInicioChecador > horaInicioRevisar) {
                            if ((horaInicioChecador - horaInicioRevisar) >= 1 && (minutoInicioChecador > minutoInicioRevisar)) {
                              calcularChecadorhoraInicio2 = (60 * (parseInt(horaInicioChecador) - parseInt(horaInicioRevisar))) + (parseInt(minutoInicioChecador) - parseInt(minutoInicioRevisar))
                            } else {
                              calcularChecadorhoraFin1 = ((60 * (horaInicioRevisar - horaInicioChecador)) - 60) + ((60 + minutoInicioRevisar) - minutoInicioChecador);
                            }
                          }
                        }
                      }
  
                      ResultadoArrayNomina.lunesRetrasohoraInicio2 = calcularChecadorhoraInicio2 || 0;
  
                      if (item.horario.horario.lunes.horaFin2 && fechaARevisar[3]) {
  
                        let horaInicioRevisar = parseInt(item.horario.horario.lunes.horaInicio2.split(':')[0]);
                        let minutoInicioRevisar = parseInt(item.horario.horario.lunes.horaInicio2.split(':')[1]);
                        let horaInicioChecador = parseInt(fechaARevisar[3].hora.split(':')[0]);
                        let minutoInicioChecador = parseInt(fechaARevisar[3].hora.split(':')[1]);
  
                        if (fechaARevisar[3] && fechaARevisar[3].Estado && fechaARevisar[3].Estado.toUpperCase() === "IGNORAR") {
                          calcularChecadorhoraFin1 = 0;
                        } else if (fechaARevisar[3] && fechaARevisar[3].Estado && fechaARevisar[3].Estado.toUpperCase() === "X") {
                          countTotal++;
                        } else {
                          if (horaInicioChecador == horaInicioRevisar) {
                            if (minutoInicioChecador < minutoInicioRevisar) {
                              calcularChecadorhoraFin2 = (parseInt(minutoInicioRevisar) - parseInt(minutoInicioChecador));
                            }
                          } else if (horaInicioChecador < horaInicioRevisar) {
                            if ((horaInicioRevisar - horaInicioChecador) >= 1 && (minutoInicioChecador < minutoInicioRevisar)) {
                              calcularChecadorhoraFin2 = (60 * (parseInt(horaInicioRevisar) - parseInt(horaInicioChecador))) + (parseInt(minutoInicioRevisar) - parseInt(minutoInicioChecador))
                            } else {
                              calcularChecadorhoraFin2 = ((60 * (horaInicioRevisar - horaInicioChecador)) - 60) + ((60 + minutoInicioRevisar) - minutoInicioChecador);
                            }
                          }
                        }
                      }
  
                      ResultadoArrayNomina.lunesRetrasohoraFin2 = calcularChecadorhoraFin2 || 0;
  
                    }
                  }
                  ResultadoArrayNomina.lunesOmisiones = countTotal;
  
                  break;
                case 'Tuesday':
                  if (fechaARevisar[0] && fechaARevisar[0].Estado && fechaARevisar[0].Estado.toUpperCase() === "FALTA") {
                    ResultadoArrayNomina.faltaMartes = true;
                    break;
                  }
  
                  if (item.horario && item.horario.horario) {
                    if (item.horario.horario.martes) {
                      if (item.horario.horario.martes.horaInicio1 && fechaARevisar[0]) {
  
                        let horaInicioRevisar = parseInt(item.horario.horario.martes.horaInicio1.split(':')[0]);
                        let minutoInicioRevisar = parseInt(item.horario.horario.martes.horaInicio1.split(':')[1]);
                        let horaInicioChecador = parseInt(fechaARevisar[0].hora.split(':')[0]);
                        let minutoInicioChecador = parseInt(fechaARevisar[0].hora.split(':')[1]);
  
                        if (fechaARevisar[0] && fechaARevisar[0].Estado && fechaARevisar[0].Estado.toUpperCase() === "IGNORAR") {
                          calcularChecadorhoraInicio1 = 0;
                        } else if (fechaARevisar[0] && fechaARevisar[0].Estado && fechaARevisar[0].Estado.toUpperCase() === "X") {
                          countTotal++;
                        } else {
                          if (horaInicioChecador == horaInicioRevisar) {
                            if (minutoInicioChecador > minutoInicioRevisar) {
                              calcularChecadorhoraInicio1 = (parseInt(minutoInicioChecador) - parseInt(minutoInicioRevisar));
                            }
                          } else if (horaInicioChecador > horaInicioRevisar) {
                            if ((horaInicioChecador - horaInicioRevisar) >= 1 && ((minutoInicioChecador) > minutoInicioRevisar)) {
                              calcularChecadorhoraInicio1 = (60 * (parseInt(horaInicioChecador) - parseInt(horaInicioRevisar))) + (parseInt(minutoInicioChecador) - parseInt(minutoInicioRevisar))
                            } else {
                              calcularChecadorhoraInicio1 = ((60 * (horaInicioRevisar - horaInicioChecador)) - 60) + ((60 + minutoInicioRevisar) - minutoInicioChecador);
                            }
                          }
                        }
                      }
  
                      ResultadoArrayNomina.martesRetrasohoraInicio1 = calcularChecadorhoraInicio1 || 0;
  
                      if (item.horario.horario.martes.horaFin1 && fechaARevisar[1]) {
  
                        let horaInicioRevisar = parseInt(item.horario.horario.lunes.horaFin1.split(':')[0]);
                        let minutoInicioRevisar = parseInt(item.horario.horario.lunes.horaFin1.split(':')[1]);
                        let horaInicioChecador = parseInt(fechaARevisar[1].hora.split(':')[0]);
                        let minutoInicioChecador = parseInt(fechaARevisar[1].hora.split(':')[1]);
  
                        if (fechaARevisar[1] && fechaARevisar[1].Estado && fechaARevisar[1].Estado.toUpperCase() === "IGNORAR") {
                          calcularChecadorhoraFin1 = 0;
                        } else if (fechaARevisar[1] && fechaARevisar[1].Estado && fechaARevisar[1].Estado.toUpperCase() === "X") {
                          countTotal++;
                        } else {
                          if (horaInicioChecador == horaInicioRevisar) {
                            if (minutoInicioChecador < minutoInicioRevisar) {
                              calcularChecadorhoraFin1 = (parseInt(minutoInicioRevisar) - parseInt(minutoInicioChecador));
                            }
                          } else if (horaInicioChecador < horaInicioRevisar) {
                            if ((horaInicioRevisar - horaInicioChecador) >= 1 && (minutoInicioChecador < minutoInicioRevisar)) {
                              calcularChecadorhoraFin1 = (60 * (parseInt(horaInicioRevisar) - parseInt(horaInicioChecador))) + (parseInt(minutoInicioRevisar) - parseInt(minutoInicioChecador))
                            } else {
                              calcularChecadorhoraFin1 = ((60 * (horaInicioRevisar - horaInicioChecador)) - 60) + ((60 + minutoInicioRevisar) - minutoInicioChecador);
                            }
                          }
                        }
                      }
  
                      ResultadoArrayNomina.martesRetrasohoraFin1 = calcularChecadorhoraFin1 || 0;
  
                      if (item.horario.horario.martes.horaInicio2 && fechaARevisar[2]) {
  
                        let horaInicioRevisar = parseInt(item.horario.horario.martes.horaInicio2.split(':')[0]);
                        let minutoInicioRevisar = parseInt(item.horario.horario.martes.horaInicio2.split(':')[1]);
                        let horaInicioChecador = parseInt(fechaARevisar[2].hora.split(':')[0]);
                        let minutoInicioChecador = parseInt(fechaARevisar[2].hora.split(':')[1]);
  
  
                        if (fechaARevisar[2] && fechaARevisar[2].Estado && fechaARevisar[2].Estado.toUpperCase() === "IGNORAR") {
                          calcularChecadorhoraInicio2 = 0;
                        } else if (fechaARevisar[2] && fechaARevisar[2].Estado && fechaARevisar[2].Estado.toUpperCase() === "X") {
                          countTotal++;
                        } else {
                          if (horaInicioChecador == horaInicioRevisar) {
                            if (minutoInicioChecador > minutoInicioRevisar) {
                              calcularChecadorhoraInicio2 = (parseInt(minutoInicioChecador) - parseInt(minutoInicioRevisar));
                            }
                          } else if (horaInicioChecador > horaInicioRevisar) {
                            if ((horaInicioChecador - horaInicioRevisar) >= 1 && (minutoInicioChecador > minutoInicioRevisar)) {
                              calcularChecadorhoraInicio2 = (60 * (parseInt(horaInicioChecador) - parseInt(horaInicioRevisar))) + (parseInt(minutoInicioChecador) - parseInt(minutoInicioRevisar))
                            } else {
                              calcularChecadorhoraFin1 = ((60 * (horaInicioRevisar - horaInicioChecador)) - 60) + ((60 + minutoInicioRevisar) - minutoInicioChecador);
                            }
                          }
                        }
                      }
  
                      ResultadoArrayNomina.martesRetrasohoraInicio2 = calcularChecadorhoraInicio2 || 0;
  
                      if (item.horario.horario.martes.horaFin2 && fechaARevisar[3]) {
  
                        let horaInicioRevisar = parseInt(item.horario.horario.martes.horaInicio2.split(':')[0]);
                        let minutoInicioRevisar = parseInt(item.horario.horario.martes.horaInicio2.split(':')[1]);
                        let horaInicioChecador = parseInt(fechaARevisar[3].hora.split(':')[0]);
                        let minutoInicioChecador = parseInt(fechaARevisar[3].hora.split(':')[1]);
  
                        if (fechaARevisar[3] && fechaARevisar[3].Estado && fechaARevisar[3].Estado.toUpperCase() === "IGNORAR") {
                          calcularChecadorhoraFin1 = 0;
                        } else if (fechaARevisar[3] && fechaARevisar[3].Estado && fechaARevisar[3].Estado.toUpperCase() === "X") {
                          countTotal++;
                        } else {
                          if (horaInicioChecador == horaInicioRevisar) {
                            if (minutoInicioChecador < minutoInicioRevisar) {
                              calcularChecadorhoraFin2 = (parseInt(minutoInicioRevisar) - parseInt(minutoInicioChecador));
                            }
                          } else if (horaInicioChecador < horaInicioRevisar) {
                            if ((horaInicioRevisar - horaInicioChecador) >= 1 && (minutoInicioChecador < minutoInicioRevisar)) {
                              calcularChecadorhoraFin2 = (60 * (parseInt(horaInicioRevisar) - parseInt(horaInicioChecador))) + (parseInt(minutoInicioRevisar) - parseInt(minutoInicioChecador))
                            } else {
                              calcularChecadorhoraFin2 = ((60 * (horaInicioRevisar - horaInicioChecador)) - 60) + ((60 + minutoInicioRevisar) - minutoInicioChecador);
                            }
                          }
                        }
                      }
  
                      ResultadoArrayNomina.martesRetrasohoraFin2 = calcularChecadorhoraFin2 || 0;
  
                    }
                  }
                  ResultadoArrayNomina.martesOmisiones = countTotal;
  
  
                  break;
                case 'Wednesday':
  
                  if (fechaARevisar[0] && fechaARevisar[0].Estado && fechaARevisar[0].Estado.toUpperCase() === "FALTA") {
                    ResultadoArrayNomina.faltaMiercoles = true;
                    break;
                  }
  
                  if (item.horario && item.horario.horario) {
                    if (item.horario.horario.miercoles) {
                      if (item.horario.horario.miercoles.horaInicio1 && fechaARevisar[0]) {
  
                        let horaInicioRevisar = parseInt(item.horario.horario.miercoles.horaInicio1.split(':')[0]);
                        let minutoInicioRevisar = parseInt(item.horario.horario.miercoles.horaInicio1.split(':')[1]);
                        let horaInicioChecador = parseInt(fechaARevisar[0].hora.split(':')[0]);
                        let minutoInicioChecador = parseInt(fechaARevisar[0].hora.split(':')[1]);
  
                        if (fechaARevisar[0] && fechaARevisar[0].Estado && fechaARevisar[0].Estado.toUpperCase() === "IGNORAR") {
                          calcularChecadorhoraInicio1 = 0;
                        } else if (fechaARevisar[0] && fechaARevisar[0].Estado && fechaARevisar[0].Estado.toUpperCase() === "X") {
                          countTotal++;
                        } else {
                          if (horaInicioChecador == horaInicioRevisar) {
                            if (minutoInicioChecador > minutoInicioRevisar) {
                              calcularChecadorhoraInicio1 = (parseInt(minutoInicioChecador) - parseInt(minutoInicioRevisar));
                            }
                          } else if (horaInicioChecador > horaInicioRevisar) {
                            if ((horaInicioChecador - horaInicioRevisar) >= 1 && ((minutoInicioChecador) > minutoInicioRevisar)) {
                              calcularChecadorhoraInicio1 = (60 * (parseInt(horaInicioChecador) - parseInt(horaInicioRevisar))) + (parseInt(minutoInicioChecador) - parseInt(minutoInicioRevisar))
                            } else {
                              calcularChecadorhoraInicio1 = ((60 * (horaInicioRevisar - horaInicioChecador)) - 60) + ((60 + minutoInicioRevisar) - minutoInicioChecador);
                            }
                          }
                        }
                      }
  
                      ResultadoArrayNomina.miercolesRetrasohoraInicio1 = calcularChecadorhoraInicio1 || 0;
  
                      if (item.horario.horario.miercoles.horaFin1 && fechaARevisar[1]) {
  
                        let horaInicioRevisar = parseInt(item.horario.horario.miercoles.horaFin1.split(':')[0]);
                        let minutoInicioRevisar = parseInt(item.horario.horario.miercoles.horaFin1.split(':')[1]);
                        let horaInicioChecador = parseInt(fechaARevisar[1].hora.split(':')[0]);
                        let minutoInicioChecador = parseInt(fechaARevisar[1].hora.split(':')[1]);
  
                        if (fechaARevisar[1] && fechaARevisar[1].Estado && fechaARevisar[1].Estado.toUpperCase() === "IGNORAR") {
                          calcularChecadorhoraFin1 = 0;
                        } else if (fechaARevisar[1] && fechaARevisar[1].Estado && fechaARevisar[1].Estado.toUpperCase() === "X") {
                          countTotal++;
                        } else {
                          if (horaInicioChecador == horaInicioRevisar) {
                            if (minutoInicioChecador < minutoInicioRevisar) {
                              calcularChecadorhoraFin1 = (parseInt(minutoInicioRevisar) - parseInt(minutoInicioChecador));
                            }
                          } else if (horaInicioChecador < horaInicioRevisar) {
                            if ((horaInicioRevisar - horaInicioChecador) >= 1 && (minutoInicioChecador < minutoInicioRevisar)) {
                              calcularChecadorhoraFin1 = (60 * (parseInt(horaInicioRevisar) - parseInt(horaInicioChecador))) + (parseInt(minutoInicioRevisar) - parseInt(minutoInicioChecador))
                            } else {
                              calcularChecadorhoraFin1 = ((60 * (horaInicioRevisar - horaInicioChecador)) - 60) + ((60 + minutoInicioRevisar) - minutoInicioChecador);
                            }
                          }
                        }
                      }
  
                      ResultadoArrayNomina.miercolesRetrasohoraFin1 = calcularChecadorhoraFin1 || 0;
  
                      if (item.horario.horario.miercoles.horaInicio2 && fechaARevisar[2]) {
  
                        let horaInicioRevisar = parseInt(item.horario.horario.miercoles.horaInicio2.split(':')[0]);
                        let minutoInicioRevisar = parseInt(item.horario.horario.miercoles.horaInicio2.split(':')[1]);
                        let horaInicioChecador = parseInt(fechaARevisar[2].hora.split(':')[0]);
                        let minutoInicioChecador = parseInt(fechaARevisar[2].hora.split(':')[1]);
  
  
                        if (fechaARevisar[2] && fechaARevisar[2].Estado && fechaARevisar[2].Estado.toUpperCase() === "IGNORAR") {
                          calcularChecadorhoraInicio2 = 0;
                        } else if (fechaARevisar[2] && fechaARevisar[2].Estado && fechaARevisar[2].Estado.toUpperCase() === "X") {
                          countTotal++;
                        } else {
                          if (horaInicioChecador == horaInicioRevisar) {
                            if (minutoInicioChecador > minutoInicioRevisar) {
                              calcularChecadorhoraInicio2 = (parseInt(minutoInicioChecador) - parseInt(minutoInicioRevisar));
                            }
                          } else if (horaInicioChecador > horaInicioRevisar) {
                            if ((horaInicioChecador - horaInicioRevisar) >= 1 && (minutoInicioChecador > minutoInicioRevisar)) {
                              calcularChecadorhoraInicio2 = (60 * (parseInt(horaInicioChecador) - parseInt(horaInicioRevisar))) + (parseInt(minutoInicioChecador) - parseInt(minutoInicioRevisar))
                            } else {
                              calcularChecadorhoraFin1 = ((60 * (horaInicioRevisar - horaInicioChecador)) - 60) + ((60 + minutoInicioRevisar) - minutoInicioChecador);
                            }
                          }
                        }
                      }
  
                      ResultadoArrayNomina.miercolesRetrasohoraInicio2 = calcularChecadorhoraInicio2 || 0;
  
                      if (item.horario.horario.miercoles.horaFin2 && fechaARevisar[3]) {
  
                        let horaInicioRevisar = parseInt(item.horario.horario.miercoles.horaInicio2.split(':')[0]);
                        let minutoInicioRevisar = parseInt(item.horario.horario.miercoles.horaInicio2.split(':')[1]);
                        let horaInicioChecador = parseInt(fechaARevisar[3].hora.split(':')[0]);
                        let minutoInicioChecador = parseInt(fechaARevisar[3].hora.split(':')[1]);
  
                        if (fechaARevisar[3] && fechaARevisar[3].Estado && fechaARevisar[3].Estado.toUpperCase() === "IGNORAR") {
                          calcularChecadorhoraFin1 = 0;
                        } else if (fechaARevisar[3] && fechaARevisar[3].Estado && fechaARevisar[3].Estado.toUpperCase() === "X") {
                          countTotal++;
                        } else {
                          if (horaInicioChecador == horaInicioRevisar) {
                            if (minutoInicioChecador < minutoInicioRevisar) {
                              calcularChecadorhoraFin2 = (parseInt(minutoInicioRevisar) - parseInt(minutoInicioChecador));
                            }
                          } else if (horaInicioChecador < horaInicioRevisar) {
                            if ((horaInicioRevisar - horaInicioChecador) >= 1 && (minutoInicioChecador < minutoInicioRevisar)) {
                              calcularChecadorhoraFin2 = (60 * (parseInt(horaInicioRevisar) - parseInt(horaInicioChecador))) + (parseInt(minutoInicioRevisar) - parseInt(minutoInicioChecador))
                            } else {
                              calcularChecadorhoraFin2 = ((60 * (horaInicioRevisar - horaInicioChecador)) - 60) + ((60 + minutoInicioRevisar) - minutoInicioChecador);
                            }
                          }
                        }
                      }
  
                      ResultadoArrayNomina.miercolesRetrasohoraFin2 = calcularChecadorhoraFin2 || 0;
  
                    }
                  }
                  ResultadoArrayNomina.miercolesOmisiones = countTotal;
  
                  break;
                case 'Thursday':
  
                  if (fechaARevisar[0] && fechaARevisar[0].Estado && fechaARevisar[0].Estado.toUpperCase() === "FALTA") {
                    ResultadoArrayNomina.faltaJueves = true;
                    break;
                  }
  
                  if (item.horario && item.horario.horario) {
                    if (item.horario.horario.jueves) {
                      if (item.horario.horario.jueves.horaInicio1 && fechaARevisar[0]) {
  
                        let horaInicioRevisar = parseInt(item.horario.horario.jueves.horaInicio1.split(':')[0]);
                        let minutoInicioRevisar = parseInt(item.horario.horario.jueves.horaInicio1.split(':')[1]);
                        let horaInicioChecador = parseInt(fechaARevisar[0].hora.split(':')[0]);
                        let minutoInicioChecador = parseInt(fechaARevisar[0].hora.split(':')[1]);
  
                        if (fechaARevisar[0] && fechaARevisar[0].Estado && fechaARevisar[0].Estado.toUpperCase() === "IGNORAR") {
                          calcularChecadorhoraInicio1 = 0;
                        } else if (fechaARevisar[0] && fechaARevisar[0].Estado && fechaARevisar[0].Estado.toUpperCase() === "X") {
                          countTotal++;
                        } else {
                          if (horaInicioChecador == horaInicioRevisar) {
                            if (minutoInicioChecador > minutoInicioRevisar) {
                              calcularChecadorhoraInicio1 = (parseInt(minutoInicioChecador) - parseInt(minutoInicioRevisar));
                            }
                          } else if (horaInicioChecador > horaInicioRevisar) {
                            if ((horaInicioChecador - horaInicioRevisar) >= 1 && ((minutoInicioChecador) > minutoInicioRevisar)) {
                              calcularChecadorhoraInicio1 = (60 * (parseInt(horaInicioChecador) - parseInt(horaInicioRevisar))) + (parseInt(minutoInicioChecador) - parseInt(minutoInicioRevisar))
                            } else {
                              calcularChecadorhoraInicio1 = ((60 * (horaInicioRevisar - horaInicioChecador)) - 60) + ((60 + minutoInicioRevisar) - minutoInicioChecador);
                            }
                          }
                        }
                      }
  
                      ResultadoArrayNomina.juevesRetrasohoraInicio1 = calcularChecadorhoraInicio1 || 0;
  
                      if (item.horario.horario.jueves.horaFin1 && fechaARevisar[1]) {
  
                        let horaInicioRevisar = parseInt(item.horario.horario.jueves.horaFin1.split(':')[0]);
                        let minutoInicioRevisar = parseInt(item.horario.horario.jueves.horaFin1.split(':')[1]);
                        let horaInicioChecador = parseInt(fechaARevisar[1].hora.split(':')[0]);
                        let minutoInicioChecador = parseInt(fechaARevisar[1].hora.split(':')[1]);
  
                        if (fechaARevisar[1] && fechaARevisar[1].Estado && fechaARevisar[1].Estado.toUpperCase() === "IGNORAR") {
                          calcularChecadorhoraFin1 = 0;
                        } else if (fechaARevisar[1] && fechaARevisar[1].Estado && fechaARevisar[1].Estado.toUpperCase() === "X") {
                          countTotal++;
                        } else {
                          if (horaInicioChecador == horaInicioRevisar) {
                            if (minutoInicioChecador < minutoInicioRevisar) {
                              calcularChecadorhoraFin1 = (parseInt(minutoInicioRevisar) - parseInt(minutoInicioChecador));
                            }
                          } else if (horaInicioChecador < horaInicioRevisar) {
                            if ((horaInicioRevisar - horaInicioChecador) >= 1 && (minutoInicioChecador < minutoInicioRevisar)) {
                              calcularChecadorhoraFin1 = (60 * (parseInt(horaInicioRevisar) - parseInt(horaInicioChecador))) + (parseInt(minutoInicioRevisar) - parseInt(minutoInicioChecador))
                            } else {
                              calcularChecadorhoraFin1 = ((60 * (horaInicioRevisar - horaInicioChecador)) - 60) + ((60 + minutoInicioRevisar) - minutoInicioChecador);
                            }
                          }
                        }
                      }
  
                      ResultadoArrayNomina.juevesRetrasohoraFin1 = calcularChecadorhoraFin1 || 0;
  
                      if (item.horario.horario.jueves.horaInicio2 && fechaARevisar[2]) {
  
                        let horaInicioRevisar = parseInt(item.horario.horario.jueves.horaInicio2.split(':')[0]);
                        let minutoInicioRevisar = parseInt(item.horario.horario.jueves.horaInicio2.split(':')[1]);
                        let horaInicioChecador = parseInt(fechaARevisar[2].hora.split(':')[0]);
                        let minutoInicioChecador = parseInt(fechaARevisar[2].hora.split(':')[1]);
  
  
                        if (fechaARevisar[2] && fechaARevisar[2].Estado && fechaARevisar[2].Estado.toUpperCase() === "IGNORAR") {
                          calcularChecadorhoraInicio2 = 0;
                        } else if (fechaARevisar[2] && fechaARevisar[2].Estado && fechaARevisar[2].Estado.toUpperCase() === "X") {
                          countTotal++;
                        } else {
                          if (horaInicioChecador == horaInicioRevisar) {
                            if (minutoInicioChecador > minutoInicioRevisar) {
                              calcularChecadorhoraInicio2 = (parseInt(minutoInicioChecador) - parseInt(minutoInicioRevisar));
                            }
                          } else if (horaInicioChecador > horaInicioRevisar) {
                            if ((horaInicioChecador - horaInicioRevisar) >= 1 && (minutoInicioChecador > minutoInicioRevisar)) {
                              calcularChecadorhoraInicio2 = (60 * (parseInt(horaInicioChecador) - parseInt(horaInicioRevisar))) + (parseInt(minutoInicioChecador) - parseInt(minutoInicioRevisar))
                            } else {
                              calcularChecadorhoraFin1 = ((60 * (horaInicioRevisar - horaInicioChecador)) - 60) + ((60 + minutoInicioRevisar) - minutoInicioChecador);
                            }
                          }
                        }
                      }
  
                      ResultadoArrayNomina.juevesRetrasohoraInicio2 = calcularChecadorhoraInicio2 || 0;
  
                      if (item.horario.horario.jueves.horaFin2 && fechaARevisar[3]) {
  
                        let horaInicioRevisar = parseInt(item.horario.horario.jueves.horaInicio2.split(':')[0]);
                        let minutoInicioRevisar = parseInt(item.horario.horario.jueves.horaInicio2.split(':')[1]);
                        let horaInicioChecador = parseInt(fechaARevisar[3].hora.split(':')[0]);
                        let minutoInicioChecador = parseInt(fechaARevisar[3].hora.split(':')[1]);
  
                        if (fechaARevisar[3] && fechaARevisar[3].Estado && fechaARevisar[3].Estado.toUpperCase() === "IGNORAR") {
                          calcularChecadorhoraFin1 = 0;
                        } else if (fechaARevisar[3] && fechaARevisar[3].Estado && fechaARevisar[3].Estado.toUpperCase() === "X") {
                          countTotal++;
                        } else {
                          if (horaInicioChecador == horaInicioRevisar) {
                            if (minutoInicioChecador < minutoInicioRevisar) {
                              calcularChecadorhoraFin2 = (parseInt(minutoInicioRevisar) - parseInt(minutoInicioChecador));
                            }
                          } else if (horaInicioChecador < horaInicioRevisar) {
                            if ((horaInicioRevisar - horaInicioChecador) >= 1 && (minutoInicioChecador < minutoInicioRevisar)) {
                              calcularChecadorhoraFin2 = (60 * (parseInt(horaInicioRevisar) - parseInt(horaInicioChecador))) + (parseInt(minutoInicioRevisar) - parseInt(minutoInicioChecador))
                            } else {
                              calcularChecadorhoraFin2 = ((60 * (horaInicioRevisar - horaInicioChecador)) - 60) + ((60 + minutoInicioRevisar) - minutoInicioChecador);
                            }
                          }
                        }
                      }
  
                      ResultadoArrayNomina.juevesRetrasohoraFin2 = calcularChecadorhoraFin2 || 0;
  
                    }
                  }
                  ResultadoArrayNomina.juevesOmisiones = countTotal;
  
                  break;
                case 'Friday':
  
                  if (fechaARevisar[0] && fechaARevisar[0].Estado && fechaARevisar[0].Estado.toUpperCase() === "FALTA") {
                    ResultadoArrayNomina.faltaViernes = true;
                    break;
                  }
  
                  if (item.horario && item.horario.horario) {
                    if (item.horario.horario.viernes) {
                      if (item.horario.horario.viernes.horaInicio1 && fechaARevisar[0]) {
  
                        let horaInicioRevisar = parseInt(item.horario.horario.viernes.horaInicio1.split(':')[0]);
                        let minutoInicioRevisar = parseInt(item.horario.horario.viernes.horaInicio1.split(':')[1]);
                        let horaInicioChecador = parseInt(fechaARevisar[0].hora.split(':')[0]);
                        let minutoInicioChecador = parseInt(fechaARevisar[0].hora.split(':')[1]);
  
                        if (fechaARevisar[0] && fechaARevisar[0].Estado && fechaARevisar[0].Estado.toUpperCase() === "IGNORAR") {
                          calcularChecadorhoraInicio1 = 0;
                        } else if (fechaARevisar[0] && fechaARevisar[0].Estado && fechaARevisar[0].Estado.toUpperCase() === "X") {
                          countTotal++;
                        } else {
                          if (horaInicioChecador == horaInicioRevisar) {
                            if (minutoInicioChecador > minutoInicioRevisar) {
                              calcularChecadorhoraInicio1 = (parseInt(minutoInicioChecador) - parseInt(minutoInicioRevisar));
                            }
                          } else if (horaInicioChecador > horaInicioRevisar) {
                            if ((horaInicioChecador - horaInicioRevisar) >= 1 && ((minutoInicioChecador) > minutoInicioRevisar)) {
                              calcularChecadorhoraInicio1 = (60 * (parseInt(horaInicioChecador) - parseInt(horaInicioRevisar))) + (parseInt(minutoInicioChecador) - parseInt(minutoInicioRevisar))
                            } else {
                              calcularChecadorhoraInicio1 = ((60 * (horaInicioRevisar - horaInicioChecador)) - 60) + ((60 + minutoInicioRevisar) - minutoInicioChecador);
                            }
                          }
                        }
                      }
  
                      ResultadoArrayNomina.viernesRetrasohoraInicio1 = calcularChecadorhoraInicio1 || 0;
  
                      if (item.horario.horario.viernes.horaFin1 && fechaARevisar[1]) {
  
                        let horaInicioRevisar = parseInt(item.horario.horario.viernes.horaFin1.split(':')[0]);
                        let minutoInicioRevisar = parseInt(item.horario.horario.viernes.horaFin1.split(':')[1]);
                        let horaInicioChecador = parseInt(fechaARevisar[1].hora.split(':')[0]);
                        let minutoInicioChecador = parseInt(fechaARevisar[1].hora.split(':')[1]);
  
                        if (fechaARevisar[1] && fechaARevisar[1].Estado && fechaARevisar[1].Estado.toUpperCase() === "IGNORAR") {
                          calcularChecadorhoraFin1 = 0;
                        } else if (fechaARevisar[1] && fechaARevisar[1].Estado && fechaARevisar[1].Estado.toUpperCase() === "X") {
                          countTotal++;
                        } else {
                          if (horaInicioChecador == horaInicioRevisar) {
                            if (minutoInicioChecador < minutoInicioRevisar) {
                              calcularChecadorhoraFin1 = (parseInt(minutoInicioRevisar) - parseInt(minutoInicioChecador));
                            }
                          } else if (horaInicioChecador < horaInicioRevisar) {
                            if ((horaInicioRevisar - horaInicioChecador) >= 1 && (minutoInicioChecador < minutoInicioRevisar)) {
                              calcularChecadorhoraFin1 = (60 * (parseInt(horaInicioRevisar) - parseInt(horaInicioChecador))) + (parseInt(minutoInicioRevisar) - parseInt(minutoInicioChecador))
                            } else {
                              calcularChecadorhoraFin1 = ((60 * (horaInicioRevisar - horaInicioChecador)) - 60) + ((60 + minutoInicioRevisar) - minutoInicioChecador);
                            }
                          }
                        }
                      }
  
                      ResultadoArrayNomina.viernesRetrasohoraFin1 = calcularChecadorhoraFin1 || 0;
  
                      if (item.horario.horario.viernes.horaInicio2 && fechaARevisar[2]) {
  
                        let horaInicioRevisar = parseInt(item.horario.horario.viernes.horaInicio2.split(':')[0]);
                        let minutoInicioRevisar = parseInt(item.horario.horario.viernes.horaInicio2.split(':')[1]);
                        let horaInicioChecador = parseInt(fechaARevisar[2].hora.split(':')[0]);
                        let minutoInicioChecador = parseInt(fechaARevisar[2].hora.split(':')[1]);
  
  
                        if (fechaARevisar[2] && fechaARevisar[2].Estado && fechaARevisar[2].Estado.toUpperCase() === "IGNORAR") {
                          calcularChecadorhoraInicio2 = 0;
                        } else if (fechaARevisar[2] && fechaARevisar[2].Estado && fechaARevisar[2].Estado.toUpperCase() === "X") {
                          countTotal++;
                        } else {
                          if (horaInicioChecador == horaInicioRevisar) {
                            if (minutoInicioChecador > minutoInicioRevisar) {
                              calcularChecadorhoraInicio2 = (parseInt(minutoInicioChecador) - parseInt(minutoInicioRevisar));
                            }
                          } else if (horaInicioChecador > horaInicioRevisar) {
                            if ((horaInicioChecador - horaInicioRevisar) >= 1 && (minutoInicioChecador > minutoInicioRevisar)) {
                              calcularChecadorhoraInicio2 = (60 * (parseInt(horaInicioChecador) - parseInt(horaInicioRevisar))) + (parseInt(minutoInicioChecador) - parseInt(minutoInicioRevisar))
                            } else {
                              calcularChecadorhoraFin1 = ((60 * (horaInicioRevisar - horaInicioChecador)) - 60) + ((60 + minutoInicioRevisar) - minutoInicioChecador);
                            }
                          }
                        }
                      }
  
                      ResultadoArrayNomina.viernesRetrasohoraInicio2 = calcularChecadorhoraInicio2 || 0;
  
                      if (item.horario.horario.viernes.horaFin2 && fechaARevisar[3]) {
  
                        let horaInicioRevisar = parseInt(item.horario.horario.viernes.horaInicio2.split(':')[0]);
                        let minutoInicioRevisar = parseInt(item.horario.horario.viernes.horaInicio2.split(':')[1]);
                        let horaInicioChecador = parseInt(fechaARevisar[3].hora.split(':')[0]);
                        let minutoInicioChecador = parseInt(fechaARevisar[3].hora.split(':')[1]);
  
                        if (fechaARevisar[3] && fechaARevisar[3].Estado && fechaARevisar[3].Estado.toUpperCase() === "IGNORAR") {
                          calcularChecadorhoraFin1 = 0;
                        } else if (fechaARevisar[3] && fechaARevisar[3].Estado && fechaARevisar[3].Estado.toUpperCase() === "X") {
                          countTotal++;
                        } else {
                          if (horaInicioChecador == horaInicioRevisar) {
                            if (minutoInicioChecador < minutoInicioRevisar) {
                              calcularChecadorhoraFin2 = (parseInt(minutoInicioRevisar) - parseInt(minutoInicioChecador));
                            }
                          } else if (horaInicioChecador < horaInicioRevisar) {
                            if ((horaInicioRevisar - horaInicioChecador) >= 1 && (minutoInicioChecador < minutoInicioRevisar)) {
                              calcularChecadorhoraFin2 = (60 * (parseInt(horaInicioRevisar) - parseInt(horaInicioChecador))) + (parseInt(minutoInicioRevisar) - parseInt(minutoInicioChecador))
                            } else {
                              calcularChecadorhoraFin2 = ((60 * (horaInicioRevisar - horaInicioChecador)) - 60) + ((60 + minutoInicioRevisar) - minutoInicioChecador);
                            }
                          }
                        }
                      }
  
                      ResultadoArrayNomina.viernesRetrasohoraFin2 = calcularChecadorhoraFin2 || 0;
  
                    }
                  }
                  ResultadoArrayNomina.viernesOmisiones = countTotal;
  
                  break;
                case 'Saturday':
  
                  if (fechaARevisar[0] && fechaARevisar[0].Estado && fechaARevisar[0].Estado.toUpperCase() === "FALTA") {
                    ResultadoArrayNomina.faltaSabado = true;
                    break;
                  }
  
                  if (item.horario && item.horario.horario) {
                    if (item.horario.horario.sabado) {
                      if (item.horario.horario.sabado.horaInicio1 && fechaARevisar[0]) {
  
                        let horaInicioRevisar = parseInt(item.horario.horario.sabado.horaInicio1.split(':')[0]);
                        let minutoInicioRevisar = parseInt(item.horario.horario.sabado.horaInicio1.split(':')[1]);
                        let horaInicioChecador = parseInt(fechaARevisar[0].hora.split(':')[0]);
                        let minutoInicioChecador = parseInt(fechaARevisar[0].hora.split(':')[1]);
  
                        if (fechaARevisar[0] && fechaARevisar[0].Estado && fechaARevisar[0].Estado.toUpperCase() === "IGNORAR") {
                          calcularChecadorhoraInicio1 = 0;
                        } else if (fechaARevisar[0] && fechaARevisar[0].Estado && fechaARevisar[0].Estado.toUpperCase() === "X") {
                          countTotal++;
                        } else {
                          if (horaInicioChecador == horaInicioRevisar) {
                            if (minutoInicioChecador > minutoInicioRevisar) {
                              calcularChecadorhoraInicio1 = (parseInt(minutoInicioChecador) - parseInt(minutoInicioRevisar));
                            }
                          } else if (horaInicioChecador > horaInicioRevisar) {
                            if ((horaInicioChecador - horaInicioRevisar) >= 1 && ((minutoInicioChecador) > minutoInicioRevisar)) {
                              calcularChecadorhoraInicio1 = (60 * (parseInt(horaInicioChecador) - parseInt(horaInicioRevisar))) + (parseInt(minutoInicioChecador) - parseInt(minutoInicioRevisar))
                            } else {
                              calcularChecadorhoraInicio1 = ((60 * (horaInicioRevisar - horaInicioChecador)) - 60) + ((60 + minutoInicioRevisar) - minutoInicioChecador);
                            }
                          }
                        }
                      }
  
                      ResultadoArrayNomina.sabadoRetrasohoraInicio1 = calcularChecadorhoraInicio1 || 0;
  
                      if (item.horario.horario.sabado.horaFin1 && fechaARevisar[1]) {
  
                        let horaInicioRevisar = parseInt(item.horario.horario.sabado.horaFin1.split(':')[0]);
                        let minutoInicioRevisar = parseInt(item.horario.horario.sabado.horaFin1.split(':')[1]);
                        let horaInicioChecador = parseInt(fechaARevisar[1].hora.split(':')[0]);
                        let minutoInicioChecador = parseInt(fechaARevisar[1].hora.split(':')[1]);
  
                        if (fechaARevisar[1] && fechaARevisar[1].Estado && fechaARevisar[1].Estado.toUpperCase() === "IGNORAR") {
                          calcularChecadorhoraFin1 = 0;
                        } else if (fechaARevisar[1] && fechaARevisar[1].Estado && fechaARevisar[1].Estado.toUpperCase() === "X") {
                          countTotal++;
                        } else {
                          if (horaInicioChecador == horaInicioRevisar) {
                            if (minutoInicioChecador < minutoInicioRevisar) {
                              calcularChecadorhoraFin1 = (parseInt(minutoInicioRevisar) - parseInt(minutoInicioChecador));
                            }
                          } else if (horaInicioChecador < horaInicioRevisar) {
                            if ((horaInicioRevisar - horaInicioChecador) >= 1 && (minutoInicioChecador < minutoInicioRevisar)) {
                              calcularChecadorhoraFin1 = (60 * (parseInt(horaInicioRevisar) - parseInt(horaInicioChecador))) + (parseInt(minutoInicioRevisar) - parseInt(minutoInicioChecador))
                            } else {
                              calcularChecadorhoraFin1 = ((60 * (horaInicioRevisar - horaInicioChecador)) - 60) + ((60 + minutoInicioRevisar) - minutoInicioChecador);
                            }
                          }
                        }
                      }
  
                      ResultadoArrayNomina.sabadoRetrasohoraFin1 = calcularChecadorhoraFin1 || 0;
  
                      if (item.horario.horario.sabado.horaInicio2 && fechaARevisar[2]) {
  
                        let horaInicioRevisar = parseInt(item.horario.horario.sabado.horaInicio2.split(':')[0]);
                        let minutoInicioRevisar = parseInt(item.horario.horario.sabado.horaInicio2.split(':')[1]);
                        let horaInicioChecador = parseInt(fechaARevisar[2].hora.split(':')[0]);
                        let minutoInicioChecador = parseInt(fechaARevisar[2].hora.split(':')[1]);
  
  
                        if (fechaARevisar[2] && fechaARevisar[2].Estado && fechaARevisar[2].Estado.toUpperCase() === "IGNORAR") {
                          calcularChecadorhoraInicio2 = 0;
                        } else if (fechaARevisar[2] && fechaARevisar[2].Estado && fechaARevisar[2].Estado.toUpperCase() === "X") {
                          countTotal++;
                        } else {
                          if (horaInicioChecador == horaInicioRevisar) {
                            if (minutoInicioChecador > minutoInicioRevisar) {
                              calcularChecadorhoraInicio2 = (parseInt(minutoInicioChecador) - parseInt(minutoInicioRevisar));
                            }
                          } else if (horaInicioChecador > horaInicioRevisar) {
                            if ((horaInicioChecador - horaInicioRevisar) >= 1 && (minutoInicioChecador > minutoInicioRevisar)) {
                              calcularChecadorhoraInicio2 = (60 * (parseInt(horaInicioChecador) - parseInt(horaInicioRevisar))) + (parseInt(minutoInicioChecador) - parseInt(minutoInicioRevisar))
                            } else {
                              calcularChecadorhoraFin1 = ((60 * (horaInicioRevisar - horaInicioChecador)) - 60) + ((60 + minutoInicioRevisar) - minutoInicioChecador);
                            }
                          }
                        }
                      }
  
                      ResultadoArrayNomina.sabadoRetrasohoraInicio2 = calcularChecadorhoraInicio2 || 0;
  
                      if (item.horario.horario.sabado.horaFin2 && fechaARevisar[3]) {
  
                        let horaInicioRevisar = parseInt(item.horario.horario.sabado.horaInicio2.split(':')[0]);
                        let minutoInicioRevisar = parseInt(item.horario.horario.sabado.horaInicio2.split(':')[1]);
                        let horaInicioChecador = parseInt(fechaARevisar[3].hora.split(':')[0]);
                        let minutoInicioChecador = parseInt(fechaARevisar[3].hora.split(':')[1]);
  
                        if (fechaARevisar[3] && fechaARevisar[3].Estado && fechaARevisar[3].Estado.toUpperCase() === "IGNORAR") {
                          calcularChecadorhoraFin1 = 0;
                        } else if (fechaARevisar[3] && fechaARevisar[3].Estado && fechaARevisar[3].Estado.toUpperCase() === "X") {
                          countTotal++;
                        } else {
                          if (horaInicioChecador == horaInicioRevisar) {
                            if (minutoInicioChecador < minutoInicioRevisar) {
                              calcularChecadorhoraFin2 = (parseInt(minutoInicioRevisar) - parseInt(minutoInicioChecador));
                            }
                          } else if (horaInicioChecador < horaInicioRevisar) {
                            if ((horaInicioRevisar - horaInicioChecador) >= 1 && (minutoInicioChecador < minutoInicioRevisar)) {
                              calcularChecadorhoraFin2 = (60 * (parseInt(horaInicioRevisar) - parseInt(horaInicioChecador))) + (parseInt(minutoInicioRevisar) - parseInt(minutoInicioChecador))
                            } else {
                              calcularChecadorhoraFin2 = ((60 * (horaInicioRevisar - horaInicioChecador)) - 60) + ((60 + minutoInicioRevisar) - minutoInicioChecador);
                            }
                          }
                        }
                      }
  
                      ResultadoArrayNomina.sabadoRetrasohoraFin2 = calcularChecadorhoraFin2 || 0;
  
                    }
                  }
                  ResultadoArrayNomina.sabadoOmisiones = countTotal;
  
                  break;
                case 'Sunday':
  
                  if (fechaARevisar[0] && fechaARevisar[0].Estado && fechaARevisar[0].Estado.toUpperCase() === "FALTA") {
                    ResultadoArrayNomina.faltaDomingo = true;
                    break;
                  }
  
                  if (item.horario && item.horario.horario) {
                    if (item.horario.horario.domingo) {
                      if (item.horario.horario.domingo.horaInicio1 && fechaARevisar[0]) {
  
                        let horaInicioRevisar = parseInt(item.horario.horario.domingo.horaInicio1.split(':')[0]);
                        let minutoInicioRevisar = parseInt(item.horario.horario.domingo.horaInicio1.split(':')[1]);
                        let horaInicioChecador = parseInt(fechaARevisar[0].hora.split(':')[0]);
                        let minutoInicioChecador = parseInt(fechaARevisar[0].hora.split(':')[1]);
  
                        if (fechaARevisar[0] && fechaARevisar[0].Estado && fechaARevisar[0].Estado.toUpperCase() === "IGNORAR") {
                          calcularChecadorhoraInicio1 = 0;
                        } else if (fechaARevisar[0] && fechaARevisar[0].Estado && fechaARevisar[0].Estado.toUpperCase() === "X") {
                          countTotal++;
                        } else {
                          if (horaInicioChecador == horaInicioRevisar) {
                            if (minutoInicioChecador > minutoInicioRevisar) {
                              calcularChecadorhoraInicio1 = (parseInt(minutoInicioChecador) - parseInt(minutoInicioRevisar));
                            }
                          } else if (horaInicioChecador > horaInicioRevisar) {
                            if ((horaInicioChecador - horaInicioRevisar) >= 1 && ((minutoInicioChecador) > minutoInicioRevisar)) {
                              calcularChecadorhoraInicio1 = (60 * (parseInt(horaInicioChecador) - parseInt(horaInicioRevisar))) + (parseInt(minutoInicioChecador) - parseInt(minutoInicioRevisar))
                            } else {
                              calcularChecadorhoraInicio1 = ((60 * (horaInicioRevisar - horaInicioChecador)) - 60) + ((60 + minutoInicioRevisar) - minutoInicioChecador);
                            }
                          }
                        }
                      }
  
                      ResultadoArrayNomina.domingoRetrasohoraInicio1 = calcularChecadorhoraInicio1 || 0;
  
                      if (item.horario.horario.domingo.horaFin1 && fechaARevisar[1]) {
  
                        let horaInicioRevisar = parseInt(item.horario.horario.domingo.horaFin1.split(':')[0]);
                        let minutoInicioRevisar = parseInt(item.horario.horario.domingo.horaFin1.split(':')[1]);
                        let horaInicioChecador = parseInt(fechaARevisar[1].hora.split(':')[0]);
                        let minutoInicioChecador = parseInt(fechaARevisar[1].hora.split(':')[1]);
  
                        if (fechaARevisar[1] && fechaARevisar[1].Estado && fechaARevisar[1].Estado.toUpperCase() === "IGNORAR") {
                          calcularChecadorhoraFin1 = 0;
                        } else if (fechaARevisar[1] && fechaARevisar[1].Estado && fechaARevisar[1].Estado.toUpperCase() === "X") {
                          countTotal++;
                        } else {
                          if (horaInicioChecador == horaInicioRevisar) {
                            if (minutoInicioChecador < minutoInicioRevisar) {
                              calcularChecadorhoraFin1 = (parseInt(minutoInicioRevisar) - parseInt(minutoInicioChecador));
                            }
                          } else if (horaInicioChecador < horaInicioRevisar) {
                            if ((horaInicioRevisar - horaInicioChecador) >= 1 && (minutoInicioChecador < minutoInicioRevisar)) {
                              calcularChecadorhoraFin1 = (60 * (parseInt(horaInicioRevisar) - parseInt(horaInicioChecador))) + (parseInt(minutoInicioRevisar) - parseInt(minutoInicioChecador))
                            } else {
                              calcularChecadorhoraFin1 = ((60 * (horaInicioRevisar - horaInicioChecador)) - 60) + ((60 + minutoInicioRevisar) - minutoInicioChecador);
                            }
                          }
                        }
                      }
  
                      ResultadoArrayNomina.domingoRetrasohoraFin1 = calcularChecadorhoraFin1 || 0;
  
                      if (item.horario.horario.domingo.horaInicio2 && fechaARevisar[2]) {
  
                        let horaInicioRevisar = parseInt(item.horario.horario.domingo.horaInicio2.split(':')[0]);
                        let minutoInicioRevisar = parseInt(item.horario.horario.domingo.horaInicio2.split(':')[1]);
                        let horaInicioChecador = parseInt(fechaARevisar[2].hora.split(':')[0]);
                        let minutoInicioChecador = parseInt(fechaARevisar[2].hora.split(':')[1]);
  
  
                        if (fechaARevisar[2] && fechaARevisar[2].Estado && fechaARevisar[2].Estado.toUpperCase() === "IGNORAR") {
                          calcularChecadorhoraInicio2 = 0;
                        } else if (fechaARevisar[2] && fechaARevisar[2].Estado && fechaARevisar[2].Estado.toUpperCase() === "X") {
                          countTotal++;
                        } else {
                          if (horaInicioChecador == horaInicioRevisar) {
                            if (minutoInicioChecador > minutoInicioRevisar) {
                              calcularChecadorhoraInicio2 = (parseInt(minutoInicioChecador) - parseInt(minutoInicioRevisar));
                            }
                          } else if (horaInicioChecador > horaInicioRevisar) {
                            if ((horaInicioChecador - horaInicioRevisar) >= 1 && (minutoInicioChecador > minutoInicioRevisar)) {
                              calcularChecadorhoraInicio2 = (60 * (parseInt(horaInicioChecador) - parseInt(horaInicioRevisar))) + (parseInt(minutoInicioChecador) - parseInt(minutoInicioRevisar))
                            } else {
                              calcularChecadorhoraFin1 = ((60 * (horaInicioRevisar - horaInicioChecador)) - 60) + ((60 + minutoInicioRevisar) - minutoInicioChecador);
                            }
                          }
                        }
                      }
  
                      ResultadoArrayNomina.domingoRetrasohoraInicio2 = calcularChecadorhoraInicio2 || 0;
  
                      if (item.horario.horario.domingo.horaFin2 && fechaARevisar[3]) {
  
                        let horaInicioRevisar = parseInt(item.horario.horario.domingo.horaInicio2.split(':')[0]);
                        let minutoInicioRevisar = parseInt(item.horario.horario.domingo.horaInicio2.split(':')[1]);
                        let horaInicioChecador = parseInt(fechaARevisar[3].hora.split(':')[0]);
                        let minutoInicioChecador = parseInt(fechaARevisar[3].hora.split(':')[1]);
  
                        if (fechaARevisar[3] && fechaARevisar[3].Estado && fechaARevisar[3].Estado.toUpperCase() === "IGNORAR") {
                          calcularChecadorhoraFin1 = 0;
                        } else if (fechaARevisar[3] && fechaARevisar[3].Estado && fechaARevisar[3].Estado.toUpperCase() === "X") {
                          countTotal++;
                        } else {
                          if (horaInicioChecador == horaInicioRevisar) {
                            if (minutoInicioChecador < minutoInicioRevisar) {
                              calcularChecadorhoraFin2 = (parseInt(minutoInicioRevisar) - parseInt(minutoInicioChecador));
                            }
                          } else if (horaInicioChecador < horaInicioRevisar) {
                            if ((horaInicioRevisar - horaInicioChecador) >= 1 && (minutoInicioChecador < minutoInicioRevisar)) {
                              calcularChecadorhoraFin2 = (60 * (parseInt(horaInicioRevisar) - parseInt(horaInicioChecador))) + (parseInt(minutoInicioRevisar) - parseInt(minutoInicioChecador))
                            } else {
                              calcularChecadorhoraFin2 = ((60 * (horaInicioRevisar - horaInicioChecador)) - 60) + ((60 + minutoInicioRevisar) - minutoInicioChecador);
                            }
                          }
                        }
                      }
  
                      ResultadoArrayNomina.domingoRetrasohoraFin2 = calcularChecadorhoraFin2 || 0;
  
                    }
                  }
                  ResultadoArrayNomina.domingoOmisiones = countTotal;
                  break;
              }
              break;
            }
          });


          console.log(ResultadoArrayNomina);

          console.log(".-..");

          const sumatoriaFaltas = 
          (ResultadoArrayNomina.faltaLunes);

          let penalizacionesNomina = [];
          if (ResultadoArrayNomina.faltaLunes) {
            let pena = {
              descripcion: "Falta lunes",
              descuento: (item.sueldo/7),
              tipo: "INASISTENCIA"
            }
            penalizacionesNomina.push(pena);
          }
          if (ResultadoArrayNomina.faltaMartes) {
            let pena = {
              descripcion: "Falta martes",
              descuento: item.sueldo,
              tipo: "INASISTENCIA"
            }
            penalizacionesNomina.push(pena);
          }
          if (ResultadoArrayNomina.faltaMiercoles) {
            let pena = {
              descripcion: "Falta miercoles",
              descuento: item.sueldo,
              tipo: "INASISTENCIA"
            }
            penalizacionesNomina.push(pena);
          }
          if (ResultadoArrayNomina.faltaJueves) {
            let pena = {
              descripcion: "Falta jueves",
              descuento: item.sueldo,
              tipo: "INASISTENCIA"
            }
            penalizacionesNomina.push(pena);
          }
          if (ResultadoArrayNomina.faltaViernes) {
            let pena = {
              descripcion: "Falta viernes",
              descuento: item.sueldo,
              tipo: "INASISTENCIA"
            }
            penalizacionesNomina.push(pena);
          }
          if (ResultadoArrayNomina.faltaSabado) {
            let pena = {
              descripcion: "Falta sabado",
              descuento: item.sueldo,
              tipo: "INASISTENCIA"
            }
            penalizacionesNomina.push(pena);
          }
          if (ResultadoArrayNomina.faltaDomingo) {
            let pena = {
              descripcion: "Falta domingo",
              descuento: item.sueldo,
              tipo: "INASISTENCIA"
            }
            penalizacionesNomina.push(pena);
          }

          if (ResultadoArrayNomina.lunesOmisiones) {
            let pena = {
              descripcion: "Omision lunes",
              descuento: parseInt(ResultadoArrayNomina.lunesOmisiones) * 50.00,
              tipo: "OMISIONES"
            }
            penalizacionesNomina.push(pena);
          }
          if (ResultadoArrayNomina.martesOmisiones) {
            let pena = {
              descripcion: "Omision martes",
              descuento: parseInt(ResultadoArrayNomina.martesOmisiones) * 50.00,
              tipo: "OMISIONES"
            }
            penalizacionesNomina.push(pena);
          }
          if (ResultadoArrayNomina.miercolesOmisiones) {
            let pena = {
              descripcion: "Omision miercoles",
              descuento: parseInt(ResultadoArrayNomina.miercolesOmisiones) * 50.00,
              tipo: "OMISIONES"
            }
            penalizacionesNomina.push(pena);
          }
          if (ResultadoArrayNomina.juevesOmisiones) {
            let pena = {
              descripcion: "Omision jueves",
              descuento: parseInt(ResultadoArrayNomina.juevesOmisiones) * 50.00,
              tipo: "OMISIONES"
            }
            penalizacionesNomina.push(pena);
          }
          if (ResultadoArrayNomina.viernesOmisiones) {
            let pena = {
              descripcion: "Omision viernes",
              descuento: parseInt(ResultadoArrayNomina.viernesOmisiones) * 50.00,
              tipo: "OMISIONES"
            }
            penalizacionesNomina.push(pena);
          }
          if (ResultadoArrayNomina.sabadoOmisiones) {
            let pena = {
              descripcion: "Omision sabado",
              descuento: parseInt(ResultadoArrayNomina.sabadoOmisiones) * 50.00,
              tipo: "OMISIONES"
            }
            penalizacionesNomina.push(pena);
          }
          if (ResultadoArrayNomina.domingoOmisiones) {
            let pena = {
              descripcion: "Omision domingo",
              descuento: parseInt(ResultadoArrayNomina.domingoOmisiones) * 50.00,
              tipo: "OMISIONES"
            }
            penalizacionesNomina.push(pena);
          }



          if (ResultadoArrayNomina.lunesRetrasohoraInicio1) {
            let pena = {
              descripcion: "Retraso checador lunes",
              descuento: calcularRetraso(ResultadoArrayNomina.lunesRetrasohoraInicio1),
              tipo: "OMISIONES"
            }
            penalizacionesNomina.push(pena);
          }
          if (ResultadoArrayNomina.lunesRetrasohoraFin1) {
            let pena = {
              descripcion: "Adelanto checador lunes",
              descuento: calcularRetraso(ResultadoArrayNomina.lunesRetrasohoraFin1),
              tipo: "OMISIONES"
            }
            penalizacionesNomina.push(pena);
          }
          if (ResultadoArrayNomina.lunesRetrasohoraInicio2) {
            let pena = {
              descripcion: "Retraso checador lunes",
              descuento: calcularRetraso(ResultadoArrayNomina.lunesRetrasohoraInicio2),
              tipo: "OMISIONES"
            }
            penalizacionesNomina.push(pena);
          }
          if (ResultadoArrayNomina.lunesRetrasohoraFin2) {
            let pena = {
              descripcion: "Adelanto checador lunes",
              descuento: calcularRetraso(ResultadoArrayNomina.lunesRetrasohoraFin2),
              tipo: "OMISIONES"
            }
            penalizacionesNomina.push(pena);
          }




          if (ResultadoArrayNomina.martesRetrasohoraInicio1) {
            let pena = {
              descripcion: "Retraso checador martes",
              descuento: calcularRetraso(ResultadoArrayNomina.martesRetrasohoraInicio1),
              tipo: "RETARDOS",
            }
            penalizacionesNomina.push(pena);
          }
          if (ResultadoArrayNomina.martesRetrasohoraFin1) {
            let pena = {
              descripcion: "Adelanto checador martes",
              descuento: calcularRetraso(ResultadoArrayNomina.martesRetrasohoraFin1),
              tipo: "RETARDOS",
            }
            penalizacionesNomina.push(pena);
          }
          if (ResultadoArrayNomina.martesRetrasohoraInicio2) {
            let pena = {
              descripcion: "Retraso checador martes",
              descuento: calcularRetraso(ResultadoArrayNomina.martesRetrasohoraInicio2),
              tipo: "RETARDOS",
            }
            penalizacionesNomina.push(pena);
          }
          if (ResultadoArrayNomina.martesRetrasohoraFin2) {
            let pena = {
              descripcion: "Adelanto checador martes",
              descuento: calcularRetraso(ResultadoArrayNomina.martesRetrasohoraFin2),
              tipo: "RETARDOS",
            }
            penalizacionesNomina.push(pena);
          }


          if (ResultadoArrayNomina.miercolesRetrasohoraInicio1) {
            let pena = {
              descripcion: "Retraso checador miercoles",
              descuento: calcularRetraso(ResultadoArrayNomina.miercolesRetrasohoraInicio1),
              tipo: "RETARDOS",
            }
            penalizacionesNomina.push(pena);
          }
          if (ResultadoArrayNomina.miercolesRetrasohoraFin1) {
            let pena = {
              descripcion: "Adelanto checador miercoles",
              descuento: calcularRetraso(ResultadoArrayNomina.miercolesRetrasohoraFin1),
              tipo: "RETARDOS",
            }
            penalizacionesNomina.push(pena);
          }
          if (ResultadoArrayNomina.miercolesRetrasohoraInicio2) {
            let pena = {
              descripcion: "Retraso checador miercoles",
              descuento: calcularRetraso(ResultadoArrayNomina.miercolesRetrasohoraInicio2),
              tipo: "RETARDOS",
            }
            penalizacionesNomina.push(pena);
          }
          if (ResultadoArrayNomina.miercolesRetrasohoraFin2) {
            let pena = {
              descripcion: "Adelanto checador miercoles",
              descuento: calcularRetraso(ResultadoArrayNomina.miercolesRetrasohoraFin2),
              tipo: "RETARDOS",
            }
            penalizacionesNomina.push(pena);
          }



          if (ResultadoArrayNomina.juevesRetrasohoraInicio1) {
            let pena = {
              descripcion: "Retraso checador jueves",
              descuento: calcularRetraso(ResultadoArrayNomina.juevesRetrasohoraInicio1),
              tipo: "RETARDOS",
            }
            penalizacionesNomina.push(pena);
          }
          if (ResultadoArrayNomina.juevesRetrasohoraFin1) {
            let pena = {
              descripcion: "Adelanto checador jueves",
              descuento: calcularRetraso(ResultadoArrayNomina.juevesRetrasohoraFin1),
              tipo: "RETARDOS",
            }
            penalizacionesNomina.push(pena);
          }
          if (ResultadoArrayNomina.juevesRetrasohoraInicio2) {
            let pena = {
              descripcion: "Retraso checador jueves",
              descuento: calcularRetraso(ResultadoArrayNomina.juevesRetrasohoraInicio2),
              tipo: "RETARDOS",
            }
            penalizacionesNomina.push(pena);
          }
          if (ResultadoArrayNomina.juevesRetrasohoraFin2) {
            let pena = {
              descripcion: "Adelanto checador jueves",
              descuento: calcularRetraso(ResultadoArrayNomina.juevesRetrasohoraFin2),
              tipo: "RETARDOS",
            }
            penalizacionesNomina.push(pena);
          }


          if (ResultadoArrayNomina.viernesRetrasohoraInicio1) {
            let pena = {
              descripcion: "Retraso checador viernes",
              descuento: calcularRetraso(ResultadoArrayNomina.viernesRetrasohoraInicio1),
              tipo: "RETARDOS",
            }
            penalizacionesNomina.push(pena);
          }
          if (ResultadoArrayNomina.viernesRetrasohoraFin1) {
            let pena = {
              descripcion: "Adelanto checador viernes",
              descuento: calcularRetraso(ResultadoArrayNomina.viernesRetrasohoraFin1),
              tipo: "RETARDOS",
            }
            penalizacionesNomina.push(pena);
          }
          if (ResultadoArrayNomina.viernesRetrasohoraInicio2) {
            let pena = {
              descripcion: "Retraso checador viernes",
              descuento: calcularRetraso(ResultadoArrayNomina.viernesRetrasohoraInicio2),
              tipo: "RETARDOS",
            }
            penalizacionesNomina.push(pena);
          }
          if (ResultadoArrayNomina.viernesRetrasohoraFin2) {
            let pena = {
              descripcion: "Adelanto checador viernes",
              descuento: calcularRetraso(ResultadoArrayNomina.viernesRetrasohoraFin2),
              tipo: "RETARDOS",
            }
            penalizacionesNomina.push(pena);
          }



          if (ResultadoArrayNomina.sabadoRetrasohoraInicio1) {
            let pena = {
              descripcion: "Retraso checador sabado",
              descuento: calcularRetraso(ResultadoArrayNomina.sabadoRetrasohoraInicio1),
              tipo: "RETARDOS",
            }
            penalizacionesNomina.push(pena);
          }
          if (ResultadoArrayNomina.sabadoRetrasohoraFin1) {
            let pena = {
              descripcion: "Adelanto checador sabado",
              descuento: calcularRetraso(ResultadoArrayNomina.sabadoRetrasohoraFin1),
              tipo: "RETARDOS",
            }
            penalizacionesNomina.push(pena);
          }
          if (ResultadoArrayNomina.sabadoRetrasohoraInicio2) {
            let pena = {
              descripcion: "Retraso checador sabado",
              descuento: calcularRetraso(ResultadoArrayNomina.sabadoRetrasohoraInicio2),
              tipo: "RETARDOS",
            }
            penalizacionesNomina.push(pena);
          }
          if (ResultadoArrayNomina.sabadoRetrasohoraFin2) {
            let pena = {
              descripcion: "Adelanto checador sabado",
              descuento: calcularRetraso(ResultadoArrayNomina.sabadoRetrasohoraFin2),
              tipo: "RETARDOS",
            }
            penalizacionesNomina.push(pena);
          }


          if (ResultadoArrayNomina.domingoRetrasohoraInicio1) {
            let pena = {
              descripcion: "Retraso checador domingo",
              descuento: calcularRetraso(ResultadoArrayNomina.domingoRetrasohoraInicio1),
              tipo: "RETARDOS",
            }
            penalizacionesNomina.push(pena);
          }
          if (ResultadoArrayNomina.domingoRetrasohoraFin1) {
            let pena = {
              descripcion: "Adelanto checador domingo",
              descuento: calcularRetraso(ResultadoArrayNomina.domingoRetrasohoraFin1),
              tipo: "RETARDOS",
            }
            penalizacionesNomina.push(pena);
          }
          if (ResultadoArrayNomina.domingoRetrasohoraInicio2) {
            let pena = {
              descripcion: "Retraso checador domingo",
              descuento: calcularRetraso(ResultadoArrayNomina.domingoRetrasohoraInicio2),
              tipo: "RETARDOS",
            }
            penalizacionesNomina.push(pena);
          }
          if (ResultadoArrayNomina.domingoRetrasohoraFin2) {
            let pena = {
              descripcion: "Adelanto checador domingo",
              descuento: calcularRetraso(ResultadoArrayNomina.domingoRetrasohoraFin2),
              tipo: "RETARDOS",
            }
            penalizacionesNomina.push(pena);
          }


          console.log("ANTES DE MANDAR A LLAMADAR");
          //let noChecador = countTotal * 50;
          let data = {
            idEmpleado: item.idEmpleado,
            idHorario: item.horario.id,
            idSucursal: item.idSucursal,
            fechaNomina: moment().format('YYYY-MM-DD'),
            fechaInicioNomina,
            fechaFinNomina,
            //inasistencias: noChecador,
            //omisionesRetardos: 0,
            errorGenerar: false,
            idNominaFecha: props.nominaFechaAnterior.id,
            sueldoFiscal: item.sueldoFiscal || 0.00,
            idUsuarioGenero: props.addSesion.user.id,
            penalizaciones: penalizacionesNomina,
            asistencias: 7
          }
          await props.generarNomina(data).then(result => {
            if (result.error) {
              alert('Ocurrió un error al generar la nómina de ' + item.nombre + ' el error fue: ' + result.message + ' \n');
            } else {
              alert('Se generó correctamente la nómina de ' + item.nombre + ' favor de validar \n');

            }
          })
        } else {
          let data = {
            idEmpleado: item.idEmpleado,
            idHorario: '',
            idSucursal: item.idSucursal,
            fechaNomina: moment().format('YYYY-MM-DD'),
            inasistencias: 0,
            omisionesRetardos: 0,
            idNominaFecha: props.nominaFechaAnterior.id,
            errorGenerar: true,
            sueldoFiscal: item.sueldoFiscal || 0.00,
            idUsuarioGenero: props.addSesion.user.id,
            fechaInicioNomina,
            fechaFinNomina,
            penalizaciones: [],
            asistencias: 7,
          }
          await props.generarNomina(data).then(result => {
            if (result.error) {
              alert('Ocurrió un error al generar la nómina de ' + item.nombre + ' el error fue: ' + result.message + ' \n');
            } else {
              alert('Se generó con errores la nómina de ' + item.nombre + ' favor de validar \n');
            }
          });
        }
      });
  }

  const handleSaveExcel = (e) => {
    setExcel('')
    //let archivos = event.target.files;
    e.preventDefault();

    var files = e.target.files, f = files[0];
    var reader = new FileReader();
    reader.onload = function (e) {
      var data = e.target.result;
      let readedData = XLSX.read(data, { type: 'binary' });
      const wsname = readedData.SheetNames[0];
      const ws = readedData.Sheets[wsname];

      /* Convert array to json*/
      const excelRows = XLSX.utils.sheet_to_row_object_array(ws);
      console.log(excelRows);
      excelRows.map(row => {
        if (!isNaN(row.Tiempo)) {
          //let fecha = new Date(row.Tiempo);
          let preDate = new Date((row.Tiempo - (25567 + 1)) * 86400 * 1000);
          preDate.setHours(preDate.getHours() + 6);
          preDate.setDate(preDate.getDate() - 1);
          row.Tiempo = moment(preDate).format('DD/MM/YYYY HH:mm a')
          row.fecha = moment(preDate).format('DD/MM/YYYY');
          row.hora = moment(preDate).format('HH:mm');
          row.ampm = moment(preDate).format('a');
          row.dia = moment(preDate).format('dddd');

        } else {
          row.Tiempo = row.Tiempo.trim();
        }
      });
      setExcel(excelRows);
      console.log(excelRows);
    };
    reader.readAsBinaryString(f)
  };

  const handleSaveExcelSueldo = (e) => {
    setExcelSueldos('')
    //let archivos = event.target.files;
    e.preventDefault();

    var files = e.target.files, f = files[0];
    var reader = new FileReader();
    reader.onload = function (e) {
      var data = e.target.result;
      let readedData = XLSX.read(data, { type: 'binary' });
      const wsname = readedData.SheetNames[0];
      const ws = readedData.Sheets[wsname];

      /* Convert array to json*/
      const excelRows = XLSX.utils.sheet_to_row_object_array(ws);
      console.log(excelRows);
      setExcelSueldos(excelRows);
    };
    reader.readAsBinaryString(f)
  };

  const openModalVerificarNommina = (nomina) => {
    setNominaVerificar(nomina);
    setSueldoSemanal(nomina.sueldoSemanalTotal || 0.00);
    setInasistencias(nomina.inasistencias || 0.00);
    setOmisionesRetardos(nomina.omisionesRetardos || 0.00);
    setPrimaVacacional(nomina.primaVacacional || 0.00);
    setSueldoSemanalFiscal(nomina.sueldoSemanalFiscal || 0.00);
    setErrorGenerarNomina(nomina.errorGenerar || true);
    setVacaciones(nomina.vacaciones || 0.00);
    setSueldoSemanalRestante(nomina.sueldoSemanalRestante || 0.00);

    let penalizacionesCount = 0.00;
    props.getPenalizacionesByNomina(nomina.id).then(res => {
      if (res.penalizacionesList && res.penalizacionesList.length > 0) {
        res.penalizacionesList.forEach(pen => {
          penalizacionesCount = penalizacionesCount + pen.descuento;
        });
        setPenalizacionADescontarTotal(penalizacionesCount);
      }
    });

    /*  const [fonacot, setFonacot] = React.useState(0.00);
  const [inasistencias, setInasistencias] = React.useState(0.00);
  const [omisionesRetardos, setOmisionesRetardos] = React.useState(0.00);
  const [primaVacacional, setPrimaVacacional] = React.useState(0.00);
  const [sueldoSemanalFiscal, setSueldoSemanalFiscal] = React.useState(0.00);
  const [errorGenerarNomina, setErrorGenerarNomina] = React.useState(0.00);
  const [sueldoSemanalTotal, setSueldoSemanalTotal] = React.useState(0.00);
  const [vacaciones, setVacaciones] = React.useState(0.00);
    */

    setOpenVerificar(true);
  }

  const handleOpenGenerarPenalizacion = () => {
    setOpenPenalizacion(true);
  }

  const handleCloseGenerarPenalizacion = () => {
    setOpenPenalizacion(false);
    setDescripcionPenalizacion('');
    setTipoPenalizacion('');
    setDescripcionPenalizacion('');
  }

  const handleChangeTipoPenalizacion = (event) => {
    setTipoPenalizacion(event.target.value);
  };

  const handleGenerarPenalizacion = (event) => {
    const data = {
      idNomina: nominaVerificar.id,
      idEmpleado: nominaVerificar.idEmpleado,
      tipo: tipoPenalizacion.toUpperCase(),
      descripcion: descripcionPenalizacion,
      idUsuarioGenero: props.addSesion.user.id,
      descuento: descuentoPenalización,
    };
    props.agregarPenalizacion(data).then((res) => {
      if (res && !res.error) {
        handleCloseGenerarPenalizacion();
        props.getNominasInRevision(sucur, fechaInicioNominaValidarSucursal, fechaFinNominaValidarSucursal);
        props.getPenalizacionesByNomina(nominaVerificar.id).then((result) => {

        });
      }
      setMessageSnackbar(res.message);
    });
  }

  const eliminarPenalizacion = (idPenalizacion) => {
    props.eliminarPenalizacion(idPenalizacion, props.addSesion.user.id).then((res) => {
      if (res && !res.error) {
        //handleCloseGenerarPenalizacion();
        let penalizacionesCount = 0.00;
        props.getPenalizacionesByNomina(nominaVerificar.id).then((result) => {
          if (result.penalizacionesList && result.penalizacionesList.length > 0) {
            props.getNominasInRevision(sucur, fechaInicioNominaValidarSucursal, fechaFinNominaValidarSucursal);
            result.penalizacionesList.forEach(pen => {
              penalizacionesCount = penalizacionesCount + pen.descuento;
            });
            setPenalizacionADescontarTotal(penalizacionesCount);
          }
        });
      }
      setMessageSnackbar(res.message);
    });
  }

  const handleValidarNomina = () => {
    let data = {
      bono: 0.00, //insertar
      errorGenerar: errorGenerarNomina,
      id: nominaVerificar.id,
      idEmpleado: nominaVerificar.idEmpleado,
      fechaNomina: nominaVerificar.fechaNomina,
      fechaInicio: nominaVerificar.fechaInicio,
      fechaFin: nominaVerificar.fechaFin,
      idNominaFecha: nominaVerificar.idNominaFecha,
      idSucursal: nominaVerificar.idSucursal,
      sueldoSemanalFiscal: sueldoSemanalFiscal,
      sueldoSemanalRestante: (sueldoSemanal - penalizacionADescontarTotal - fonacot  + primaVacacional),
      sueldoSemanalTotal: sueldoSemanal,
      total: (sueldoSemanal - sueldoSemanalFiscal - penalizacionADescontarTotal - fonacot + primaVacacional),
      createdAt: nominaVerificar.createdAt,
      updatedAt: nominaVerificar.updatedAt,
      penalizaciones: penalizacionADescontarTotal,
    };
    props.setValidarNomina(data).then(result => {
      if (!result.error) {
        props.getNominasInRevision(sucur, nominaFechas);
      }
      setMessageSnackbar(result.message);
    });
  }

  const generarPDF = (nomina) => {
    if (nomina) {
      const doc = new jsPDF('p', 'pt');
      doc.autoTable({
        styles: {
          fillColor: [255, 255, 255]
        },
        body: [
          [
            {
              content: 'Recibo de Prenomina',
              colSpan: 1,
              rowSpan: 1,
              styles: {
                halign: 'center',
                fontStyle: 'bold',
                fillColor: [255, 255, 255],
                margin: '0px',
              }
            }
          ]
        ],
      });
      doc.setFontSize(13);
      doc.addImage(tapanosaLogo, 'png', 15, 45, 0, 0);
      doc.text(270, 95, "GRUPO TAPANOSA S. de R.L. de C.V.");
      doc.text(270, 110, "Diego Fernández de Proaño no. 3477 Col. San Rafael");
      doc.text(270, 125, "C.P. 80150 Culiacán, Sinaloa.");
      doc.text(270, 140, "R.F.C.  GTA160811K77  REG. PAT. Z33 21060 10 8");
      doc.text(15, 170, `Empleado: ${nomina.empleado.nombre}`);
      doc.text(300, 170, `Periodo: ${nomina.fechaNomina}`);

      doc.text(15, 185, `IMSS: ${nomina.empleado.nombre}`);
      doc.text(300, 185, `CURP: ${nomina.empleado.curp ? nomina.empleado.curp : 'No disponible'}`);

      doc.text(15, 200, `R.F.C.: ${nomina.empleado.rfc}`);
      doc.text(300, 200, `Sueldo: ${nomina.sueldoSemanalTotal}`);

      doc.text(15, 215, `Días Pagados: ${nomina.empleado.nombre}`);
      doc.text(300, 215, `SDI: ${nomina.sueldoSemanalTotal}`);
      doc.text(450, 215, `Faltas: ${nomina.inasistencias}`);

      doc.text(15, 250, 'Percepciones');
      doc.text(15, 280, `Sueldo: ${nomina.sueldoSemanalTotal}`);
      doc.text(15, 295, `Día Extra: ${nomina.diaExtra}`);
      doc.text(15, 310, `Prima vacacional: ${nomina.primaVacacional}`);
      doc.text(15, 325, `Reembolso: ${nomina.reembolso}`);
      doc.text(15, 340, `Vacaciones: ${nomina.vacaciones}`);
      doc.text(15, 355, `Total percepciones: ${nomina.percepciones}`);


      doc.text(300, 250, 'Deducciones');
      doc.text(300, 280, `imss: -`);
      doc.text(300, 295, `Penalizaciones: ${nomina.penalizaciones || 0}`);
      doc.text(300, 310, `Inasistencias: ${nomina.inasistencias || 0}`);
      doc.text(300, 325, `Retardos: ${nomina.retardos}`);
      doc.text(300, 340, `Fondo de ahorro: ${nomina.fondoAhorro || 0}`);
      doc.text(300, 355, `Total deducciones: ${nomina.deducciones || 0}`);
      doc.text(300, 370, `Neto pagado: ${nomina.sueldoSemanalRestante}`);

      doc.text(25, 400, `La cantidad anotada en este recibo es el pago de mi salario; además, certifico que no se me adeuda a `);
      doc.text(25, 415, `la fecha, cantidad alguna por sueldos, tiempo extra o por cualquier otro concepto.`);

      doc.text(250, 440, '______________________')
      doc.text(255, 455, ` Firma del trabajador`);


      /* doc.autoTable({
         styles: { fillColor: [0, 255, 0] },
         columnStyles: { 0: { halign: 'center' } },
         head: [
             [
                 { content: '', styles: { halign: 'center', fontStyle: 'bold', fillColor: [255, 255, 255] } },
                 { content: '', styles: { halign: 'center', fontStyle: 'bold', fillColor: [255, 255, 255] } },
                 { content: '', styles: { halign: 'center', fontStyle: 'bold', fillColor: [255, 255, 255] } },
                 { content: '', styles: { halign: 'center', fontStyle: 'bold', fillColor: [255, 255, 255] } },
             ]
         ],
         body: [
             [
                 { content: 'GRUPO TAPANOSA S. de R.L. de C.V.', styles: { halign: 'center' }},
                 { content: 'Diego Fernández de Proaño no. 3477 Col. San Rafael', styles: { halign: 'center' }},
                 { content: 'C.P. 80150 Culiacán, Sinaloa.', styles: { halign: 'center' }},
                 { content: 'R.F.C.  GTA160811K77        REG. PAT. Z33 21060 10 8', styles: { halign: 'center' }},
     
             ],
         ]
     }); */

      doc.save('Nómina.pdf');


    }
  }

  return (
    <div className={classes.root}>
      <Menu title="Administración de Nómina" />
      <main className={classes.content}>
        <div className={classes.toolbar} />
        <Container component="main" >
          <div className={classes.paper}>
            <Typography variant="h4">
              Administración de nómina
            </Typography>
            <Grid container spacing={2}>
              <Grid item xs={6} sm={4} >

                {props.addSesion && props.addSesion.user && props.addSesion.user.admin ?
                  <Button
                    type="submit"
                    fullWidth
                    variant="outlined"
                    color="primary"
                    onClick={handleOpenGenerarNomina}
                  >
                    <Add />
                    Generar Nomina
                  </Button>
                  : ''
                }
              </Grid>

              <div className={tabClasses.root}>
                <AppBar position="static">
                  <Tabs value={value} onChange={handleChange} aria-label="simple tabs example">
                    <Tab label="Verificar nóminas" {...a11yProps(0)} />
                    <Tab label="Validar nóminas" {...a11yProps(1)} />
                    <Tab label="Reporte de Nóminas" {...a11yProps(2)} />
                  </Tabs>
                </AppBar>
                <TabPanel value={value} index={0}>

                  {props.addSesion && props.addSesion.user && props.addSesion.user.admin ?
                    <Grid item xs={7} md={12} >
                      <FormControl className={classes.formControl}>
                        <InputLabel id="seleccionar-sucursal">Sucursal</InputLabel>
                        <Select
                          labelId="sucursales"
                          id="sucursaless"
                          value={sucur}
                          onChange={handleChangeSucursal}
                          fullWidth
                        >
                          <MenuItem value="">
                            <em>Ninguna</em>
                          </MenuItem>
                          <MenuItem value="TODAS">
                            <em>Todas</em>
                          </MenuItem>
                          {
                            arraySucursales.map(item => {
                              return (<MenuItem value={item.id}>
                                <em>{item.nombre} </em>
                              </MenuItem>);
                            })
                          }
                        </Select>
                      </FormControl>
                      <TextField
                        id="date"
                        label="Fecha Inicio"
                        type="date"
                        value={fechaInicioNominaValidarSucursal}
                        sx={{ width: 220 }}
                        InputLabelProps={{
                          shrink: true,
                        }}
                        onChange={e => { setFechaInicioNominaValidarSucursal(e.target.value) }}
                      />
                      <TextField
                        id="date"
                        label="Fecha Fin"
                        type="date"
                        value={fechaFinNominaValidarSucursal}
                        sx={{ width: 220 }}
                        InputLabelProps={{
                          shrink: true,
                        }}
                        onChange={e => { setFechaFinNominaValidarSucursal(e.target.value) }}
                      />


                      <Button
                        type="submit"
                        style={{ width: '100px', marginTop: '20px' }}
                        variant="outlined"
                        color="primary"
                        onClick={onBuscarEmpleadosPorSucursal}
                      >
                        Buscar
                      </Button>
                    </Grid>
                    : ''
                  }

                  <Grid item xs={7} md={12} >
                    <TableContainer component={Paper}>
                      <Table className={classes.table} size="small" aria-label="a dense table">
                        <TableHead>
                          <TableRow>
                            <TableCell align="center">Id Nómina</TableCell>
                            <TableCell align="center">Empleado</TableCell>
                            <TableCell align="center">Sucursal</TableCell>
                            <TableCell align="center">Departamento</TableCell>
                            <TableCell align="center">Estatus nómina</TableCell>
                            <TableCell align="center">Generó</TableCell>
                            <TableCell align="center">Fecha Creación</TableCell>
                            <TableCell align="center">Última Modificación</TableCell>
                            <TableCell align="center"></TableCell>
                            {props.addSesion && props.addSesion.user && props.addSesion.user.admin ?
                              <TableCell align="center"></TableCell> : ''}
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {props.nominasRevision && props.nominasRevision.nominas && props.nominasRevision.nominas.length >= 1 ?
                            props.nominasRevision.nominas.map((nomina) => (
                              <TableRow key={nomina.id}>
                                <TableCell component="th" scope="row" align="center">
                                  {nomina.id}
                                </TableCell>
                                <TableCell align="center">{nomina.empleado ? `${nomina.empleado.nombre} ${nomina.empleado.primerApellido}` : 'No disponible'}</TableCell>
                                <TableCell align="center">{nomina.sucursal ? nomina.sucursal.nombre : 'No disponible'}</TableCell>
                                <TableCell align="center">{nomina.empleado && nomina.empleado.departamento ? nomina.empleado.departamento.descripcion : 'No disponible'}</TableCell>
                                <TableCell align="center">{nomina.status ? nomina.status : 'No disponible'}</TableCell>
                                <TableCell align="center">{nomina.empleadoGenero ? nomina.empleadoGenero.nombre : 'No disponible'}</TableCell>
                                <TableCell align="center">{nomina.createdAt ? nomina.createdAt : 'No disponible'}</TableCell>
                                <TableCell align="center">{nomina.updatedAt ? nomina.updatedAt : 'No disponible'}</TableCell>
                                {props.addSesion && props.addSesion.user && props.addSesion.user.admin ?
                                  <TableCell align="center">
                                    <Button
                                      type=""
                                      variant="outlined"
                                      color="primary"
                                      onClick={() => openModalVerificarNommina(nomina)}
                                    >Validar
                                    </Button>
                                  </TableCell> : ''
                                }
                              </TableRow>
                            ))
                            :
                            (!props.nominasRevision ? '' :
                              props.nominasRevision.nominas && props.nominasRevision.nominas.length == 0 ? 'No se encontraron resultados' : 'No se encontraron resultados')
                          }
                        </TableBody>
                      </Table>
                    </TableContainer>
                  </Grid>

                </TabPanel>

                <TabPanel value={value} index={1}>

                  {props.addSesion && props.addSesion.user && props.addSesion.user.admin ?
                    <Grid item xs={7} md={12} >
                      <FormControl className={classes.formControl}>
                        <InputLabel id="seleccionar-sucursal">Sucursal</InputLabel>
                        <Select
                          labelId="sucursales"
                          id="sucursaless"
                          value={sucur}
                          onChange={handleChangeSucursal}
                          fullWidth
                        >
                          <MenuItem value="">
                            <em>Ninguna</em>
                          </MenuItem>
                          <MenuItem value="TODAS">
                            <em>Todas</em>
                          </MenuItem>
                          {
                            arraySucursales.map(item => {
                              return (<MenuItem value={item.id}>
                                <em>{item.nombre} </em>
                              </MenuItem>);
                            })
                          }
                        </Select>
                      </FormControl>
                      
                      <TextField
                        id="date"
                        label="Fecha Inicio"
                        type="date"
                        value={fechaInicioValidacionAdmin}
                        sx={{ width: 220 }}
                        InputLabelProps={{
                          shrink: true,
                        }}
                        onChange={e => { setFechaInicioValidacionAdmin(e.target.value) }}
                      />
                      <TextField
                        id="date"
                        label="Fecha Fin"
                        type="date"
                        value={fechaFinValidacionAdmin}
                        sx={{ width: 220 }}
                        InputLabelProps={{
                          shrink: true,
                        }}
                        onChange={e => { setFechaFinValidacionAdmin(e.target.value) }}
                      />

                      <Button
                        type="submit"
                        style={{ width: '100px', marginTop: '20px' }}
                        variant="outlined"
                        color="primary"
                        onClick={onBuscarNominasValidadas}
                      >
                        Buscar
                      </Button>
                    </Grid>
                    : ''
                  }

                  <Grid item xs={7} md={12} >
                    <TableContainer component={Paper}>
                      <Table className={classes.table} size="small" aria-label="a dense table">
                        <TableHead>
                          <TableRow>
                            <TableCell align="center">Id Nómina</TableCell>
                            <TableCell align="center">Empleado</TableCell>
                            <TableCell align="center">Departamento</TableCell>
                            <TableCell align="center">Asistencias</TableCell>
                            <TableCell align="center">Sueldo Semanal</TableCell>
                            <TableCell align="center">Incapacidades</TableCell>
                            <TableCell align="center">Penalizaciones</TableCell>
                            <TableCell align="center">Fonacot</TableCell>
                            <TableCell align="center">Inasistencais</TableCell>
                            <TableCell align="center">Fondo ahorro</TableCell>                            <TableCell align="center">Fondo ahorro</TableCell>
                            <TableCell align="center">Bono</TableCell>
                            <TableCell align="center">Prima Vacacional</TableCell>
                            <TableCell align="center">Vacaciones</TableCell>
                            <TableCell align="center">Prestamos</TableCell>
                            <TableCell align="center">Total de nómina</TableCell>
                            {props.addSesion && props.addSesion.user && props.addSesion.user.admin ?
                              <TableCell align="center"></TableCell> : ''}
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {props.nominasValidadas && props.nominasValidadas.nominas && props.nominasValidadas.nominas.length >= 1 ?
                            props.nominasValidadas.nominas.map((nomina) => (
                              <TableRow key={nomina.id}>
                                <TableCell component="th" scope="row" align="center">
                                  {nomina.id}
                                </TableCell>
                                <TableCell align="center">{nomina.empleado ? `${nomina.empleado.nombre} ${nomina.empleado.primerApellido}` : 'No disponible'}</TableCell>
                                <TableCell align="center">{nomina.empleado && nomina.empleado.departamento ? nomina.empleado.departamento.descripcion : 'No disponible'}</TableCell>
                                <TableCell align="center">{nomina.asistencias ? nomina.asistencias : 'No disponible'}</TableCell>
                                <TableCell align="center">{nomina.sueldoSemanalTotal ? nomina.sueldoSemanalTotal : 'No disponible'}</TableCell>
                                <TableCell align="center">{nomina.incapacidades ? nomina.incapacidades : 'No disponible'}</TableCell>
                                <TableCell align="center">{nomina.penalizaciones ? nomina.penalizaciones : 'No disponible'}</TableCell>
                                <TableCell align="center">{nomina.fonacot ? nomina.fonacot : 'No disponible'}</TableCell>
                                <TableCell align="center">{nomina.inasistencias ? nomina.inasistencias : 'No disponible'}</TableCell>
                                <TableCell align="center">{nomina.fondoAhorro ? nomina.fondoAhorro : 'No disponible'}</TableCell>
                                <TableCell align="center">{nomina.bono ? nomina.bono : 'No disponible'}</TableCell>
                                <TableCell align="center">{nomina.primaVacacional ? nomina.primaVacacional : 'No disponible'}</TableCell>
                                <TableCell align="center">{nomina.vacaciones ? nomina.vacaciones : 'No disponible'}</TableCell>
                                <TableCell align="center">{nomina.prestamos ? nomina.prestamos : 'No disponible'}</TableCell>
                                <TableCell align="center">{nomina.vacaciones ? nomina.vacaciones : 'No disponible'}</TableCell>
                                <TableCell align="center">{nomina.sueldoSemanalTotal ? nomina.sueldoSemanalTotal : 'No disponible'}</TableCell>

                                <TableCell align="center">{nomina.createdAt ? nomina.createdAt : 'No disponible'}</TableCell>
                                {props.addSesion && props.addSesion.user && props.addSesion.user.admin ?
                                  <TableCell align="center">
                                    <Button
                                      type=""
                                      variant="outlined"
                                      color="primary"
                                      onClick={() => generarPDF(nomina)}
                                    >Descargar
                                    </Button>
                                  </TableCell> : ''
                                }
                              </TableRow>
                            ))
                            :
                            (!props.nominasValidadas ? '' :
                              props.nominasValidadas && props.nominasValidadas.nominas && props.nominasValidadas.nominas.length == 0 ? 'No se encontraron resultados' : 'No se encontraron resultados')
                          }
                        </TableBody>
                      </Table>
                    </TableContainer>
                  </Grid>

                </TabPanel>
                <TabPanel value={value} index={2}>
                  Item Three
                </TabPanel>
              </div>




              <Dialog
                open={openGeneralPrestamo}
                onClose={handleCloseGenerarNomina}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
              >
                <DialogTitle id="alert-dialog-title">{"Generar nómina"}</DialogTitle>
                <DialogContent>
                  <DialogContentText id="alert-dialog-description">
                    Favor de ingresar el archivo excel con los horarios y nómina, <br />
                    seleccionar la sucursal a generar la nómina y seleccionar el rango de fechas para la nómina <br />
                    <br />

                    <div>
                      <TextField
                        id="date"
                        label="Fecha Inicio"
                        type="date"
                        value={fechaInicioNomina}
                        sx={{ width: 220 }}
                        InputLabelProps={{
                          shrink: true,
                        }}
                        onChange={e => { setFechaInicioNomina(e.target.value) }}
                      />
                      <TextField
                        id="date"
                        label="Fecha Fin"
                        type="date"
                        value={fechaFinNomina}
                        sx={{ width: 220 }}
                        InputLabelProps={{
                          shrink: true,
                        }}
                        onChange={e => { setFechaFinNomina(e.target.value) }}
                      />
                    </div>
                    <div>
                      <div>
                        Archivo de horario:  <br />
                        <input
                          //accept='.png, .jpeg, .jpg'
                          //className={classes.input}
                          id='contained-button-file'
                          onClick={(event) => event.target.value = ''}
                          type='file'
                          onChange={handleSaveExcel}
                        />
                      </div>
                      <Divider />
                      <div>
                        Archivo de nómina base:  <br />
                        <input
                          //accept='.png, .jpeg, .jpg'
                          //className={classes.input}

                          id='contained-button-file'
                          onClick={(event) => event.target.value = ''}
                          type='file'
                          onChange={handleSaveExcelSueldo}
                        />
                      </div>
                      <Divider />

                      <FormControl fullWidth >
                        <InputLabel id="demo-simple-select-label">Sucursal</InputLabel>
                        <Select
                          labelId="demo-simple-select-label"
                          id="demo-simple-select"
                          value={sucursarSelect}
                          fullWidth
                          label="Sucursal"
                          onChange={handleChangeSucursalSelected}
                        >
                          <MenuItem id="" value={''}>Seleccione una sucursal</MenuItem>
                          {
                            props.sucursales && Array.isArray(props.sucursales) && props.sucursales.length > 0 && props.sucursales.map(item => {
                              return (<MenuItem id={item.id} value={item.id}>{item.nombre}</MenuItem>);
                            })
                          }
                        </Select>
                      </FormControl>
                    </div>
                  </DialogContentText>
                </DialogContent>
                <DialogActions>
                  <Button onClick={handleCloseGenerarNomina} color="primary">
                    Cerrar
                  </Button>
                  <Button onClick={handleGenerarNomina} color="primary" autoFocus>
                    Generar
                  </Button>
                </DialogActions>
              </Dialog>

              <Dialog
                open={openVerificar}
                onClose={handleCloseVerificarNomina}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
              >
                <DialogTitle id="alert-dialog-title">{"Generar nómina"}</DialogTitle>
                <DialogContent>
                  <DialogContentText id="alert-dialog-description">
                    Favor de verificar la información antes de guardar la nómina <br />
                    <br />
                    <br />
                    NOTA: La información que no se modificó no se podrá modificar despues
                    <div>
                      <Grid container spacing={2}>
                        <Grid item xs={6} sm={4} >
                          <TextField
                            name="SueldoSemanal"
                            variant="outlined"
                            fullWidth
                            id="txtSueldoSemanal"
                            label="Sueldo Semanal"
                            value={sueldoSemanal}
                            onChange={(e) => setSueldoSemanal(e.target.value)}
                          />
                        </Grid>
                        <Grid item xs={6} sm={4} >
                          <TextField
                            name="inasistencias"
                            variant="outlined"
                            fullWidth
                            id="txtInasistencais"
                            label="Inasistencais"
                            value={inasistencias}
                            onChange={(e) => setInasistencias(e.target.value)}
                          />

                        </Grid>
                        <Grid item xs={6} sm={4} >
                          <TextField
                            name="prestamos"
                            variant="outlined"
                            fullWidth
                            disabled
                            id="txtPrestamos"
                            label="Prestamos"
                            value={prestamosValue}
                            onChange={(e) => setPrestamosValue(e.target.value)}
                          />
                        </Grid>
                      </Grid>
                      <Grid container spacing={2}>
                        <Grid item xs={6} sm={4} >
                          <TextField
                            name="fonacot"
                            variant="outlined"
                            fullWidth
                            id="txtFonacot"
                            label="Fonacot"
                            value={fonacot}
                            onChange={(e) => setFonacot(e.target.value)}
                          />
                        </Grid>

                        <Grid item xs={6} sm={4} >
                          <TextField
                            name="omisionesRetardos"
                            variant="outlined"
                            fullWidth
                            id="txtOmisionesRetardos"
                            label="Omisiones/Retardos"
                            value={omisionesRetardos}
                            onChange={(e) => setOmisionesRetardos(e.target.value)}
                          />
                        </Grid>
                        <Grid item xs={6} sm={4} >
                          <TextField
                            name="primaVacacional"
                            variant="outlined"
                            fullWidth
                            id="txtPrimaVacacional"
                            label="Prima Vacacional"
                            value={primaVacacional}
                            onChange={(e) => setPrimaVacacional(e.target.value)}
                          />
                        </Grid>

                      </Grid>
                      <Divider />
                      Penalizaciones
                      <Divider />
                      <Button
                        onClick={handleOpenGenerarPenalizacion}
                        color="primary"
                      > Generar Penalización
                      </Button>
                      {
                        openPenalizacion ?
                          <div>
                            <Button
                              onClick={handleCloseGenerarPenalizacion}
                              color="primary"
                            > Cerar Penalización
                            </Button>
                            <FormControl fullWidth >
                              <InputLabel id="demo-simple-select-label">Tipo penalización</InputLabel>
                              <Select
                                labelId="demo-simple-select-label"
                                id="demo-simple-select"
                                value={tipoPenalizacion}
                                fullWidth
                                label="Tipo Penalización"
                                onChange={handleChangeTipoPenalizacion}
                              >
                                <MenuItem id="" value={''}>Seleccione un tipo de penalización</MenuItem>
                                <MenuItem id={1} value="DESCUENTO">Descuento</MenuItem>
                                <MenuItem id={2} value="FALTANTE">Faltante</MenuItem>
                                <MenuItem id={3} value="CANCELACIONES">Cancelaciones</MenuItem>
                                <MenuItem id={4} value="REEMBOLSOS">Reembolsos</MenuItem>

                              </Select>
                            </FormControl>
                            <Grid container spacing={2}>
                              <Grid item xs={6} sm={4} >
                                <TextField
                                  name="descripcionPenalizacion"
                                  variant="outlined"
                                  fullWidth
                                  id="txtDescripcionPenalizacion"
                                  label="Descripción Penalización"
                                  value={descripcionPenalizacion}
                                  onChange={(e) => setDescripcionPenalizacion(e.target.value)}
                                />
                              </Grid>
                              <Grid item xs={6} sm={4} >
                                <TextField
                                  name="descuentoPenalizacion"
                                  variant="outlined"
                                  fullWidth
                                  id="txtDescuentoPenalizacion"
                                  label="Descuento Penalización"
                                  value={descuentoPenalización}
                                  onChange={(e) => setDescuentoPenalizacion(e.target.value)}
                                />
                              </Grid>
                            </Grid>
                            <Button
                              onClick={handleGenerarPenalizacion}
                              color="primary"
                            > Generar Penalización
                            </Button>
                          </div>
                          : <div></div>
                      }

                      <TableContainer component={Paper}>
                        <Table className={classes.table} size="small" aria-label="a dense table">
                          <TableHead>
                            <TableRow>
                              <TableCell align="center">Id Penalización</TableCell>
                              <TableCell align="center">Descripción</TableCell>
                              <TableCell align="center">Tipo</TableCell>
                              <TableCell align="center">Descuento</TableCell>
                              <TableCell align="center"></TableCell>
                            </TableRow>
                          </TableHead>
                          <TableBody>
                            {props.penalizacionesByNomina && props.penalizacionesByNomina.penalizacionesList && props.penalizacionesByNomina.penalizacionesList.length >= 1 ?
                              props.penalizacionesByNomina.penalizacionesList.map((penalizacion) => (
                                <TableRow key={penalizacion.id}>
                                  <TableCell component="th" scope="row" align="center">
                                    {penalizacion.descripcion}
                                  </TableCell>
                                  <TableCell align="center">{penalizacion.tipo}</TableCell>
                                  <TableCell align="center">{penalizacion.descuento}</TableCell>
                                  <TableCell align="center">
                                    <Button
                                      type=""
                                      variant="outlined"
                                      color="primary"
                                      onClick={() => eliminarPenalizacion(penalizacion.id)}
                                    >Eliminar
                                    </Button>
                                  </TableCell>
                                </TableRow>
                              ))
                              :
                              (!props.nominasRevision ? '' :
                                props.nominasRevision && props.nominasRevision.nominas && props.nominasRevision.nominas.length == 0 ? 'No se encontraron resultados' : 'No se encontraron resultados')
                            }
                          </TableBody>
                        </Table>
                      </TableContainer>
                      <Divider />

                      <br />
                      Resumen:
                      <br />
                      Sueldo Semanal    : ${sueldoSemanal} - 
                      <br />
                      Penalizaciones    : ${penalizacionADescontarTotal} - 
                      <br />
                      Fonacot           : ${fonacot} - 
                      <br />
                      Inasistencias     : ${inasistencias} +
                      <br />
                      Prima Vacacional  : ${primaVacacional}
                    </div>
                  </DialogContentText>
                </DialogContent>
                <DialogActions>
                  <Button onClick={handleCloseVerificarNomina} color="primary">
                    Cerrar
                  </Button>
                  <Button onClick={handleValidarNomina} color="primary" autoFocus>
                    Generar
                  </Button>
                </DialogActions>
              </Dialog>
              <Snackbar
                anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
                open={Boolean(messageSnackbar)}
                onClose={() => setMessageSnackbar('')}
                message={messageSnackbar}
                autoHideDuration={6000}
              />
            </Grid>
          </div>
        </Container>
      </main>
    </div>
  )

}

const mapStateToProps = state => {
  return {
    prestamos: state.PrestamosReducer.prestamosByIdSucursal,
    empleadoModificado: state.PrestamosReducer.prestamosModified,
    addSesion: state.SesionReducer.addSesion,
    empleados: state.PrestamosReducer.empleados,
    prestamoGenerado: state.PrestamosReducer.prestamoGenerado,
    sucursales: state.NominaReducer.sucursales,
    empleadosSucursal: state.NominaReducer.empleadosSucursal,
    nominaGenerada: state.NominaReducer.generar,
    nominasRevision: state.NominaReducer.revision,
    nominaFechaAnterior: state.NominaReducer.nominaFechaAnterior,
    nominaFechaSearch: state.NominaReducer.nominaFechaSearch,
    addSesion: state.SesionReducer.addSesion,
    penalizacionesByNomina: state.NominaReducer.penalizacionesByNomina,
    agregarPenalizacion: state.NominaReducer.agregarPenalizacion,
    eliminarPenalizacion: state.NominaReducer.eliminarPenalizacion,
    validarNomina: state.NominaReducer.validarNomina,
    nominasValidadas: state.NominaReducer.nominasValidadas,
  }
}

const mapDispatchToProps = dispatch => {
  return {
    getPrestamos: (sucur) => dispatch(PrestamosActions.getAllPrestamosByidSucursal(sucur)),
    modificarPrestamo: (prestamo) => dispatch(PrestamosActions.UpdateStatus(prestamo)),
    getEmpleados: () => dispatch(PrestamosActions.EmpleadosParaPrestamo()),
    generarPrestamo: (data) => dispatch(PrestamosActions.GenerarPrestamo(data)),
    getSucursales: () => dispatch(NominaActions.getSucursalesActivas()),
    getNominaFechas: (fecha) => dispatch(NominaActions.getNominaFechas(fecha)),
    getEmpleadosSucursal: (idSucursal) => dispatch(NominaActions.getEmpleadosBySucursal(idSucursal)),
    generarNomina: (data) => dispatch(NominaActions.generateNomina(data)),
    getNominasInRevision: (data, fechaInicio, fechaFin) => dispatch(NominaActions.getAllNominasInRevision(data, fechaInicio, fechaFin)),
    getNominaFechaAnterior: (fecha) => dispatch(NominaActions.getNominaFechaAnterior(fecha)),
    getNominaFechasSearch: (fecha) => dispatch(NominaActions.getNominaFechasSearch(fecha)),
    agregarPenalizacion: (data) => dispatch(NominaActions.AgregarPenalizacion(data)),
    eliminarPenalizacion: (idPenalizacion, idUsuario) => dispatch(NominaActions.EliminarPenalizacion(idPenalizacion, idUsuario)),
    getPenalizacionesByNomina: (idNomina) => dispatch(NominaActions.GetPenalizacionesByNomina(idNomina)),
    setValidarNomina: (data) => dispatch(NominaActions.ValidarNomina(data)),
    getNominasValidadas: (idSucursal, fechaInicio, fechaFin) => dispatch(NominaActions.getNominasValidadas(idSucursal, fechaInicio, fechaFin)),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Nomina);