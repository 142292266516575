import { BrowserRouter, Switch, Route } from "react-router-dom";
import Principal from "./Principal";
import Menu from "./Menu"
import { connect} from 'react-redux';


import CatalogoEmpleados from "./CatalogoEmpleados"
import RegistrarEmpleados from "./RegistrarEmpleados"
import ActualizarEmpleados from "./ActualizarEmpleados"

import CatalogoSucursales from "./CatalogoSucursales"
import RegistrarSucursal from "./RegistrarSucursal"
import ActualizarSucursal from "./ActualizarSucursal"

import PerfilEmpleados from "./PerfilEmpleados";
import Prestamos from "./Prestamos";
import Nomina from './Nomina';

import Horario from './Horarios/index';
import Reportes from "./Reportes";
import CatalogoDepartamentos from './Departamentos/index';

function Routes(props){
        return (
            <BrowserRouter>
                                         <Switch>
                                         <Route exact path='/principal' component={Principal} />
                                         <Route exact path='/menu' component={Menu} />
                     
                                         <Route exact path='/catalogoempleados' component={CatalogoEmpleados} /> 
                                         <Route exact path='/registrarempleados' component={RegistrarEmpleados} />
                                         <Route exact path='/actualizarempleados/:id' component={ActualizarEmpleados} />
                                         <Route exact path='/empleado/perfil/:id' component={PerfilEmpleados}/>

                                         <Route exact path='/horarios' component={Horario} />
                  
                                         <Route exact path='/sucursales' component={CatalogoSucursales} /> 
                                         <Route exact path='/registrarsucursal' component={RegistrarSucursal} />
                                         <Route exact path='/actualizarsucursal/:id' component={ActualizarSucursal} />

                                         <Route exact path='/reportes' component={Reportes} />

                                         <Route exact path='/prestamos' component={Prestamos} />

                                         <Route exact path='/nomina' component={Nomina} />
                                         <Route exact path='/departamentos' component={CatalogoDepartamentos} />
                      
                                     </Switch>   

            </BrowserRouter>
        )
}

const mapStateToProps = state => {
    return {
        addSesion: state.SesionReducer.addSesion
    }
  }
  
  const mapDispatchToProps = dispatch => {
    return {
        
    }
  }

export default connect(mapStateToProps, mapDispatchToProps)(Routes);
