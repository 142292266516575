import * as actionTypes from './actionTypes';

const initialState = {
    sucursales: undefined,
    serviceModified: undefined,
    addSucursal: undefined,
    alterSucursal: undefined,
    deleteSucursal: undefined,
    sucursalById: undefined
};

export default function SucursalesReducer(state, action) {
    if (typeof state === 'undefined') {
        return initialState;
    }
    switch (action.type) {
        case actionTypes.getAllSucursales:
            return Object.assign({}, state, {
                sucursales: action.payload,
            });
        case actionTypes.modifyService:
            return Object.assign({}, state, {
                serviceModified: action.payload,
            });
        case actionTypes.AddSucursal:
            return Object.assign({}, state, {
                addSucursal: action.payload,
            });
            case actionTypes.GetSucursalById:
                return Object.assign({}, state, {
                    sucursalById: action.payload,
            });
            case actionTypes.AlterSucursales:
                return Object.assign({}, state, {
                    alterSucursal: action.payload,
            });
            case actionTypes.DeleteSucursal:
                return Object.assign({}, state, {
                    deleteSucursal: action.payload,
            });
        default: return state;
    }
}