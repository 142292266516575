import * as actionTypes from './actionTypes';

const initialState = {
    empleados: undefined,
    empleadoModified: undefined,
    addEmpleado: undefined,
    alterEmpleado: undefined,
    deleteEmpleado: undefined,
    EmpleadoById: undefined,
    sucursales: undefined,
    sucursalById: undefined,
    horarios: undefined,
    departamentos: undefined,
    departamentoById: undefined,
};

export default function EmpleadosReducer(state, action) {
    if (typeof state === 'undefined') {
        return initialState;
    }
    switch (action.type) {
        case actionTypes.GetEmpleados:
            return Object.assign({}, state, {
                empleados: action.payload,
            });
            case actionTypes.modifyEmpleados:
                return Object.assign({}, state, {
                    empleadoModified: action.payload,
            });
            case actionTypes.AddEmpleado:
                    return Object.assign({}, state, {
                        addEmpleado: action.payload,
            });
            case actionTypes.GetEmpleadoById:
                return Object.assign({}, state, {
                    EmpleadoById: action.payload,
            });
            case actionTypes.AlterEmpleado:
                return Object.assign({}, state, {
                    alterEmpleado: action.payload,
            });
            case actionTypes.DeleteEmpleado:
                return Object.assign({}, state, {
                    deleteEmpleado: action.payload,
            });
            case actionTypes.Sucursales:
                return Object.assign({}, state, {
                    sucursales: action.payload,
            });
            case actionTypes.GetSucursalById:
                return Object.assign({}, state, {
                    sucursalById: action.payload,
            });
            case actionTypes.GetHorarios:
                return Object.assign({}, state, {
                    horarios: action.payload,
            });
            case actionTypes.GetDepartamentos:
                return Object.assign({}, state, {
                    departamentos: action.payload,
            });
            case actionTypes.GetDepartamentoById:
                return Object.assign({}, state, {
                    departamentoById: action.payload,
            });
        default: return state;
    }
}