import * as actionTypes from './actionTypes';

const initialState = {
    prestamos: undefined,
    prestamosModified: undefined,
    prestamosByIdSucursal: undefined,
    sucursales: undefined,
    empleados: undefined,
    prestamoGenerado: undefined,
};

export default function PrestamosReducer(state, action) {
    if (typeof state === 'undefined') {
        return initialState;
    }
    switch (action.type) {
        case actionTypes.GetPrestamosByIdSucursal:
            return Object.assign({}, state, {
                prestamosByIdSucursal: action.payload,
            });
        case actionTypes.GetSucursales:
            return Object.assign({}, state, {
                sucursales: action.payload,
            });
        case actionTypes.ModificarPrestamo:
            return Object.assign({}, state, {
                prestamosModified: action.payload,
            });
        case actionTypes.Empleados:
            return Object.assign({}, state, {
                empleados: action.payload,
            });
        case actionTypes.AgregarPrestamo:
            return Object.assign({}, state, {
                prestamoGenerado: action.payload,
            });
        default: return state;
    }
}