import * as actionTypes from './actionTypes';

const initialState = {
    horarios: undefined,
    horariosModified: undefined,
    alterHorario: undefined,
    deleteHorario: undefined,
    horarioById: undefined
};

export default function HorariosReducer(state, action) {
    if (typeof state === 'undefined') {
        return initialState;
    }
    switch (action.type) {
        case actionTypes.GetHorarios:
            return Object.assign({}, state, {
                horarios: action.payload,
            });
            case actionTypes.modifyHorarios:
                return Object.assign({}, state, {
                    horariosModified: action.payload,
            });
            case actionTypes.AddHorario:
                return Object.assign({}, state, {
                    AddHorario: action.payload,
            });
            case actionTypes.GetHorarioById:
                return Object.assign({}, state, {
                    horarioById: action.payload,
            });
            case actionTypes.AlterHorario:
                return Object.assign({}, state, {
                    alterHorario: action.payload,
            });
            case actionTypes.DeleteHorario:
                return Object.assign({}, state, {
                    deleteHorario: action.payload,
            });
        default: return state;
    }
}