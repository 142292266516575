import React from 'react';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import { makeStyles, createMuiTheme } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import Menu from '../Menu/index';
import {
  Redirect
} from "react-router-dom";
import * as SucursalesActions from '../../Api/Sucursales/actions';
import { connect } from 'react-redux';
import Snackbar from '@material-ui/core/Snackbar';


const useStyles = makeStyles((theme) => ({
    paper: {
      marginTop: theme.spacing(4),
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
    },
    form: {
      width: '100%', 
      marginTop: theme.spacing(3),
    },
    submit: {
      margin: theme.spacing(3, 0, 2),
    },
    root: {
        display: 'flex',
      },
     toolbar: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-end',
        padding: theme.spacing(0, 1),
        // necessary for content to be below app bar
        ...theme.mixins.toolbar,
      },
      content: {
        flexGrow: 1,
        padding: theme.spacing(3),
      },
  }));

  const theme = createMuiTheme();

function RegistrarSucursal(props) {
    const [redirect, setRedirect] = React.useState(false);
    const [nombre, setNombre]  = React.useState('');
    const [messageSnackbar, setMessageSnackbar] = React.useState('');

    const classes = useStyles();

    const onComeBackToSucursalList = () => {
      setRedirect(true);
    }

    if (redirect){
      return <Redirect to='/sucursales' /> 
    }

    const onChangeNombre = (e) => {
      setNombre(e.target.value);
    }

    const onRegistrarSucursal = () => {
      const sucursal = {
        nombre
      };
      console.log(props.addSesion);
      const idUsuario = props.addSesion.user.id;

      props.AddSucursal(sucursal, idUsuario).then((result) => {
          if (result && result.message) {
            setMessageSnackbar(result.message);
          }
      });

    }

    return(
        <div className={classes.root}>
        <Menu title="Administración de servicios"/>
        <main className={classes.content}>
          <div className={classes.toolbar} />
          <Container component="main" maxWidth="sm">
      <div className={classes.paper}>
        <Typography variant="h4">
          Registro de sucursales
        </Typography>
        <form className={classes.form}>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={6} >
              <TextField
                name="idServicio"
                variant="outlined"
                required
                fullWidth
                id="txtServicio"
                label="ID sucursal"
                disabled
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                name="Nombre"
                variant="outlined"
                fullWidth
                id="txtNombre"
                label="Nombre"
                value={nombre}
                onChange={onChangeNombre}
              />
            </Grid>
            <Grid item xs={12} sm={6} >
            <Button
            fullWidth
            variant="outlined"
            color="secondary"
            onClick={onComeBackToSucursalList}
          >
            Regresar
          </Button>
            </Grid>
                        <Grid item xs={12} sm={6} >
            <Button
            fullWidth
            variant="outlined"
            color="primary"
            onClick={onRegistrarSucursal}
          >
            Registrar
          </Button>
            </Grid>
          </Grid>
        </form>
      </div>
    </Container>

    <Snackbar
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
        open={Boolean(messageSnackbar)}
        onClose={()=>setMessageSnackbar('')}
        message={messageSnackbar}
        autoHideDuration={6000}
      />
        </main>
      </div>
    )

}

const mapStateToProps = state => {
  return {
    AddSucursal: state.SucursalesReducer.addSucursal,
    addSesion: state.SesionReducer.addSesion,
  }
}

const mapDispatchToProps = dispatch => {
  return {
    AddSucursal: (sucursal, idUsuario) => dispatch(SucursalesActions.AddSucursal(sucursal, idUsuario)),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(RegistrarSucursal);
