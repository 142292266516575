export const getAllSucursales = 'sucursales/GET_SUCURSALES';

export const AddSucursal = 'sucursales/ADD';

export const GetSucursalById = 'sucursales/GETBYID';

export const AlterSucursales = 'sucursales/ALTER';

export const DeleteSucursal = 'sucursales/DELETE';

export const modifyService = 'services/MODIFY';
