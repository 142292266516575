export const GetSucursalesActivas = 'nomina/GET_SUCURSALES_ACTIVAS_NOMINA';

export const GetNominaFechas = 'nomina/NOMINA_FECHAS';

export const GenerarNomina = 'nomina/GENERAR_POR_SUCURSAL';

export const GetEmpleadosPorSucursal = 'nomina/EMPLEADOS_POR_SUCURSAL';

export const Empleados = 'prestamos/EMPLEADOS';

export const Generar = 'nomina/GENERAR';

export const NominaInRevision = 'nomina/IN_REVISION';

export const NominaFechaAnterior = 'nomina/FECHA_ANTERIOR';

export const NominaFechaSearch = 'nomina/NOMINA_FECHAS_SEARCH';

export const AgregarPenalizacion = 'nomina/PENALIZACION_AGREGAR';

export const GetPenalizacionesByNomina = 'nomina/PENALIZACION_GET_NOMINA';

export const EliminarPenalizacion = 'nomina/PENALIZACION_ELIMINAR';

export const GetNominasValidadas = 'nomina/VALIDADAS';

export const ValidarNomina = 'nomina/VALIDAR';