import React, { useEffect } from 'react';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import Menu from '../../modulos/Menu/index';
import { Redirect } from "react-router-dom";
import { connect } from 'react-redux';
import Snackbar from '@material-ui/core/Snackbar';
import Box from '@material-ui/core/Box';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import * as ReportesAction from '../../Api/Reportes/actions';

const useStyles = makeStyles((theme) => ({
    paper: {
        marginTop: theme.spacing(4),
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
    },
    form: {
        width: '100%',
        marginTop: theme.spacing(3),
    },
    submit: {
        margin: theme.spacing(3, 0, 2),
    },
    formControl: {
        minWidth: 120,
        width: '100%',
    },
    selectEmpty: {
        marginTop: theme.spacing(3),
    },
    root: {
        display: 'flex',
    },
    toolbar: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-end',
        padding: theme.spacing(0, 1),
        // necessary for content to be below app bar
        ...theme.mixins.toolbar,
    },
    content: {
        flexGrow: 1,
        padding: theme.spacing(3),
    },
}));

function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box p={3}>
                    <Typography>{children}</Typography>
                </Box>
            )}
        </div>
    );
}


function a11yProps(index) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}

const tabStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
        backgroundColor: theme.palette.background.paper,
    },
}));


function Reportes(props) {
    const [redirect, setRedirect] = React.useState(false);
    const [messageSnackbar, setMessageSnackbar] = React.useState('');


    const [permit, setPermit] = React.useState(true);

    const [value, setValue] = React.useState(0);

    const classes = useStyles();

    const tabClasses = tabStyles();

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    useEffect(() => {
        if (permit) {
            if (props.addSesion && props.addSesion.user && props.addSesion.user.admin){
                props.getReportesEmpleados();
                props.getReportesSucursales();
                props.getReportesUsuarios();
            } else {
                props.getReportesEmpleados().then(result => {
                    if (result && Array.isArray(result)) {
                        setPermit(false);
                    }
                });
                props.getReportesSucursales().then(result => {
                    if (result && Array.isArray(result)) {
                        setPermit(false);
                    }
                });
            }
        }
        setPermit(false);
    }, [permit]);


    if (redirect) {
        return <Redirect to='/catalogoempleados' />
    }


    const infoEmpleados = (info) => {
        return(
            <div>
                <div> idEmpleado: {info.id}</div>
                <div> Nombre: {info.nombre}</div>
                <div> Primer Apellido: {info.primerApellido}</div>
                <div> Segundo Apellido: {info.segundoApellido}</div>
                <div> rfc: {info.rfc}</div>
                <div> idhorario: {info.idHorario}</div>
                <div> Dirección: {info.direccion}</div>
                <div> Fecha de nacimiento: {info.fechaNacimiento}</div>
                <div> Sueldo: {info.sueldo}</div>
                <div> Clave: {info.clave}</div>
                <div> id sucursal: {info.idSucursal}</div>
                <div> estatus: {info.status}</div>

            </div>
        )
    }

    const infoSucursal = (info) => {
        return(
            <div>
                <div> idSucursal: {info.id}</div>
                <div> Nombre: {info.nombre}</div>
                <div> status: {info.status}</div>
            </div>
        )
    }

    const infoUsuarios = (info) => {
        return(
            <div>
                <div> idUsuario: {info.id}</div>
                <div> username: {info.username}</div>
                <div> status: {info.status}</div>
                <div> administrador: {info.admin ? "SI" : "NO"}</div>
            </div>
        )
    }

    return (
        <div className={classes.root}>
            <Menu title="Administración de Reportes" />
            <main className={classes.content}>
                <div className={classes.toolbar} />
                <Container component="main" maxWidth="lg">
                    <div className={classes.paper}>
                        <Typography variant="h4">
                            Reportes
                        </Typography>
                        <form className={classes.form}>
                            <Grid container spacing={3}>
                                <Grid item xs={12} sm={12}>
                                    <div className={tabClasses.root}>
                                        <AppBar position="static">
                                            <Tabs value={value} onChange={handleChange} aria-label="simple tabs example">
                                                <Tab label="Empleados" {...a11yProps(0)} />
                                                <Tab label="Sucursales" {...a11yProps(1)} />
                                                <Tab label="Prestamos" {...a11yProps(2)} />
                                                <Tab label="Usuarios" {...a11yProps(3)} />
                                                <Tab label="Caja de ahorro" {...a11yProps(4)} />
                                                <Tab label="Errores de sistema" {...a11yProps(5)} />
                                            </Tabs>
                                        </AppBar>
                                        <TabPanel value={value} index={0}>
                                            <TableContainer component={Paper}>
                                                <Table className={classes.table} aria-label="simple table">
                                                    <TableHead>
                                                        <TableRow>
                                                            <TableCell>Id reporte</TableCell>
                                                            <TableCell >Información previa</TableCell>
                                                            <TableCell >Información nueva</TableCell>
                                                            <TableCell >Usuarió que realizó la acción</TableCell>
                                                            <TableCell >Estatus</TableCell>
                                                            <TableCell >Fecha de ejecución</TableCell>
                                                        </TableRow>
                                                    </TableHead>
                                                    <TableBody>
                                                        {props.reporteEmpleados && props.reporteEmpleados.listadoReportes.map((item) => (
                                                            <TableRow key={item.empleados.id}>
                                                                <TableCell component="th" scope="row">
                                                                    {item.id}
                                                                </TableCell>
                                                                <TableCell component="th" scope="row">
                                                                    {item.empleadosInfoVieja ? infoEmpleados(item.empleadosInfoVieja) : 'No disponible'}
                                                                </TableCell>
                                                                <TableCell >{infoEmpleados(item.empleados)}</TableCell>
                                                                <TableCell >{item.usuario.username}</TableCell>
                                                                <TableCell >{item.status}</TableCell>
                                                                <TableCell >{item.createdAt}</TableCell>
                                                            </TableRow>
                                                        ))}  
                                                    </TableBody>
                                                </Table>
                                            </TableContainer>
                                        </TabPanel>
                                        <TabPanel value={value} index={1}>

                                        <TableContainer component={Paper}>
                                                <Table className={classes.table} aria-label="simple table">
                                                    <TableHead>
                                                        <TableRow>
                                                            <TableCell> Id reporte</TableCell>
                                                            <TableCell >Información previa</TableCell>
                                                            <TableCell >Información nueva</TableCell>
                                                            <TableCell >Usuarió que realizó la acción</TableCell>
                                                            <TableCell >Estatus</TableCell>
                                                            <TableCell >Fecha de ejecución</TableCell>
                                                        </TableRow>
                                                    </TableHead>
                                                    <TableBody>
                                                        {props.reporteSucursales && props.reporteSucursales.listadoReportes.map((item) => (
                                                            <TableRow key={item.sucursalNuevoRegistro.id}>
                                                                <TableCell component="th" scope="row">
                                                                    {item.id}
                                                                </TableCell>
                                                                <TableCell component="th" scope="row">
                                                                    {item.sucursalUltimoRegistro ? infoSucursal(item.sucursalUltimoRegistro) : 'No disponible'}
                                                                </TableCell>
                                                                <TableCell >{infoSucursal(item.sucursalNuevoRegistro)}</TableCell>
                                                                <TableCell >{item.usuario.username}</TableCell>
                                                                <TableCell >{item.status}</TableCell>
                                                                <TableCell >{item.createdAt}</TableCell>
                                                            </TableRow>
                                                        ))}  
                                                    </TableBody>
                                                </Table>
                                            </TableContainer>
                                        </TabPanel>
                                        <TabPanel value={value} index={2}>
                                        En desarrollo...
                                        </TabPanel>
                                        <TabPanel value={value} index={3}>
                                        <TableContainer component={Paper}>
                                                <Table className={classes.table} aria-label="simple table">
                                                    <TableHead>
                                                        <TableRow>
                                                            <TableCell>Id reporte</TableCell>
                                                            <TableCell >Información previa</TableCell>
                                                            <TableCell >Información nueva</TableCell>
                                                            <TableCell >Usuarió que realizó la acción</TableCell>
                                                            <TableCell >Estatus</TableCell>
                                                            <TableCell >Fecha de ejecución</TableCell>
                                                        </TableRow>
                                                    </TableHead>
                                                    <TableBody>
                                                        {props.reporteUsuarios && props.reporteUsuarios.listadoReportes.map((item) => (
                                                            <TableRow key={item.usuarioReporte.id}>
                                                                <TableCell component="th" scope="row">
                                                                    {item.id}
                                                                </TableCell>
                                                                <TableCell component="th" scope="row">
                                                                    {item.usuarioUltimoRegistro ? infoSucursal(item.usuarioUltimoRegistro) : 'No disponible'}
                                                                </TableCell>
                                                                <TableCell >{infoUsuarios(item.usuarioReporte)}</TableCell>
                                                                <TableCell >{item.usuario.username}</TableCell>
                                                                <TableCell >{item.status}</TableCell>
                                                                <TableCell >{item.createdAt}</TableCell>
                                                            </TableRow>
                                                        ))}  
                                                    </TableBody>
                                                </Table>
                                            </TableContainer>
                                        </TabPanel>
                                        <TabPanel value={value} index={4}>
                                        En desarrollo...
                                        </TabPanel>
                                        <TabPanel value={value} index={5}>
                                        En desarrollo...
                                        </TabPanel>
                                    </div>
                                </Grid>
                            </Grid>
                        </form>
                    </div>
                </Container>

                <Snackbar
                    anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
                    open={Boolean(messageSnackbar)}
                    onClose={() => setMessageSnackbar('')}
                    message={messageSnackbar}
                    autoHideDuration={6000}
                />
            </main>
        </div>
    )

}

const mapStateToProps = state => {
    return {
        reporteEmpleados: state.ReporteReducer.reporteEmpleados,
        reporteSucursales: state.ReporteReducer.reporteSucursales,
        addSesion: state.SesionReducer.addSesion,
        reporteUsuarios: state.ReporteReducer.reporteUsuarios,
    }
}

const mapDispatchToProps = dispatch => {
    return {
        getReportesEmpleados: () => dispatch(ReportesAction.getAllReportesEmpleados()),
        getReportesSucursales:() => dispatch(ReportesAction.getAllReportesSucursales()),
        getReportesUsuarios:() => dispatch(ReportesAction.getAllReportesUsuarios()),

    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Reportes);
