import React, {useEffect } from 'react';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import { createMuiTheme } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Add from '@material-ui/icons/Add';
import Paper from '@material-ui/core/Paper';
import Menu from '../Menu/index';
import { connect } from 'react-redux';
import * as DepartamentosActions from '../../Api/Departamentos/actions';
import { Redirect } from 'react-router';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Snackbar from '@material-ui/core/Snackbar';
import { TextField } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
    table: {
        minWidth: 800,
      },
    paper: {
      marginTop: theme.spacing(4),
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
    },
    form: {
      width: '100%', 
      marginTop: theme.spacing(3),
    },
    submit: {
      margin: theme.spacing(3, 0, 2),
    },
    root: {
        display: 'flex',
      },
     toolbar: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-end',
        padding: theme.spacing(0, 1),
        // necessary for content to be below app bar
        ...theme.mixins.toolbar,
      },
      content: {
        flexGrow: 1,
        padding: theme.spacing(3),
      },
  }));

  const theme = createMuiTheme();

function CatalogoDepartamentos(props) {
  const [redirect, setRedirect] = React.useState(false);
  const [redirectAlter, setRedirectAlter] = React.useState(false);
  const [idSucursal, setIdSucursal] = React.useState('');
  const [permit, setPermit] = React.useState(true);
  const [open, setOpen] = React.useState(false);
  const [idDepartamentoToDelete, setIdDepartamentoToDelete] = React.useState('');
  const [messageSnackbar, setMessageSnackbar] = React.useState('');
  const [descripcionDepartamento, setDescripcionDepartamento] = React.useState('');
  const [openAgregar, setOpenAgregar] = React.useState(false);


  useEffect(() => {
    if(permit){
      props.getAllDepartamentos().then(result => {
        if (result && result.id){
          setPermit(false);
        }
      });
    }
    }, [permit]); 

    console.log(props);
  
    const handleClose = () => {
      setOpen(false);
    };

    const handleCloseAgregar = () => {
        setOpenAgregar(false);
      };

    const classes = useStyles();

    const onRedirectToAddSucursal = () => {
      setRedirect(true);
    }

    const onRedirectToModifySucursal = (id) => {
      setIdSucursal(id);
      setRedirectAlter(true);
    }

    const openModalEliminarDepartamento = (id) => {
      setIdDepartamentoToDelete(id);
      setOpen(true);
    }

    const openModalAgregarDepartamento = (id) => {
        setOpenAgregar(true);
      }

    const handleEliminarDepartamento = () => {
      const idUsuario = props.addSesion.user.id;
      props.eliminarDepartamento(idDepartamentoToDelete, idUsuario).then(result => {
        if (result && result.message) {
          setOpen(false);
          setMessageSnackbar(result.message);
          setIdDepartamentoToDelete('');
          props.getAllDepartamentos();
        }
      });
    }

    const handleAgregarDepartamento = () => {
        const idUsuario = props.addSesion.user.id;
        props.agregarDepartamento({descripcion: descripcionDepartamento}, idUsuario).then(result => {
            if (result && result.message) {
              setOpenAgregar(false);
              setMessageSnackbar(result.message);
              setDescripcionDepartamento('');
              props.getAllDepartamentos();
            }
          });
    }

    const onChangeDescripcion = (e) => {
        setDescripcionDepartamento(e.target.value);
      }
  

    if(redirectAlter){
      return <Redirect to={`/actualizarsucursal/${idSucursal}`} /> 
    }
    
    if(redirect){
      return <Redirect to='/registrarsucursal' /> 
    }

    return(
        <div className={classes.root}>
        <Menu title="Administración de departamentos"/>
        <main className={classes.content}>
          <div className={classes.toolbar} />
          <Container component="main" >
      <div className={classes.paper}>
        <Typography variant="h4">
            Departamentos
        </Typography>
           <Grid container spacing={2}>
            <Grid item xs={6} sm={4}>
            <Button
            type="submit"
            fullWidth
            variant="outlined"
            color="primary"
            onClick={openModalAgregarDepartamento}
          >
            <Add />
            Agregar
          </Button>
            </Grid>
           
            <Grid item xs={7} md={12}>
            <TableContainer component={Paper}>
      <Table className={classes.table} size="small" aria-label="a dense table">
        <TableHead>
          <TableRow>
            <TableCell align="center">ID</TableCell>
            <TableCell align="center">Nombre</TableCell>
            <TableCell align="center"></TableCell>
            <TableCell align="center"></TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {!props.departamentos ? 'Cargando...' :
          props.departamentos.departamentoList.map((departamento) => (
            <TableRow key={departamento.id}>
              <TableCell component="th" scope="row" align="center">
                {departamento.id}
              </TableCell>
              <TableCell align="center">{departamento.descripcion}</TableCell>
              <TableCell align="center">
                <Button
                  type=""
                  variant="outlined"
                  color="primary"
                  onClick={() => onRedirectToModifySucursal(departamento.id)}
                  >Actualizar</Button></TableCell>
              <TableCell align="center">
                <Button
                    type=""
                    variant="outlined"
                    color="primary"
                    onClick = {() => openModalEliminarDepartamento(departamento.id)}
                    >
                      Eliminar
                </Button>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
            </Grid>
            <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{"¿Desea eliminar este departamento?"}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Una vez eliminado, no se podrá revertir está acción
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary" autoFocus>
            Cerrar
          </Button>
          <Button onClick={handleEliminarDepartamento} color="primary">
            Eliminar
          </Button>
        </DialogActions>
      </Dialog>


      <Dialog
        open={openAgregar}
        onClose={handleCloseAgregar}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{"Agregar Departamento"}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Favor de agregar la descripción del departamento
          </DialogContentText>
          <TextField
                name="descripcion"
                variant="outlined"
                fullWidth
                id="txtDescripcionDepartamento"
                label="Descripción departamento"
                value={descripcionDepartamento}
                onChange={onChangeDescripcion}
              />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseAgregar} color="primary" autoFocus>
            Cerrar
          </Button>
          <Button onClick={handleAgregarDepartamento} color="primary">
            Agregar
          </Button>
        </DialogActions>
      </Dialog>
    <Snackbar
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
        open={Boolean(messageSnackbar)}
        onClose={()=>setMessageSnackbar('')}
        message={messageSnackbar}
        autoHideDuration={6000}
      />
          </Grid>
      </div>
    </Container>
        </main>
      </div>
    )

}

const mapStateToProps = state => {
  return {
    departamentos: state.DepartamentosReducer.listadoDepartamentos,
    addSesion: state.SesionReducer.addSesion,
    agregarDepartamento: state.DepartamentosReducer.agregarDepartamento,
    eliminarDpto: state.DepartamentosReducer.eliminarDpto,
  }
}

const mapDispatchToProps = dispatch => {
  return {
    getAllDepartamentos: () => dispatch(DepartamentosActions.getAllDepartamentos()),
    agregarDepartamento: (data, idUsuario) => dispatch(DepartamentosActions.agregarDepartamento(data, idUsuario)),
    eliminarDepartamento: (idDpto, idUsuario) => dispatch(DepartamentosActions.eliminarDepartamento(idDpto, idUsuario)), 
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(CatalogoDepartamentos);
