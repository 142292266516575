import React,  {useEffect } from 'react';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import { createMuiTheme } from '@material-ui/core/styles';
import Menu from '../Menu/index';
import { Redirect } from "react-router-dom";
import * as SucursalesActions from '../../Api/Sucursales/actions';
import { connect } from 'react-redux';
import Snackbar from '@material-ui/core/Snackbar';

const useStyles = makeStyles((theme) => ({
    paper: {
      marginTop: theme.spacing(4),
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
    },
    form: {
      width: '100%', 
      marginTop: theme.spacing(3),
    },
    submit: {
      margin: theme.spacing(3, 0, 2),
    },
    root: {
        display: 'flex',
      },
     toolbar: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-end',
        padding: theme.spacing(0, 1),
        // necessary for content to be below app bar
        ...theme.mixins.toolbar,
      },
      content: {
        flexGrow: 1,
        padding: theme.spacing(3),
      },
  }));

  const theme = createMuiTheme();

function ActualizarSucursal(props) {
  const [permit, setPermit] = React.useState(true);
  const [messageSnackbar, setMessageSnackbar] = React.useState('');
  const [redirect, setRedirect] = React.useState(false);
  const [nombre, setNombre]  = React.useState('');

    const classes = useStyles();

    useEffect(() => {
      if(permit){
        props.GetById(props.match.params.id).then(result => {
          if (result && !result.message){
            setMessageSnackbar(result.message);
            setPermit(false);
          }
          if(result && result.sucursal){
            setNombre(result.sucursal.nombre);
          }
        });
      }
      }, [permit]); 

      const goToActualizarSucursal = () => {
        const sucursal = {
          nombre: nombre,
          status: "ACTIVO",
          id: props.match.params.id
        };

        const idUsuario = props.addSesion.user.id;
    
        props.AlterSucursal(sucursal, idUsuario).then((result) => {
            if (result && result.message) {
              setMessageSnackbar(result.message);
              if(!result.error){
                props.GetById(props.match.params.id).then((resultById)=> {
                  setNombre(resultById.sucursal.nombre);
                });
              }
            }
        });
      }

      const onComeBackToServiciosList = () => {
        setRedirect(true);
      }
  
      if (redirect){
        return <Redirect to='/sucursales' /> 
      }
  

    return(
        <div className={classes.root}>
        <Menu title="Administración de servicios"/>
        <main className={classes.content}>
          <div className={classes.toolbar} />
          <Container component="main" maxWidth="sm">
      <div className={classes.paper}>
        <Typography variant="h4">
          Actualización de servicios
        </Typography>
        <form className={classes.form}>
        <Grid container spacing={2}>
            <Grid item xs={12} sm={6} >
              <TextField
                name="idServicio"
                variant="outlined"
                fullWidth
                id="txtServicio"
                label="ID Sucursal"
                disabled
                value={props.sucursalById && props.sucursalById.sucursal ? props.sucursalById.sucursal.id: ''}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                name="Nombre"
                variant="outlined"
                fullWidth
                id="txtNombre"
                label="Nombre"
                value={nombre }
                onChange={(e) => setNombre(e.target.value)}
              />
            </Grid>

            <Grid item xs={12} sm={6} >
            <Button
            onClick={onComeBackToServiciosList}
            fullWidth
            variant="outlined"
            color="secondary"
          >
            Regresar
          </Button>
            </Grid>
            <Grid item xs={12} sm={6} >
            <Button
            fullWidth
            variant="outlined"
            color="primary"
            onClick={goToActualizarSucursal}
          >
            Actualizar
          </Button>
            </Grid>
          </Grid>
        </form>
      </div>
    </Container>
    <Snackbar
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
        open={Boolean(messageSnackbar)}
        onClose={()=>setMessageSnackbar('')}
        message={messageSnackbar}
        autoHideDuration={6000}
      />
        </main>
      </div>
    )

}

const mapStateToProps = state => {
  return {
    sucursalById: state.SucursalesReducer.sucursalById,
    alterSucursal: state.SucursalesReducer.alterSucursal,
    addSesion: state.SesionReducer.addSesion,
  }
}

const mapDispatchToProps = dispatch => {
  return {
    AlterSucursal: (sucursal, idUsuario) => dispatch(SucursalesActions.AlterSucursal(sucursal, idUsuario)),
    GetById: (id) => dispatch(SucursalesActions.GetSucursalById(id)),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(ActualizarSucursal);
