import * as actionTypes from './actionTypes';
import { connector } from '../connector/index';

export const getAllSucursales = () => {
    return async (dispatch) => {
        const result = await connector(`/sucursales`, 'GET', {},{}).then((response) => {
            dispatch({
                type: actionTypes.getAllSucursales,
                payload: response.data,
            });
            return response;
        }).catch(err => {
            dispatch({
                type: actionTypes.getAllSucursales,
                payload: err.data,
            });
            return err.response;
        });
        return result && result.response ? result.response.data : result.data;
    }
}

export const AddSucursal = (SucursalBody, idUsuario) => {
    return async (dispatch) => {
        const result = await connector(`/sucursales?idUsuario=${idUsuario}`, 'PUT', SucursalBody,{}).then((response) => {
            dispatch({
                type: actionTypes.AddSucursal,
                payload: response.data,
            });
            return response;
        }).catch(err => {
            dispatch({
                type: actionTypes.AddSucursal,
                payload: err.data,
            });
            return err.response;
        });
        return result && result.response ? result.response.data : result.data;
    } 
}

export const GetSucursalById = (id) => {
    return async (dispatch) => {
        const result = await connector(`/sucursales/by-id?id=${id}`, 'GET', {},{}).then(
            (response) => {
            dispatch({
                type: actionTypes.GetSucursalById,
                payload: response.data,
            });
            return response;
        }).catch(err => {
            dispatch({
                type: actionTypes.GetSucursalById,
                payload: err.data,
            });
            return err.response;
        });
        return result && result.response ? result.response.data : result.data;
    }
}



export const AlterSucursal = (SucursalBody, idUsuario) => {
    return async (dispatch) => {
        const result = await connector(`/sucursales?idUsuario=${idUsuario}`, 'POST', SucursalBody,{}).then((response) => {
            dispatch({
                type: actionTypes.AlterSucursales,
                payload: response.data,
            });
            return response;
        }).catch(err => {
            dispatch({
                type: actionTypes.AlterSucursales,
                payload: err.data,
            });
            return err.response;
        });
        return result && result.response ? result.response.data : result.data;
    } 
}

export const DeleteSucursal = (sucursalId, idUsuario) => {
    return async (dispatch) => {
        const result = await connector(`/sucursales?id=${sucursalId}&idUsuario=${idUsuario}`, 'DELETE', {},{}).then((response) => {
            dispatch({
                type: actionTypes.DeleteSucursal,
                payload: response.data,
            });
            return response;
        }).catch(err => {
            dispatch({
                type: actionTypes.DeleteSucursal,
                payload: err.data,
            });
            return err.response;
        });
        return result && result.response ? result.response.data : result.data;
    } 
}