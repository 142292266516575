import * as actionTypes from './actionTypes';

const initialState = {
    equipos: undefined,
    equiposModified: undefined,
    alterEquipo: undefined,
    deleteEquipo: undefined,
    equipoById: undefined
};

export default function EquiposReducer(state, action) {
    if (typeof state === 'undefined') {
        return initialState;
    }
    switch (action.type) {
        case actionTypes.GetEquipos:
            return Object.assign({}, state, {
                equipos: action.payload,
            });
            case actionTypes.modifyEquipos:
                return Object.assign({}, state, {
                    equiposModified: action.payload,
            });
            case actionTypes.AddEquipo:
                return Object.assign({}, state, {
                    addEquipo: action.payload,
            });
            case actionTypes.GetEquipoById:
                return Object.assign({}, state, {
                    equipoById: action.payload,
            });
            case actionTypes.AlterEquipo:
                return Object.assign({}, state, {
                    alterEquipo: action.payload,
            });
            case actionTypes.DeleteEquipo:
                return Object.assign({}, state, {
                    deleteEquipo: action.payload,
            });
        default: return state;
    }
}