import * as actionTypes from './actionTypes';
import { connector } from '../connector/index';

export const getAllReportesEmpleados = () => {
    return async (dispatch) => {
        const result = await connector(`/reportes/empleados`, 'GET', {},{}).then((response) => {
            dispatch({
                type: actionTypes.GetReportesEmpleados,
                payload: response.data,
            });
            return response;
        }).catch(err => {
            dispatch({
                type: actionTypes.GetReportesEmpleados,
                payload: err.data,
            });
            return err.response;
        });
        return result && result.response ? result.response.data : result.data;
    }
}

export const getAllReportesSucursales = () => {
    return async (dispatch) => {
        const result = await connector(`/reportes/sucursales`, 'GET', {},{}).then((response) => {
            dispatch({
                type: actionTypes.GetReportesSucursales,
                payload: response.data,
            });
            return response;
        }).catch(err => {
            dispatch({
                type: actionTypes.GetReportesSucursales,
                payload: err.data,
            });
            return err.response;
        });
        return result && result.response ? result.response.data : result.data;
    }
}

export const getAllReportesUsuarios = () => {
    return async (dispatch) => {
        const result = await connector(`/reportes/usuarios`, 'GET', {},{}).then((response) => {
            dispatch({
                type: actionTypes.GetReportesUsuarios,
                payload: response.data,
            });
            return response;
        }).catch(err => {
            dispatch({
                type: actionTypes.GetReportesUsuarios,
                payload: err.data,
            });
            return err.response;
        });
        return result && result.response ? result.response.data : result.data;
    }
}