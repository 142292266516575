import * as actionTypes from './actionTypes';
import { connector } from '../connector/index';

export const getAllPrestamosByidSucursal = (idSucursal) => {
    return async (dispatch) => {
        const result = await connector(`/prestamos/by-sucursal?sucursal=${idSucursal}`, 'GET', {},{}).then((response) => {
            dispatch({
                type: actionTypes.GetPrestamosByIdSucursal,
                payload: response.data,
            });
            return response;
        }).catch(err => {
            dispatch({
                type: actionTypes.GetPrestamosByIdSucursal,
                payload: err.data,
            });
            return err.response;
        });
        return result && result.response ? result.response.data : result.data;
    }
}

export const GetSucursales = () => {
    return async (dispatch) => {
        const result = await connector(`/sucursales`, 'GET', {},{}).then((response) => {
            dispatch({
                type: actionTypes.GetSucursales,
                payload: response.data,
            });
            return response;
        }).catch(err => {
            dispatch({
                type: actionTypes.GetSucursales,
                payload: err.data,
            });
            return err.response;
        });
        return result && result.response ? result.response.data : result.data;
    } 
}

export const UpdateStatus = (prestamo) => {
    return async (dispatch) => {
        const result = await connector(`/prestamos/update-status`, 'POST', prestamo, {}).then((response) => {
            dispatch({
                type: actionTypes.ModificarPrestamo,
                payload: response.data,
            });
            return response;
        }).catch(err => {
            dispatch({
                type: actionTypes.ModificarPrestamo,
                payload: err.data,
            });
            return err.response;
        });
        return result && result.response ? result.response.data : result.data;
    } 
}

export const EmpleadosParaPrestamo = () => {
    return async (dispatch) => {
        const result = await connector(`/prestamos/empleados`, 'GET', {}, {}).then((response) => {
            dispatch({
                type: actionTypes.Empleados,
                payload: response.data,
            });
            return response;
        }).catch(err => {
            dispatch({
                type: actionTypes.Empleados,
                payload: err.data,
            });
            return err.response;
        });
        return result && result.response ? result.response.data : result.data;
}
}

export const GenerarPrestamo = (data) => {
    return async (dispatch) => {
        const result = await connector(`/prestamos/`, 'POST', data, {}).then((response) => {
            dispatch({
                type: actionTypes.AgregarPrestamo,
                payload: response.data,
            });
            return response;
        }).catch(err => {
            dispatch({
                type: actionTypes.AgregarPrestamo,
                payload: err.data,
            });
            return err.response;
        });
        return result && result.response ? result.response.data : result.data;
}
}