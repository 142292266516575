import * as actionTypes from './actionTypes';

const initialState = {
    reporteEmpleados: undefined,
    reporteSucursales: undefined,
    reporteUsuarios: undefined,
};

export default function ReporteReducer(state, action) {
    if (typeof state === 'undefined') {
        return initialState;
    }
    switch (action.type) {
        case actionTypes.GetReportesEmpleados:
            return Object.assign({}, state, {
                reporteEmpleados: action.payload,
            });
        case actionTypes.GetReportesSucursales:
            return Object.assign({}, state, {
                reporteSucursales: action.payload,
            });
        case actionTypes.GetReportesUsuarios:
            return Object.assign({}, state, {
                reporteUsuarios: action.payload,
            });
        default: return state;
    }
}