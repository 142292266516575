import * as actionTypes from './actionTypes';

const initialState = {
    puestos: undefined,
    puestosModified: undefined,
    puestoById: undefined,
    alterPuesto: undefined,
    deletePuesto: undefined
};

export default function PuestosReducer(state, action) {
    if (typeof state === 'undefined') {
        return initialState;
    }
    switch (action.type) {
        case actionTypes.GetPuestos:
            return Object.assign({}, state, {
                puestos: action.payload,
            });
            case actionTypes.modifyPuestos:
                return Object.assign({}, state, {
                    puestosModified: action.payload,
            });
            case actionTypes.AddPuesto:
                return Object.assign({}, state, {
                    addPuesto: action.payload,
            });
            case actionTypes.GetPuetsoById:
                return Object.assign({}, state, {
                    puestoById: action.payload,
            });
            case actionTypes.AlterPuesto:
                return Object.assign({}, state, {
                    alterPuesto: action.payload,
            });
            case actionTypes.DeletePuesto:
                return Object.assign({}, state, {
                    deletePuesto: action.payload,
            });
        default: return state;
    }
}