import React from 'react';
import clsx from 'clsx';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import Drawer from '@material-ui/core/Drawer';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import List from '@material-ui/core/List';
import CssBaseline from '@material-ui/core/CssBaseline';
import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';
import IconButton from '@material-ui/core/IconButton';
import MenuIcon from '@material-ui/icons/Menu';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import GroupIcon from '@material-ui/icons/Group';
import PersonIcon from '@material-ui/icons/Person';
import LoyaltyIcon from '@material-ui/icons/Loyalty';
import AppsIcon from '@material-ui/icons/Apps';
import GroupWorkIcon from '@material-ui/icons/GroupWork';
import DeviceHubIcon from '@material-ui/icons/DeviceHub';
import ScheduleIcon from '@material-ui/icons/Schedule';
import LocalGroceryStoreIcon from '@material-ui/icons/LocalGroceryStore';
import AttachMoneyIcon from '@material-ui/icons/AttachMoney';
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import CalendarToday from '@material-ui/icons/CalendarToday';
import AccessTimeIcon from '@material-ui/icons/AccessTime';
import DescriptionIcon from '@material-ui/icons/Description';
import TapanosaMiniLogo from '../../img/logo-chico-tapanosa.png';
import AssignmentIndIcon from '@material-ui/icons/AssignmentInd';

import {
  Redirect
} from "react-router-dom";
import { connect} from 'react-redux';


const drawerWidth = 240;

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
  },
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  appBarShift: {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  menuButton: {
    marginRight: 36,
  },
  hide: {
    display: 'none',
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
    whiteSpace: 'nowrap',
  },
  drawerOpen: {
    width: drawerWidth,
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  drawerClose: {
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    overflowX: 'hidden',
    width: theme.spacing(7) + 1,
    [theme.breakpoints.up('sm')]: {
      width: theme.spacing(9) + 1,
    },
  },
  toolbar: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(3),
  },
  Log1: {
    width: 70,
    marginRight: 5,
  },
  Log2: {
    width: 110,
  },
}));

function Menu(props) {

  const classes = useStyles();
  const theme = useTheme();
  const [open, setOpen] = React.useState(false);
  const [urlRedirect, setUrlRedirect] = React.useState('');

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };

  const handleRedirect = (url) => {
    setUrlRedirect(url);
  }

  if(urlRedirect){
    let url = urlRedirect;
    if (url !== window.location.pathname){
      return <Redirect to={url} /> 
    }
  }

  const admin = localStorage.getItem(0)


  console.log("Storage despues de entrar: "+localStorage.getItem(0))

    return(
        <div className={classes.root}>
      <CssBaseline />
      <AppBar
        position="fixed"
        className={clsx(classes.appBar, {
          [classes.appBarShift]: open,
        })}
      >
        <Toolbar>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            onClick={handleDrawerOpen}
            edge="start"
            className={clsx(classes.menuButton, {
              [classes.hide]: open,
            })}

          >
            <MenuIcon />
          </IconButton>
          <Typography variant="h6" noWrap>
            {props.title}
          </Typography>
        </Toolbar>
      </AppBar>
             <Drawer
             variant="permanent"
             className={clsx(classes.drawer, {
               [classes.drawerOpen]: open,
               [classes.drawerClose]: !open,
             })}
             classes={{
               paper: clsx({
                 [classes.drawerOpen]: open,
                 [classes.drawerClose]: !open,
               }),
             }}
           >
             <div className={classes.toolbar}>
             <img src={TapanosaMiniLogo} style={{ height: '35px', width:'35px' }}/>
               Sistema de prenomina
               <IconButton onClick={handleDrawerClose}><ChevronLeftIcon /></IconButton>
             </div>
             <Divider />
             <List>
                 <ListItem>
                   <ListItemIcon><GroupIcon /></ListItemIcon>
                   <ListItemText primary={"Administrador"}
      />
                 </ListItem>
               </List>
             <List>
             <ListItem button key="Empleados" onClick={() => handleRedirect('/catalogoempleados')} >
                   <ListItemIcon><PersonIcon /></ListItemIcon>
                   <ListItemText primary="Empleados" />
             </ListItem>
             <ListItem button key="Sucursales" onClick={() => handleRedirect('/sucursales')}>
                   <ListItemIcon><AppsIcon /></ListItemIcon>
                   <ListItemText primary="Sucursales" />
             </ListItem>
             <ListItem button key="Departamentos" onClick={() => handleRedirect('/departamentos')}>
                   <ListItemIcon><AssignmentIndIcon /></ListItemIcon>
                   <ListItemText primary="Departamentos" />
              </ListItem>
             </List>
             <Divider />
              <ListItem button key="Prestamos" onClick={() => handleRedirect('/prestamos')}>
                   <ListItemIcon><AttachMoneyIcon /></ListItemIcon>
                   <ListItemText primary="Prestamos" />
              </ListItem>
              <ListItem button key="Nomina" onClick={() => handleRedirect('/nomina')}>
                   <ListItemIcon><AttachMoneyIcon /></ListItemIcon>
                   <ListItemText primary="Nomina" />
              </ListItem>
             <Divider />
             <ListItem button key="Horarios" onClick={() => handleRedirect('/horarios')}>
                   <ListItemIcon><AccessTimeIcon /></ListItemIcon>
                   <ListItemText primary="Horarios" />
              </ListItem>
              <Divider />
              <ListItem button key="Reportes" onClick={() => handleRedirect('/reportes')}>
                   <ListItemIcon><DescriptionIcon /></ListItemIcon>
                   <ListItemText primary="Reportes" />
              </ListItem>
             <List>
               <ListItem button key="Cerrar sesión" onClick={() => window.location.reload()}>
                   <ListItemIcon><ExitToAppIcon /></ListItemIcon>
                   <ListItemText primary="Cerrar sesión" />
             </ListItem>
             </List>
           </Drawer>
    </div>
    )

}


const mapStateToProps = state => {
  return {
      addSesion: state.SesionReducer.addSesion
  }
}

const mapDispatchToProps = dispatch => {
  return {
      
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Menu);
