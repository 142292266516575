import React from 'react';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import Grid from "@material-ui/core/Grid"
import * as SesionActions from '../../Api/Sesion/actions';
import { connect } from 'react-redux';
import Snackbar from '@material-ui/core/Snackbar';
import { Redirect } from "react-router-dom";
import tapanosaLogo from '../../img/tapanosa-logo.png';

const useStyles = makeStyles((theme) => ({
    paper: {
      marginTop: theme.spacing(8),
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
    },
    Log1: {
        width: 200,
      },
      Log2: {
        width: 200,
        marginBottom: 20,
      },
  }));
  
function Login(props) {
    const [redirect, setRedirect] = React.useState(false);
    const [username, setUsuario]  = React.useState('');
    const [password, setContrasena]  = React.useState('');
    const [messageSnackbar, setMessageSnackbar] = React.useState('');

    const classes = useStyles();

    const onIniciarSesion = () => {

      props.AddSesion(username, password).then((result) => {
        setMessageSnackbar(result.message);
          if (result && !result.error) {
            setRedirect(true);
            localStorage.setItem(0,result.user.admin)
            console.log("storage antes de entrar: "+result.user.admin)
          }
        }
      );

    }

   if (redirect){
      return <Redirect to='/principal' /> 
    }
  
    return (
      <Container component="main" maxWidth="xs">
        <div className={classes.paper}>
        <div>
          Sistema de prenomina
        </div>
          <img src={tapanosaLogo} />
          <br />

          <form className={classes.form}>
           
          <Grid container spacing={2}>
            <Grid item xs={12} >
                <Typography variant="h4">
                    Iniciar sesión
                </Typography>
            </Grid>
            <Grid item xs={12} >
              <TextField
                name="idSesion"
                variant="outlined"
                fullWidth
                id="txtUsuario"
                label="Usuario"
                value={username}
                onChange={(e) => setUsuario(e.target.value)}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                name="idContrasena"
                variant="outlined"
                fullWidth
                id="txtContrasena"
                label="Contraseña"
                type="password"
                value={password}
                onChange={(e) => setContrasena(e.target.value)}
              />
            </Grid>
            <Grid item xs={12} >
            <Button
            fullWidth
            variant="contained"
            color="primary"
            onClick={onIniciarSesion}
          >
            Iniciar Sesión
          </Button>
            </Grid>
          </Grid>
          <Snackbar
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
        open={Boolean(messageSnackbar)}
        onClose={()=>setMessageSnackbar('')}
        message={messageSnackbar}
        autoHideDuration={6000}
      />
          </form>
        </div>
      </Container>
      
    );
  }
  
  const mapStateToProps = state => {
    return {
      addSesion: state.SesionReducer.addSesion,
    }
  }
  
  const mapDispatchToProps = dispatch => {
    return {
      AddSesion: (Username, Password) => dispatch(SesionActions.AddSesion(Username, Password)),
    }
  }
  
  export default connect(mapStateToProps, mapDispatchToProps)(Login);
  