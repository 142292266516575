import * as actionTypes from './actionTypes';
import { connector } from '../connector/index';

export const getAllHorarios = () => {
    return async (dispatch) => {
        const result = await connector(`/horario`, 'GET', {},{}).then((response) => {
            dispatch({
                type: actionTypes.GetHorarios,
                payload: response.data,
            });
            return response;
        }).catch(err => {
            dispatch({
                type: actionTypes.GetHorarios,
                payload: err.data,
            });
            return err.response;
        });
        return result && result.response ? result.response.data : result.data;
    }
}

export const AddHorario = (HorarioBody, idUsuario) => {
    return async (dispatch) => {
        const result = await connector(`/horario/add?idUsuario=${idUsuario}`, 'POST', HorarioBody,{}).then((response) => {
            dispatch({
                type: actionTypes.AddHorario,
                payload: response.data,
            });
            return response;
        }).catch(err => {
            dispatch({
                type: actionTypes.AddHorario,
                payload: err.data,
            });
            return err.response;
        });
        return result && result.response ? result.response.data : result.data;
    } 
}

export const GetHorarioById = (id) => {
    return async (dispatch) => {
        const result = await connector(`/horario/by-id?id=${id}`, 'GET', {},{}).then(
            (response) => {
            dispatch({
                type: actionTypes.GetHorarioById,
                payload: response.data,
            });
            return response;
        }).catch(err => {
            dispatch({
                type: actionTypes.GetHorarioById,
                payload: err.data,
            });
            return err.response;
        });
        return result && result.response ? result.response.data : result.data;
    }
}


export const AlterHorario = (HorarioBody, idUsuario) => {
    return async (dispatch) => {
        const result = await connector(`/horario/modificar?idUsuario=${idUsuario}`, 'PUT', HorarioBody,{}).then((response) => {
            dispatch({
                type: actionTypes.AlterHorario,
                payload: response.data,
            });
            return response;
        }).catch(err => {
            dispatch({
                type: actionTypes.AlterHorario,
                payload: err.data,
            });
            return err.response;
        });
        return result && result.response ? result.response.data : result.data;
    } 
} 

export const DeleteHorario = (horarioId, idUsuario) => {
    return async (dispatch) => {
        const result = await connector(`/horario?idHorario=${horarioId}&idUsuario=${idUsuario}`, 'DELETE', {},{}).then((response) => {
            dispatch({
                type: actionTypes.DeleteHorario,
                payload: response.data,
            });
            return response;
        }).catch(err => {
            dispatch({
                type: actionTypes.DeleteHorario,
                payload: err.data,
            });
            return err.response;
        });
        return result && result.response ? result.response.data : result.data;
    } 
}