import * as actionTypes from './actionTypes';
import { connector } from '../connector/index';

export const getEmpleadosPorSucursal = (idSucursal) => {
    return async (dispatch) => {
        const result = await connector(`/employees/by-sucursal?sucursal=${idSucursal}`, 'GET', {},{}).then((response) => {
            dispatch({
                type: actionTypes.GetEmpleados,
                payload: response.data,
            });
            return response;
        }).catch(err => {
            dispatch({
                type: actionTypes.GetEmpleados,
                payload: err.data,
            });
            return err.response;
        });
        return result && result.response ? result.response.data : result.data;
    }
}

export const AddEmpleado = (EmpleadoBody) => {
    return async (dispatch) => {
        const result = await connector(`/employees`, 'PUT', EmpleadoBody,{}).then((response) => {
            dispatch({
                type: actionTypes.AddEmpleado,
                payload: response.data,
            });
            return response;
        }).catch(err => {
            dispatch({
                type: actionTypes.AddEmpleado,
                payload: err.data,
            });
            return err.response;
        });
        return result && result.response ? result.response.data : result.data;
    } 
}

export const GetEmpleadoById = (id) => {
    return async (dispatch) => {
        const result = await connector(`/employees/by-id?id=${id}`, 'GET', {},{}).then(
            (response) => {
            dispatch({
                type: actionTypes.GetEmpleadoById,
                payload: response.data,
            });
            return response;
        }).catch(err => {
            dispatch({
                type: actionTypes.GetEmpleadoById,
                payload: err.data,
            });
            return err.response;
        });
        return result && result.response ? result.response.data : result.data;
    }
}



export const AlterEmpleado = (EmpleadoBody, idUsuario) => {
    return async (dispatch) => {
        const result = await connector(`/employees?idUsuario=${idUsuario}`, 'POST', EmpleadoBody,{}).then((response) => {
            dispatch({
                type: actionTypes.AlterEmpleado,
                payload: response.data,
            });
            return response;
        }).catch(err => {
            dispatch({
                type: actionTypes.AlterEmpleado,
                payload: err.data,
            });
            return err.response;
        });
        return result && result.response ? result.response.data : result.data;
    } 
}

export const DeleteEmpleado = (empleadoId, idUsuario) => {
    return async (dispatch) => {
        const result = await connector(`/employees?idEmployee=${empleadoId}&idUsuario=${idUsuario}`, 'DELETE', {},{}).then((response) => {
            dispatch({
                type: actionTypes.DeleteEmpleado,
                payload: response.data,
            });
            return response;
        }).catch(err => {
            dispatch({
                type: actionTypes.DeleteEmpleado,
                payload: err.data,
            });
            return err.response;
        });
        return result && result.response ? result.response.data : result.data;
    } 
}

export const GetSucursales = () => {
    return async (dispatch) => {
        const result = await connector(`/sucursales`, 'GET', {},{}).then((response) => {
            dispatch({
                type: actionTypes.Sucursales,
                payload: response.data,
            });
            return response;
        }).catch(err => {
            dispatch({
                type: actionTypes.Sucursales,
                payload: err.data,
            });
            return err.response;
        });
        return result && result.response ? result.response.data : result.data;
    } 
}

export const GetSucursalById = (id) => {
    return async (dispatch) => {
        const result = await connector(`/sucursales/by-id?id=${id}`, 'GET', {},{}).then(
            (response) => {
            dispatch({
                type: actionTypes.GetSucursalById,
                payload: response.data,
            });
            return response;
        }).catch(err => {
            dispatch({
                type: actionTypes.GetSucursalById,
                payload: err.data,
            });
            return err.response;
        });
        return result && result.response ? result.response.data : result.data;
    }
}

export const getAllHorarios = () => {
    return async (dispatch) => {
        const result = await connector(`/horario`, 'GET', {},{}).then((response) => {
            dispatch({
                type: actionTypes.GetHorarios,
                payload: response.data,
            });
            return response;
        }).catch(err => {
            dispatch({
                type: actionTypes.GetHorarios,
                payload: err.data,
            });
            return err.response;
        });
        return result && result.response ? result.response.data : result.data;
    }
}

export const getAllDepartamentos = () => {
    return async (dispatch) => {
        const result = await connector(`/departamentos/all`, 'GET', {},{}).then((response) => {
            dispatch({
                type: actionTypes.GetDepartamentos,
                payload: response.data,
            });
            return response;
        }).catch(err => {
            dispatch({
                type: actionTypes.GetDepartamentos,
                payload: err.data,
            });
            return err.response;
        });
        return result && result.response ? result.response.data : result.data;
    }
}

export const DepartamentoById = (idDepartamento) => {
    return async (dispatch) => {
        const result = await connector(`/departamentos/by-id?idDepartamento=${idDepartamento}`, 'GET', {},{}).then((response) => {
            dispatch({
                type: actionTypes.GetDepartamentoById,
                payload: response.data,
            });
            return response;
        }).catch(err => {
            dispatch({
                type: actionTypes.GetDepartamentoById,
                payload: err.data,
            });
            return err.response;
        });
        return result && result.response ? result.response.data : result.data;
    }
}

export const cleanProfile = () => {
    return async (dispatch) => {
        dispatch({
            type: actionTypes.GetEmpleadoById,
            payload: null,
        });
    }
}