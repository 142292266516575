import * as actionTypes from './actionTypes';
import { connector } from '../connector/index';

export const AddSesion = (usuario, clave) => {
    return async (dispatch) => {
        const result = await connector(`/user/login?username=${usuario}&password=${clave}`, 'POST', {},{}).then((response) => {
            dispatch({
                type: actionTypes.AddSesion,
                payload: response.data,
            });
            return response;
        }).catch(err => {
            dispatch({
                type: actionTypes.AddSesion,
                payload: err.data,
            });
            return err.response;
        });
        return result && result.response ? result.response.data : result.data;
    } 
}

