export const GetPromociones = 'promociones/GET_SERVICES';

export const modifyPromociones = 'promociones/MODIFY';

export const AddPromocion = 'promociones/ADD';

export const GetPromocionById = 'promociones/GETBYID';

export const AlterPromocion = 'promociones/ALTER';

export const DeletePromocion = 'promociones/DELETE';