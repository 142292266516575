import React, { useEffect } from 'react';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import { createMuiTheme } from '@material-ui/core/styles';
import Menu from '../../modulos/Menu/index';
import { Redirect } from "react-router-dom";
import * as EmpleadosActions from '../../Api/Empleados/actions';
import { connect } from 'react-redux';
import Snackbar from '@material-ui/core/Snackbar';
import { DatePicker, KeyboardDatePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import DateFnsUtils from '@date-io/date-fns';
import Divider from '@material-ui/core/Divider';


import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import * as HorariosActions from '../../Api/Horarios/actions';
import Box from '@material-ui/core/Box';
import Collapse from '@material-ui/core/Collapse';
import IconButton from '@material-ui/core/IconButton';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';


const useStyles = makeStyles((theme) => ({
  paper: {
    marginTop: theme.spacing(4),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  form: {
    width: '100%',
    marginTop: theme.spacing(3),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
  root: {
    display: 'flex',
  },
  toolbar: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
  },
  content: {
    flexGrow: 0.1,
    padding: theme.spacing(4),
  },
  divInfo: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: '100%',
    height: '100%',
    backgroundColor: '#E6E1E6',
    border: '1px black',
    borderRadius: '10px',
  }
}));

const theme = createMuiTheme();

function PerfilEmpleados(props) {
  const [permit, setPermit] = React.useState(true);
  const [messageSnackbar, setMessageSnackbar] = React.useState('');
  const [idEmpleado, setIdEmpleado] = React.useState('');
  const [redirectAlter, setRedirectAlter] = React.useState(false);
  const [redirect, setRedirect] = React.useState(false);

  const classes = useStyles();

  useEffect(() => {
    if (permit) {
      props.GetById(props.match.params.id).then(result => {
        if (result && !result.message) {
          setMessageSnackbar(result.message);
          setPermit(false);
        }
        if (result && result.empleados) {
          props.sucursalById(result.empleados.idSucursal);
          props.getDepartamentoById(result.empleados.idDepartamento);
        }
      });
    }
  }, [permit]);

  const onComeBackToEmpleadosList = () => {
    props.cleanProfile();
    setRedirect(true);
  }

  const onRedirectToModifyEmpleado = (id) => {
    setIdEmpleado(props.empleadoById.empleados.id);
    setRedirectAlter(true);
  }

  if (redirect) {
    return <Redirect to='/catalogoempleados' />
  }

  if (redirectAlter) {
    return <Redirect to={`/actualizarempleados/${idEmpleado}`} />
  }

  const useRowStyles = makeStyles({
    root: {
      '& > *': {
        borderBottom: 'unset',
      },
    },
  });


  function Row(props) {
    const { row } = props;
    const [open, setOpen] = React.useState(false);
    const classes = useRowStyles();
  
    return (
      <React.Fragment>
        <TableRow className={classes.root}>
          <TableCell>
            <IconButton aria-label="expand row" size="small" onClick={() => setOpen(!open)}>
              {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
            </IconButton>
          </TableCell>
          <TableCell component="th" scope="row">
            {row.id}
          </TableCell>
          <TableCell align="right">{row.descripcion}</TableCell>
          <TableCell align="right">{row.status}</TableCell>
          <TableCell align="right">{row.createdAt}</TableCell>
          <TableCell align="right">{row.updatedAt}</TableCell>
          <TableCell align="right">{<Button disabled>Modificar</Button>}</TableCell>
          <TableCell align="right">{<Button>Eliminar</Button>}</TableCell>
        </TableRow>
        <TableRow>
          <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
            <Collapse in={open} timeout="auto" unmountOnExit>
              <Box margin={1}>
                <Typography variant="h6" gutterBottom component="div">
                  Horario
                </Typography>
                <Table size="small" aria-label="purchases">
                  <TableHead>
                    <TableRow>
                      <TableCell>Día</TableCell>
                      <TableCell>Hora Inicio 1</TableCell>
                      <TableCell >Hora Fin 1</TableCell>
                      <TableCell >Hora Inicio 2</TableCell>
                      <TableCell >Hora Fin 2</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                      <TableRow>
                          <TableCell component="th" scope="row">
                            {"Lunes"}
                          </TableCell>
                          <TableCell component="th" scope="row">
                            {row.horario.lunes && row.horario.lunes.horaInicio1 ? row.horario.lunes.horaInicio1 : '-'}
                          </TableCell>
                          <TableCell component="th" scope="row">
                          {row.horario.lunes && row.horario.lunes.horaFin1 ? row.horario.lunes.horaFin1 : '-'}
                          </TableCell>
                          <TableCell component="th" scope="row">
                          {row.horario.lunes && row.horario.lunes.horaInicio2 ? row.horario.lunes.horaInicio2 : '-'}
                          </TableCell>
                          <TableCell component="th" scope="row">
                          {row.horario.lunes && row.horario.lunes.horaFin2 ? row.horario.lunes.horaFin2 : '-'}
                          </TableCell>
                      </TableRow>
                      <TableRow>
                          <TableCell component="th" scope="row">
                            {"Martes"}
                          </TableCell>
                          <TableCell component="th" scope="row">
                            {row.horario.martes && row.horario.martes.horaInicio1 ? row.horario.martes.horaInicio1 : '-'}
                          </TableCell>
                          <TableCell component="th" scope="row">
                          {row.horario.martes && row.horario.martes.horaFin1 ? row.horario.martes.horaFin1 : '-'}
                          </TableCell>
                          <TableCell component="th" scope="row">
                          {row.horario.martes && row.horario.martes.horaInicio2 ? row.horario.martes.horaInicio2 : '-'}
                          </TableCell>
                          <TableCell component="th" scope="row">
                          {row.horario.martes && row.horario.martes.horaFin2 ? row.horario.martes.horaFin2 : '-'}
                          </TableCell>
                      </TableRow>
                      <TableRow>
                          <TableCell component="th" scope="row">
                            {"Miercoles"}
                          </TableCell>
                          <TableCell component="th" scope="row">
                            {row.horario.miercoles && row.horario.miercoles.horaInicio1 ? row.horario.miercoles.horaInicio1 : '-'}
                          </TableCell>
                          <TableCell component="th" scope="row">
                            {row.horario.miercoles && row.horario.miercoles.horaFin1 ? row.horario.miercoles.horaFin1 : '-'}
                          </TableCell>
                          <TableCell component="th" scope="row">
                          {row.horario.miercoles && row.horario.miercoles.horaInicio2 ? row.horario.miercoles.horaInicio2 : '-'}
                          </TableCell>
                          <TableCell component="th" scope="row">
                          {row.horario.miercoles && row.horario.miercoles.horaFin2 ? row.horario.miercoles.horaFin2 : '-'}
                          </TableCell>
                      </TableRow>
                      <TableRow>
                          <TableCell component="th" scope="row">
                            {"Jueves"}
                          </TableCell>
                          <TableCell component="th" scope="row">
                            {row.horario.jueves && row.horario.jueves.horaInicio1 ? row.horario.jueves.horaInicio1 : '-'}
                          </TableCell>
                          <TableCell component="th" scope="row">
                            {row.horario.jueves && row.horario.jueves.horaFin1 ? row.horario.jueves.horaFin1 : '-'}
                          </TableCell>
                          <TableCell component="th" scope="row">
                          {row.horario.jueves && row.horario.jueves.horaInicio2 ? row.horario.jueves.horaInicio2 : '-'}
                          </TableCell>
                          <TableCell component="th" scope="row">
                          {row.horario.jueves && row.horario.jueves.horaFin2 ? row.horario.jueves.horaFin2 : '-'}
                          </TableCell>
                      </TableRow>
                      <TableRow>
                          <TableCell component="th" scope="row">
                            {"Viernes"}
                          </TableCell>
                          <TableCell component="th" scope="row">
                            {row.horario.viernes && row.horario.viernes.horaInicio1 ? row.horario.viernes.horaInicio1 : '-'}
                          </TableCell>
                          <TableCell component="th" scope="row">
                            {row.horario.viernes && row.horario.viernes.horaFin1 ? row.horario.viernes.horaFin1 : '-'}
                          </TableCell>
                          <TableCell component="th" scope="row">
                          {row.horario.viernes && row.horario.viernes.horaInicio2 ? row.horario.viernes.horaInicio2 : '-'}
                          </TableCell>
                          <TableCell component="th" scope="row">
                          {row.horario.viernes && row.horario.viernes.horaFin2 ? row.horario.viernes.horaFin2 : '-'}
                          </TableCell>
                      </TableRow>
                      <TableRow>
                          <TableCell component="th" scope="row">
                            {"Sábado"}
                          </TableCell>
                          <TableCell component="th" scope="row">
                            {row.horario.sabado && row.horario.sabado.horaInicio1 ? row.horario.sabado.horaInicio1 : '-'}
                          </TableCell>
                          <TableCell component="th" scope="row">
                            {row.horario.sabado && row.horario.sabado.horaFin1 ? row.horario.sabado.horaFin1 : '-'}
                          </TableCell>
                          <TableCell component="th" scope="row">
                          {row.horario.sabado && row.horario.sabado.horaInicio2 ? row.horario.sabado.horaInicio2 : '-'}
                          </TableCell>
                          <TableCell component="th" scope="row">
                          {row.horario.sabado && row.horario.sabado.horaFin2 ? row.horario.sabado.horaFin2 : '-'}
                          </TableCell>
                      </TableRow>
                      <TableRow>
                          <TableCell component="th" scope="row">
                            {"Domingo"}
                          </TableCell>
                          <TableCell component="th" scope="row">
                            {row.horario.domingo && row.horario.domingo.horaInicio1 ? row.horario.domingo.horaInicio1 : '-'}
                          </TableCell>
                          <TableCell component="th" scope="row">
                            {row.horario.domingo && row.horario.domingo.horaFin1 ? row.horario.domingo.horaFin1 : '-'}
                          </TableCell>
                          <TableCell component="th" scope="row">
                          {row.horario.domingo && row.horario.domingo.horaInicio2 ? row.horario.domingo.horaInicio2 : '-'}
                          </TableCell>
                          <TableCell component="th" scope="row">
                          {row.horario.domingo && row.horario.domingo.horaFin2 ? row.horario.domingo.horaFin2 : '-'}
                          </TableCell>
                      </TableRow>
                  </TableBody>
                </Table>
              </Box>
            </Collapse>
          </TableCell>
        </TableRow>
      </React.Fragment>
    );
  }

  return (
    <div className={classes.root}>
      <Menu title="Administración de empleados" />
      <main className={classes.content}>
        <div className={classes.toolbar} />
        <Container component="main" maxWidth="sm">
          <div className={classes.paper}>
            <Typography variant="h4">
              <Button
                variant="outlined"
                color="secondary"
                onClick={onComeBackToEmpleadosList}
              >
                {'<-'}
              </Button> Actualización de empleados
            </Typography>
            <form className={classes.form}>
              <Grid container spacing={2}>
                <Grid item xs={12} sm={6} >
                  <div className={classes.divInfo}>
                    Nombre: {props.empleadoById && props.empleadoById.empleados ?
                      props.empleadoById.empleados.nombre + ' ' + props.empleadoById.empleados.primerApellido + ' ' + props.empleadoById.empleados.segundoApellido : ''}
                  </div>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <div className={classes.divInfo}>
                    Celular: {props.empleadoById && props.empleadoById.empleados ?
                      props.empleadoById.empleados.celular : ''}
                  </div>
                </Grid>
                <Divider />
                <Grid item xs={12}>
                  <div className={classes.divInfo}>
                    Dirección: {props.empleadoById && props.empleadoById.empleados ?
                      props.empleadoById.empleados.direccion : ''}
                  </div>
                </Grid>
                <Divider />
                <Grid item xs={12} sm={6}>
                  <div className={classes.divInfo}>
                    Sueldo: {props.empleadoById && props.empleadoById.empleados ?
                      props.empleadoById.empleados.sueldo : ''}
                  </div>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <div className={classes.divInfo}>
                    Sucursal: {props.sucursal && props.sucursal.sucursal ?
                      props.sucursal.sucursal.nombre : ''}
                  </div>
                </Grid>
                <Grid item xs={12} sm={6} >
                  <div className={classes.divInfo}>
                    RFC: {props.empleadoById && props.empleadoById.empleados ?
                      props.empleadoById.empleados.rfc : ''}
                  </div>
                </Grid>
                <Grid item xs={12} sm={6} >
                  <div className={classes.divInfo}>
                    Fecha de nacimiento: {props.empleadoById && props.empleadoById.empleados ?
                      `${props.empleadoById.empleados.fechaNacimiento.substring(0,10)}`
                      : 'No disponible'}
                  </div>
                </Grid>
                <Grid item xs={12}>
                  <div className={classes.divInfo}>
                    Horario: {props.empleadoById && props.empleadoById.horario ?
                          props.empleadoById.horario.descripcion
                      : 'No disponible'}
                  </div>
                </Grid>
                <Grid item xs={12}  sm={6}>
                  <div className={classes.divInfo}>
                    Clave: {props.empleadoById && props.empleadoById.empleados ?
                      props.empleadoById.empleados.clave
                      : ''}
                  </div>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <div className={classes.divInfo}>
                    Departamento: {props.departamentoById && props.departamentoById.departamento ?
                      props.departamentoById.departamento.descripcion
                      : ''}
                  </div>
                </Grid>
                <Grid item xs={12} sm={6} >
                  <Button
                    fullWidth
                    variant="outlined"
                    color="primary"
                    onClick={onRedirectToModifyEmpleado}
                  >
                    Editar Información
                  </Button>
                </Grid>
                <Grid item xs={12} sm={6} >

                </Grid>
              </Grid>
            </form>
          </div>
        </Container>

        <Snackbar
          anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
          open={Boolean(messageSnackbar)}
          onClose={() => setMessageSnackbar('')}
          message={messageSnackbar}
          autoHideDuration={6000}
        />
      </main>
    </div>
  )

}

const mapStateToProps = state => {
  return {
    empleadoById: state.EmpleadosReducer.EmpleadoById,
    alterEmpleado: state.EmpleadosReducer.alterEmpleado,
    sucursal: state.EmpleadosReducer.sucursalById,
    departamentoById: state.EmpleadosReducer.departamentoById,
  }
}

const mapDispatchToProps = dispatch => {
  return {
    AlterEmpleado: (Empleado) => dispatch(EmpleadosActions.AlterEmpleado(Empleado)),
    GetById: (id) => dispatch(EmpleadosActions.GetEmpleadoById(id)),
    sucursalById: (id) => dispatch(EmpleadosActions.GetSucursalById(id)),
    getDepartamentoById: (id) => dispatch(EmpleadosActions.DepartamentoById(id)),
    cleanProfile: () => dispatch(EmpleadosActions.cleanProfile()),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(PerfilEmpleados);
