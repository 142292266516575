import React,  {useEffect } from 'react';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import { createMuiTheme } from '@material-ui/core/styles';
import Menu from '../../modulos/Menu/index';
import { Redirect } from "react-router-dom";
import * as EmpleadosActions from '../../Api/Empleados/actions';
import { connect } from 'react-redux';
import Snackbar from '@material-ui/core/Snackbar';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import DateFnsUtils from '@date-io/date-fns';
import { DatePicker,TimePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";

const useStyles = makeStyles((theme) => ({
    paper: {
      marginTop: theme.spacing(4),
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
    },
    form: {
      width: '100%', 
      marginTop: theme.spacing(3),
    },
    submit: {
      margin: theme.spacing(3, 0, 2),
    },
    root: {
        display: 'flex',
      },
     toolbar: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-end',
        padding: theme.spacing(0, 1),
        // necessary for content to be below app bar
        ...theme.mixins.toolbar,
      },
      content: {
        flexGrow: 1,
        padding: theme.spacing(3),
      },
      formControl: {
        minWidth: 120,
        width: '100%', 
      },
  }));

  const theme = createMuiTheme();

function ActualizarEmpleado(props) {
  const [permit, setPermit] = React.useState(true);
  const [messageSnackbar, setMessageSnackbar] = React.useState('');
  const [apellidoPaterno, setapellidoPaterno]  = React.useState('');
  const [apellidoMaterno, setapellidoMaterno]  = React.useState('');
  const [nombre, setNombre]  = React.useState('');
  const [direccion, setDireccion]  = React.useState('');
  const [telefono, setTelefono]  = React.useState('');
  const [fechaNacimiento, setfechaNacimiento]  = React.useState(new Date());
  const [rfc, setRfc]  = React.useState('');
  const [sucur, setSucur] = React.useState('');
  const [sueldo, setSueldo] = React.useState('');
  const [diaInicio, setDiaInicio] = React.useState('');
  const [diaFin, setDiaFin] = React.useState('');
  const [horaInicio, setHoraInicio] = React.useState(new Date());
  const [horaFin, setHoraFin] = React.useState(new Date());
  const [id, setid]  = React.useState('');
  const [redirect, setRedirect] = React.useState(false);
  const [horario, setHorario] = React.useState('');
  const [clave, setClave] = React.useState('');
  const [departamento, setDepartamento] = React.useState('');

    const classes = useStyles();

    useEffect(() => {
      if(permit){
        props.GetById(props.match.params.id).then(result => {
          if (result && !result.message){
            setMessageSnackbar(result.message);
            setPermit(false);
          }
          if(result && result.empleados){

            props.getDepartamentos();
            props.getHorarios();

            setNombre(result.empleados.nombre);
            setapellidoPaterno(result.empleados.primerApellido);
            setapellidoMaterno(result.empleados.segundoApellido);
            setNombre(result.empleados.nombre);
            setDireccion(result.empleados.direccion);
            setTelefono(result.empleados.celular);
            setfechaNacimiento(result.empleados.fechaNacimiento);
            setRfc(result.empleados.rfc);
            setSucur(result.empleados.idSucursal);
            setid(result.empleados.id);
            setSueldo(result.empleados.sueldo);
            setDepartamento(result.empleados.idDepartamento);
            setHorario(result.empleados.idHorario);
            setClave(result.empleados.clave);
          }
        });
      }
      }, [permit]);
      
      const onRegistrarEmpleado = () => {
        const horaInicioCasteada = `${('0'+horaInicio.getHours()).slice(-2)}:${('0'+horaInicio.getMinutes()).slice(-2)}`;
        const horaFinCasteada = `${('0'+horaFin.getHours()).slice(-2)}:${('0'+horaFin.getMinutes()).slice(-2)}`;
        const empleado = {
          primerApellido: apellidoPaterno,
          segundoApellido: apellidoMaterno,
          nombre,
          direccion,
          celular: telefono,
          fechaNacimiento,
          rfc,
          idSucursal: sucur,
          sueldo,
          fechaInicio: diaInicio,
          fechaFin: diaFin,
          horaInicio: horaInicioCasteada,
          horaFin: horaFinCasteada,
          id,
          idDepartamento: departamento,
          idHorario: horario,
          clave,
          status: "ACTIVO",
        };
  
        const idUsuario = props.addSesion.user.id;
    
        props.AlterEmpleado(empleado, idUsuario).then((result) => {
            if (result && result.message) {
              setMessageSnackbar(result.message);
              if(!result.error){
              props.GetById(props.match.params.id).then((result)=> {

                setNombre(result.empleados.nombre);
                setapellidoPaterno(result.empleados.primerApellido);
                setapellidoMaterno(result.empleados.segundoApellido);
                setNombre(result.empleados.nombre);
                setDireccion(result.empleados.direccion);
                setTelefono(result.empleados.celular);
                setfechaNacimiento(result.empleados.fechaNacimiento);
                setRfc(result.empleados.rfc);
                setSucur(result.empleados.idSucursal);
                setid(result.empleados.id);
                setSueldo(result.empleados.sueldo);
                setDepartamento(result.empleados.idDepartamento);
                setHorario(result.empleados.idHorario);
                setClave(result.empleados.clave);
              });
            }
          }
        });
      }

      const onComeBackToEmpleadosList = () => {
        setRedirect(true);
      }
  
      if (redirect){
        return <Redirect to={`/empleado/perfil/${id}`} /> 
      }

      const onChangerfc = (e) => {
        setRfc(e.target.value);
      }
  
      const handleChangeSucursal = (event) => {
        console.log(event.target.value)
        setSucur(event.target.value);
      }
  
      const onChangeSueldo = (event) => {
        setSueldo(event.target.value);
      }

      const handleChangeDepartamento = (event) => {
        setDepartamento(event.target.value);
      }

      const handleChangeHorario = (event) => {
        setHorario(event.target.value);
      }

      const onChangeClave = (e) => {
        setClave(e.target.value);
      }
  
      const handleChangeDiaInicio = (event) => {
        setDiaInicio(event.target.value);
      }
  
      const handleChangeDiaFin = (event) => {
        setDiaFin(event.target.value);
      }

      const onChangeapellidoPaterno = (e) => {
        setapellidoPaterno(e.target.value);
      }
  
      const onChangeapellidoMaterno = (e) => {
        setapellidoMaterno(e.target.value);
      }
  
      const onChangeNombre = (e) => {
        setNombre(e.target.value);
      }
  
      const onChangeDireccion = (e) => {
        setDireccion(e.target.value);
      }
  
      const onChangefechaNacimiento = (e) => {
        setfechaNacimiento(e.target.value);
      }
  
      const onChangeTelefono = (e) => {
        setTelefono(e.target.value);
      }

    return(
        <div className={classes.root}>
        <Menu title="Administración de empleados"/>
        <main className={classes.content}>
          <div className={classes.toolbar} />
          <Container component="main" maxWidth="sm">
      <div className={classes.paper}>
        <Typography variant="h4">
          Actualización de empleados
        </Typography>
        <form className={classes.form}>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={6} >
                           <TextField
                name="idEmpelado"
                variant="outlined"
                fullWidth
                id="txtIdEmpleado"
                label="ID Empleado"
                disabled
                value={id}
              />
            </Grid>
        
            <Grid item xs={12} sm={6}>
              <TextField
                name="Nombre"
                variant="outlined"
                fullWidth
                id="txtNombre"
                label="Nombres"
                value={nombre}
                onChange={onChangeNombre}
              />
            </Grid>

            <Grid item xs={12} sm={6}>
              <TextField
                name="apellidoPaterno"
                variant="outlined"
                fullWidth
                id="txtApapellidoPaterno"
                label="Primer Apellido"
                value={apellidoPaterno}
                onChange={onChangeapellidoPaterno}
              />
            </Grid>

            <Grid item xs={12} sm={6}>
              <TextField
                name="apellidoMaterno"
                variant="outlined"
                fullWidth
                id="txtApapellidoMaterno"
                label="Segundo Apellido"
                value={apellidoMaterno}
                onChange={onChangeapellidoMaterno}
              />
            </Grid>

            <Grid item xs={12}>
              <TextField
                name="Direccion"
                variant="outlined"
                fullWidth
                id="txtDireccion"
                label="Dirección"
                value={direccion}
                onChange={onChangeDireccion}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                name="Telefono"
                variant="outlined"
                fullWidth
                id="txtTelefono"
                label="Teléfono"
                value={telefono}
                onChange={onChangeTelefono}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
            <MuiPickersUtilsProvider utils={DateFnsUtils}>
              <DatePicker
                variant="inline"
                inputVariant="outlined"
                fullWidth
                autoOk
                id="fecha"
                label="Fecha de nacimiento"
                format="yyyy-MM-dd"
                defaultValue={fechaNacimiento}
                value={fechaNacimiento}
                onChange={(date) => setfechaNacimiento(date)}
              />
              </MuiPickersUtilsProvider>
            </Grid>
            <Grid item xs={12} sm={6} >
              <TextField
                name="rfc"
                variant="outlined"
                fullWidth
                id="txtRfc"
                label="RFC"
                value={rfc}
                onChange={onChangerfc}
              />
            </Grid>

            <Grid item xs={12} sm={6} >
            <FormControl className={classes.formControl} variant="outlined">
                  <InputLabel id="seleccionar-sucursal">Sucursal</InputLabel>
                  <Select
                    labelId="sucursales"
                    id="sucursaless"
                    value={sucur}
                    onChange={handleChangeSucursal}
                    fullWidth
                  >
                    <MenuItem value="">
                      <em>Ninguna</em>
                    </MenuItem>
                    {
                        props.sucursales.map(item => {
                          return  (<MenuItem value={item.id}>
                          <em>{item.nombre} </em>
                        </MenuItem>);
                        })
                    }
                  </Select>
                </FormControl>
            </Grid>
            <Grid item xs={12} sm={6} >
              <TextField
                name="Sueldo"
                variant="outlined"
                fullWidth
                id="txtSueld"
                label="Sueldo"
                value={sueldo}
                onChange={onChangeSueldo}
              />
            </Grid>
            <Grid item xs={12} sm={6} >
            <FormControl className={classes.formControl} variant="outlined">
                  <InputLabel id="seleccionar-departamento">Departamento</InputLabel>
                  <Select
                    labelId="departamento"
                    id="departamento"
                    value={departamento}
                    onChange={handleChangeDepartamento}
                    fullWidth
                  >
                    <MenuItem value="">
                      <em>Ninguna</em>
                    </MenuItem>
                    {
                        props.departamentos && props.departamentos.departamentoList && props.departamentos.departamentoList.map(item => {
                          return  (<MenuItem value={item.id}>
                          <em>{item.descripcion} </em>
                        </MenuItem>);
                        })
                    }
                  </Select>
                </FormControl>
            </Grid>

            <Grid item xs={12} sm={6} >
            <FormControl className={classes.formControl} variant="outlined">
                  <InputLabel id="seleccionar-sucursal">Horario</InputLabel>
                  <Select
                    labelId="horarios"
                    id="horarios"
                    value={horario}
                    onChange={handleChangeHorario}
                    fullWidth
                  >
                    <MenuItem value="">
                      <em>Ninguna</em>
                    </MenuItem>
                    {
                        props.horarios && props.horarios.horarios && props.horarios.horarios.map(item => {
                          return  (<MenuItem value={item.id}>
                          <em>{item.descripcion} </em>
                        </MenuItem>);
                        })
                    }
                  </Select>
                </FormControl>
            </Grid>
            <Grid item xs={12} sm={6} >
            <TextField
                name="Clave"
                variant="outlined"
                fullWidth
                id="txtClave"
                label="Clave"
                value={clave}
                onChange={onChangeClave}
              />
            </Grid>
            <Grid item xs={12} sm={6} >
            <Button
            fullWidth
            variant="outlined"
            color="primary"
            onClick={onRegistrarEmpleado}
          >
            Registrar
          </Button>
            </Grid>
            <Grid item xs={12} sm={6} >
            <Button
            fullWidth
            variant="outlined"
            color="secondary"
            onClick={onComeBackToEmpleadosList}
          >
            Cancelar
          </Button>
            </Grid>
          </Grid>
        </form>
      </div>
    </Container>

    <Snackbar
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
        open={Boolean(messageSnackbar)}
        onClose={()=>setMessageSnackbar('')}
        message={messageSnackbar}
        autoHideDuration={6000}
      />
        </main>
      </div>
    )

}

const mapStateToProps = state => {
  return {
    empleadoById: state.EmpleadosReducer.empleadoById,
    alterEmpleado: state.EmpleadosReducer.alterEmpleado,
    sucursales: state.EmpleadosReducer.sucursales,
    departamentos: state.EmpleadosReducer.departamentos,
    horarios: state.EmpleadosReducer.horarios,
    addSesion: state.SesionReducer.addSesion,

  }
}

const mapDispatchToProps = dispatch => {
  return {
    AlterEmpleado: (Empleado, idUsuario) => dispatch(EmpleadosActions.AlterEmpleado(Empleado, idUsuario)),
    GetById: (id) => dispatch(EmpleadosActions.GetEmpleadoById(id)),
    getDepartamentos: () => dispatch(EmpleadosActions.getAllDepartamentos()),
    getHorarios: () => dispatch(EmpleadosActions.getAllHorarios()),

  }
}

export default connect(mapStateToProps, mapDispatchToProps)(ActualizarEmpleado);
